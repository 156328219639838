import { Student, studentsProvider } from "../providers/studentsProvider";
import { ReactNode } from "react";
import { NotificationOptions, NotificationType } from "ra-core/src/notification/types";
import { uncapitalize } from "./string";

type NotifyFunction = (message: string | ReactNode, options: NotificationOptions & { type?: NotificationType }) => void;

/**
 * Resolves to `true` if the error was handled, to `false` otherwise.
 */
export async function handleBookingError(
  error: any,
  studentOrStudentId: Student | string | undefined,
  notify: NotifyFunction,
): Promise<boolean> {
  const code = error.details?.code;

  async function student() {
    if (typeof studentOrStudentId === "string") {
      try {
        const { data: student } = await studentsProvider.getOne("students", { id: studentOrStudentId });
        return student;
      } catch {
        return undefined;
      }
    } else {
      return studentOrStudentId;
    }
  }

  async function studentName() {
    return (await student())?.name ?? "Der Fahrschüler";
  }

  if (code === "appointment.capacity_reached") {
    notify("Alle Plätze sind bereits belegt.", { type: "error" });
  } else if (code === "payments.outstanding") {
    notify(`${await studentName()} hat offene Zahlungen.`, { type: "error" });
  } else if (code === "student.base-fee-not-paid") {
    notify(`${await studentName()} hat die Grundgebühr noch nicht bezahlt.`, { type: "error" });
  } else if (code === "student.cannot_book_theory_lessons") {
    notify(`${await studentName()} hat kein Paket gebucht, das Theorieunterricht enthält.`, { type: "error" });
  } else if (code === "student.not-active") {
    const { status, verboseStatus } = (await student()) ?? {};
    if (status === "active" || !verboseStatus) {
      notify(`${await studentName()} ist nicht aktiv.`, { type: "error" });
    } else {
      notify(`${await studentName()} ist nicht aktiv, sondern: ${uncapitalize(verboseStatus)}.`, { type: "error" });
    }
  } else if (code === "student.not-enough-credit") {
    notify(`${await studentName()} hat nicht genügend Guthaben.`, { type: "error" });
  } else if (code === "student.no-payment-method") {
    notify(`${await studentName()} hat keine gültige Zahlungsmethode hinterlegt.`, { type: "error" });
  } else {
    return false;
  }
  return true;
}
