import { Avatar, Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  FunctionField,
  Link,
  ReferenceArrayField,
  ReferenceField,
  SelectField,
  Tab,
  TextField,
  useGetOne,
  SingleFieldList,
  useRecordContext,
} from "react-admin";
import { grants } from "../backoffice.access_control";
import { OpenInFirestoreButton } from "../buttons/OpenInFirestoreButton";
import { OpenInHubspotButton } from "../buttons/OpenInHubspotButton";
import { OpenInStripeButton } from "../buttons/OpenInStripeButton";
import { StudentDrivingLessonsList } from "./student/StudentDrivingLessonsList";
import { StudentTheoryLessonsList } from "./student/StudentTheoryLessonsList";
import { autovioColors, overviewCardStyle } from "../misc/backofficeTheme";
import { Student } from "../providers/studentsProvider";
import { StudentAdminForm } from "./student/StudentAdminForm";
import { DrivingSchool } from "../providers/drivingSchoolsProvider";
import { StudentOverviewTab } from "./student/StudentOverviewTab";
import { StudentInvoicesList } from "./student/StudentInvoicesList";
import { Tabs } from "../misc/Tabs";
import { ShowBase } from "../misc/ShowBase";
import { Row } from "../misc/Row";
import { LoadingIndicator } from "../misc/LoadingIndicator";
import { MoneyField } from "../fields/MoneyField";
import {
  ChangeStudentPaymentStrategyButton,
  PaymentStrategyChoices,
} from "../buttons/ChangeStudentPaymentStrategyButton";
import { useEffect, useState } from "react";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { StudentProfileTab } from "./student/StudentProfileTab";
import { StudentStickyNotesList } from "./student/StudentStickyNotesList";
import { StudentInAppNotificationsList } from "./student/StudentInAppNotificationsList";
import { StudentBranchField } from "../fields/StudentBranchField";
import { ChangeStudentBranchButton } from "../buttons/ChangeStudentBranchButton";
import LocalPhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { NameHeader } from "../misc/NameHeader";
import { BookedQuotesDialog } from "../dialogs/BookedQuotesDialog";
import { ChangeStudentInstructorButton } from "../buttons/ChangeStudentInstructorButton";
import { ChangeStudentStatusButton } from "../buttons/ChangeStudentStatusButton";
import { HeaderField } from "../misc/HeaderField";
import { Column } from "../misc/Column";

// This component is used by the `studentsResource` and by the `DunningStudentDialog`.
export function StudentDetails({ compactView = false }: { compactView?: boolean }) {
  const student = useRecordContext<Student>();
  useEffect(() => {
    // For backwards compatibility ...
    if (location.pathname.endsWith("/packages")) {
      const url = new URL(location.href);
      url.pathname = url.pathname.substring(0, url.pathname.length - "/packages".length);
      url.searchParams.set("openDialog", BookedQuotesDialog.name);
      location.replace(url.toString());
    }
  }, []);
  if (location.pathname.endsWith("/packages")) {
    return null;
  }
  return (
    // If the student is undefined (component rednered inside "student resource"), React-admin gets the student id from the URL.
    <ShowBase resource="students" id={student?.id}>
      <StudentDetailsTabs syncWithLocation={!compactView} />
      {/** Add some space at the bottom of the page ... */}
      <Box height="20px" />
    </ShowBase>
  );
}

function StudentButtons() {
  const student = useRecordContext<Student>();
  const { greyUltraLight, green } = autovioColors;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePhoneClick = () => {
    window.location.href = `tel:${student.phoneNumber}`;
  };

  const handleEmailClick = () => {
    const url = `https://app.hubspot.com/contacts/19951845/record/0-1/${student.hubspotContactId}/?interaction=email`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Row sx={{ display: "block" }}>
      <Box sx={{ display: "flex", gap: 1 }}>
        <IconButton sx={{ color: green, "&:hover": { background: greyUltraLight } }} onClick={handlePhoneClick}>
          <LocalPhoneIcon style={{ fontSize: 19 }} />
        </IconButton>
        <IconButton sx={{ color: green, "&:hover": { background: greyUltraLight } }} onClick={handleEmailClick}>
          <AlternateEmailIcon sx={{ fontSize: 20 }} />
        </IconButton>
        {grants.includes("admin") && (
          <IconButton sx={{ color: green, "&:hover": { background: greyUltraLight } }} onClick={handleClick}>
            <MoreVertIcon sx={{ fontSize: 20 }} />
          </IconButton>
        )}
      </Box>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ width: 220 }}>
        {grants.includes("viewStripe") && (
          <MenuItem onClick={handleClose}>
            <OpenInStripeButton />
          </MenuItem>
        )}

        {grants.includes("viewHubspot") && (
          <MenuItem onClick={handleClose}>
            <OpenInHubspotButton showTitle />
          </MenuItem>
        )}

        {grants.includes("viewFirestore") && (
          <MenuItem onClick={handleClose}>
            <OpenInFirestoreButton showTitle />
          </MenuItem>
        )}
      </Menu>
    </Row>
  );
}

function StudentDetailsHeader({ student }: { student: Student }) {
  const role = student.bookedTrainings.every((it) => it.bundleName.includes("ASF"))
    ? "ASF-Kurs-Teilnehmer"
    : "Fahrschüler";

  return (
    <Row sx={{ my: "20px" }} spacing="50px" alignItems="start">
      <Row spacing="20px" alignItems="center">
        <Avatar src={student.avatarUrl} sx={{ width: "60px", height: "60px" }} />
        <Box sx={{ ml: "20px", display: "flex", flexDirection: "column" }}>
          <NameHeader>{student.name}</NameHeader>
          <Typography sx={{ color: autovioColors.grey, fontSize: "14px", lineHeight: "1.43" }}>{role}</Typography>
        </Box>
      </Row>
      <Row sx={{ flexWrap: "wrap", gap: "10px 40px", alignItems: "baseline" }} alignItems="center">
        <_BranchField />
        <_InstructorField />
        <_StatusField />
        <_BalanceField />
        <_PaymentDetailsField />
      </Row>
    </Row>
  );
}

function _BranchField() {
  const student = useRecordContext<Student>();
  const { data: drivingSchool } = useGetOne<DrivingSchool>("drivingSchools", { id: student.drivingSchoolId });
  if (!drivingSchool || drivingSchool?.branches?.length <= 1) {
    return null;
  }
  return (
    <HeaderField label="Filiale">
      <StudentBranchField drivingSchoolId={student.drivingSchoolId} />
      <ChangeStudentBranchButton />
    </HeaderField>
  );
}

function _InstructorField() {
  return (
    <HeaderField label="Fahrlehrer">
      <Box sx={{ display: "flex", alignItems: "start", gap: "5px", lineHeight: 1 }}>
        <ReferenceArrayField label="Fahrlehrer" reference="instructors" source="instructorIds">
          <SingleFieldList linkType={false} sx={{ display: "flex", flexDirection: "column", gap: "0" }}>
            <ReferenceField
              source="id"
              reference="instructors"
              sx={{ "& .RaReferenceField-link": { display: "flex" } }}
            />
          </SingleFieldList>
        </ReferenceArrayField>
        <ChangeStudentInstructorButton />
      </Box>
    </HeaderField>
  );
}

function _StatusField() {
  return (
    <HeaderField label="Status">
      <TextField label="Status" source="verboseStatus" />
      <ChangeStudentStatusButton />
    </HeaderField>
  );
}

function _BalanceField() {
  const student = useRecordContext<Student>();

  if (!grants.includes("viewDunning")) {
    return null;
  }

  return (
    <HeaderField label="Saldo">
      <Link to={`/students/${student.id}/invoices`}>
        <MoneyField source="balance" redForNegativeValues />
      </Link>
    </HeaderField>
  );
}

function _PaymentDetailsField() {
  return (
    <HeaderField label="Zahlungsmodus">
      <Column>
        <Row spacing="5px">
          <SelectField source="paymentStrategy" choices={PaymentStrategyChoices} />
          {grants.includes("editStudentPaymentStrategy") && <ChangeStudentPaymentStrategyButton />}
        </Row>
        <Box>
          <_PaymentMethodLabel />
        </Box>
      </Column>
    </HeaderField>
  );
}

function _PaymentMethodLabel() {
  const student = useRecordContext<Student>();

  function _renderPaymentMethod(student: Student) {
    const { paymentMethod } = student;
    switch (paymentMethod) {
      case "card":
        return "Kreditkarte";
      case "sepa":
        return "Lastschrift";
      default:
        return "Keine Angabe";
    }
  }

  if (!grants.includes("viewDunning") || student.paymentStrategy === "purchaseOnAccount") {
    return null;
  }

  return <FunctionField render={_renderPaymentMethod} />;
}

function StudentDetailsTabs({ syncWithLocation }: { syncWithLocation: boolean }) {
  const student = useRecordContext<Student>();
  const [autovioContext, setAutovioContext] = useAutovioContext();
  useEffect(() => {
    if (student && student.drivingSchoolId !== autovioContext.drivingSchoolId) {
      setAutovioContext({ drivingSchoolId: student.drivingSchoolId });
    }
  }, [autovioContext.drivingSchoolId, student?.id]);

  if (!student) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Tabs
        sx={{ ...overviewCardStyle, padding: "5px 30px 10px 30px" }}
        header={<StudentDetailsHeader student={student} />}
        additionalButtons={<StudentButtons />}
        separateCards
        syncWithLocation={syncWithLocation}
      >
        <Tab label="Überblick" /* no path attribute here, so the tab is shown by default */>
          <StudentOverviewTab student={student} />
        </Tab>
        <Tab label="Stammdaten" path="profile">
          <StudentProfileTab />
        </Tab>
        <Tab label="Theorie" path="theoryLessons">
          <StudentTheoryLessonsList />
        </Tab>
        <Tab label="Fahrstunden" path="drivingLessons">
          <StudentDrivingLessonsList />
        </Tab>
        <Tab label="Konto" path="invoices">
          <StudentInvoicesList />
        </Tab>
        {grants.includes("admin") && (
          <Tab label="Admin" path="admin">
            <Box sx={{ ...overviewCardStyle, paddingBottom: "18px" /* <= 25px - 7px margin from Toolbar */ }}>
              <StudentAdminForm />
            </Box>
            <Grid2 container spacing="30px" /* see https://mui.com/material-ui/react-grid2 */>
              <Grid2 flex={1}>
                <h4 style={{ marginTop: 0 }}>Sticky Notes</h4>
                <StudentStickyNotesList />
              </Grid2>
              <Grid2 flex={1}>
                <h4 style={{ marginTop: 0 }}>In-App-Benachrichtigungen</h4>
                <StudentInAppNotificationsList />
              </Grid2>
            </Grid2>
          </Tab>
        )}
      </Tabs>
    </>
  );
}
