import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import {
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { applyFilter, applyPagination, applySort } from "../backoffice.utils";
import { firestore } from "../firebase";
import { TheoryExam, TheoryLesson } from "../model/autovioCalendarEvents";
import { theoryLessonConverter } from "./converter/theoryLessonConverter";
import { theoryExamConverter } from "./converter/theoryExamConverter";
import { commonCalendarEventQueryConstraints } from "../utils/firestore-queries";
import { AbstractProvider } from "./abstractProvider";
import { autovioCalendarEventConverter } from "./converter/autovioCalendarEventConverter";
import { getAuthenticatedServerClient, schemas } from "../api/server.api";
import { z } from "zod";
import { updateApplied } from "../utils/updateApplied";
import { diff as deepObjectDiff } from "deep-object-diff";

type EditCalendarEventDto = z.infer<typeof schemas.EditCalendarEventDto>;

class TheoryLessonsProvider extends AbstractProvider<TheoryLesson | TheoryExam> {
  async getOne(_: string, { id }: GetOneParams): Promise<GetOneResult<TheoryLesson | TheoryExam>> {
    const snapshot = await getDoc(doc(firestore, `calendar_events/${id}`));
    const event = autovioCalendarEventConverter.fromFirestore(snapshot);
    if (event.type === "TheoryLesson" || event.type === "TheoryExam") {
      return { data: event };
    }
    throw new Error(`Calendar event ${id} is of type: ${event.type} -- expected: TheoryLesson or TheoryExam`);
  }

  async getManyReference(
    _: string,
    { target, id, filter, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<TheoryLesson | TheoryExam>> {
    const { from, to, types, ...restFilters } = filter;
    const fetchTypes: Set<"TheoryLesson" | "TheoryExam"> = types
      ? new Set(types)
      : new Set(["TheoryLesson", "TheoryExam"]);

    if (fetchTypes.size === 0) {
      throw Error("No type requested, must be one of 'TheoryLesson' and 'TheoryExam' or both.");
    } else if (!fetchTypes.has("TheoryExam") && !fetchTypes.has("TheoryLesson")) {
      throw Error(`Unknown types requested: ${fetchTypes}`);
    }

    let theoryLessons: TheoryLesson[] = [];
    if (fetchTypes.has("TheoryLesson")) {
      let q = query(
        collection(firestore, `/calendar_events`),
        where("type", "==", "TheoryLesson"),
        where(`deleted`, "==", false),
      );
      q = commonCalendarEventQueryConstraints(q, { target, id, from, to });
      const snapshot1 = await getDocs(q);
      theoryLessons = snapshot1.docs.map(theoryLessonConverter.fromFirestore);
    }

    let theoryExams: TheoryExam[] = [];
    if (fetchTypes.has("TheoryExam")) {
      let q = query(
        collection(firestore, `/calendar_events`),
        where("drivingLessonType", "==", "theoretischePruefung"),
        where(`deleted`, "==", false),
      );
      q = commonCalendarEventQueryConstraints(q, { target, id, from, to });
      const snapshot2 = await getDocs(q);
      theoryExams = snapshot2.docs.map(theoryExamConverter.fromFirestore);
    }

    let targetString = "";
    switch (target) {
      case "derived.studentUids":
      case "studentUid":
      case "studentUids":
        targetString = "student";
        break;
      case "drivingSchoolUid":
        targetString = "driving school";
        break;
      default:
        throw new Error(`Unexpected target: ${JSON.stringify(target)}`);
    }
    console.info(`Retrieved ${theoryLessons.length} theory lesson(s) for ${targetString} ${id}`);
    console.info(`Retrieved ${theoryExams.length} theory exam(s) for ${targetString} ${id}`);
    const data = (theoryLessons as Array<TheoryLesson | TheoryExam>).concat(theoryExams);
    return applyPagination(applySort(applyFilter(data, restFilters), sort), pagination);
  }

  async update(_: string, { id, data, previousData }: UpdateParams): Promise<UpdateResult<TheoryLesson | TheoryExam>> {
    const diff = deepObjectDiff(previousData, data) as Partial<TheoryLesson | TheoryExam>;
    const updateData: Partial<TheoryLesson> = {};
    const dto: EditCalendarEventDto = {};
    const unsupportedKeys: Array<string> = [];
    for (const key of Object.keys(diff)) {
      if (key in schemas.EditCalendarEventDto.shape) {
        const value = data[key];
        if (key === "start") {
          const start = value;
          const end = start.plus(previousData.end.diff(previousData.start));
          Object.assign(updateData, { start, end });
          Object.assign(dto, { start: start.toISO(), end: end.toISO() });
        } else {
          updateData[key] = value;
          dto[key as keyof EditCalendarEventDto] = value;
        }
      } else {
        unsupportedKeys.push(key);
      }
    }
    if (unsupportedKeys.length === 1) {
      throw new Error(`Changing property ${unsupportedKeys[0]} is not supported.`);
    } else if (unsupportedKeys.length > 1) {
      throw new Error(`Changing properties ${unsupportedKeys.join(", ")} is not supported.`);
    }
    if (Object.keys(updateData).length > 0) {
      const serverClient = await getAuthenticatedServerClient();
      const updateReceived = new Promise<void>((resolve) => {
        const unsubscribe = onSnapshot(doc(firestore, `calendar_events/${id}`), (snapshot) => {
          const updatedTheoryLesson = theoryLessonConverter.fromFirestore(snapshot);
          if (updateApplied(updateData, updatedTheoryLesson)) {
            unsubscribe();
            resolve();
          }
        });
      });
      await serverClient.editEvent(dto, { params: { eventId: id } });
      await updateReceived;
    }
    return this.getOne("", { id });
  }
}

export const theoryLessonsProvider = new TheoryLessonsProvider();
