import { BookedQuote } from "../providers/bookedQuotesProvider";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Skeleton,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ChangeIcon from "@mui/icons-material/CurrencyExchange";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SaveIcon from "@mui/icons-material/Save";
import { useState } from "react";
import { Bundle, catalogProvider } from "../providers/catalogProvider";
import { PaginationPayload, useGetList, useNotify, useRecordContext, useRefresh } from "react-admin";
import { autovioColors } from "../misc/backofficeTheme";
import {
  CAR_DRIVING_LICENSE_CLASSES,
  DrivingLicenseClass,
  MOTORCYCLE_DRIVING_LICENSE_CLASSES,
  TRAILER_DRIVING_LICENSE_CLASSES,
} from "../model/DrivingLicenseClass";
import { areSetsEqual, assertNever, postCondition, reportError } from "../backoffice.utils";
import { studentsProvider } from "../providers/studentsProvider";
import { PricesOverview } from "../misc/PricesOverview";
import { useDialog } from "../hooks/useDialog";
import { DialogCloseButton } from "../misc/DialogCloseButton";

/**
 * Allows to change the driving license class, price class, or bundle of a booked quote.
 */
export function ChangeBundleButton() {
  const bookedQuote = useRecordContext<BookedQuote>();
  const { dialogProps, openDialog } = useDialog();

  return (
    <>
      <Button startIcon={<ChangeIcon />} onClick={openDialog} disabled={!bookedQuote}>
        Paket ändern
      </Button>
      <ChangeBundleDialog bookedQuote={bookedQuote} {...dialogProps} />
    </>
  );
}

interface ChangeBundleDialogProps {
  open: boolean;
  onClose: () => void;
  bookedQuote: BookedQuote;
}

type ChangeBundleDialogState =
  | "choose what to change"
  | "change driving license class"
  | "change price class"
  | "change bundle"
  | "prices overview";

function ChangeBundleDialog({ open, onClose, bookedQuote }: ChangeBundleDialogProps) {
  const [dialogState, setDialogState] = useState<ChangeBundleDialogState>("choose what to change");
  const [selectedDrivingLicenseClasses, setSelectedDrivingLicenseClasses] = useState(bookedQuote.drivingLicenseClasses);
  const [selectedBundle, setSelectedBundle] = useState<Bundle | undefined>(undefined);
  const notify = useNotify();
  const refresh = useRefresh();
  const [saving, setSaving] = useState(false);
  const changeDrivingLicenseClasses = async () => {
    setSaving(true);
    try {
      const changes = bookedQuote.drivingLicenseClasses
        .map((a, i) => [a, selectedDrivingLicenseClasses[i]])
        .filter(([a, b]) => a !== b);
      if (changes.length === 0) {
        throw new Error("Invalid state: No driving license class change");
      }
      await catalogProvider.changeDrivingLicenseClasses({
        studentId: bookedQuote.studentId,
        quoteId: bookedQuote.id,
        changes: Object.fromEntries(changes),
      });
      await postCondition(async () => {
        const { data: student } = await studentsProvider.getOne("students", { id: bookedQuote.studentId });
        const expectedDrivingLicenseClasses = changes.map(([_, newDrivingLicenseClass]) => newDrivingLicenseClass);
        const actualDrivingLicenseClasses = new Set(student.bookedTrainings.map((it) => it.drivingLicenseClass));
        return expectedDrivingLicenseClasses.every((it) => actualDrivingLicenseClasses.has(it));
      });
      if (changes.length === 1) {
        notify(`Führerscheinklasse erfolgreich von ${changes[0][0]} auf ${changes[0][1]} geändert.`);
      } else {
        notify(`Führerscheinklassen erfolgreich geändert.`);
      }
      refresh();
    } catch (error) {
      reportError("Failed to change driving license classes", error);
      notify("Oje, das hat leider nicht geklappt.", { type: "error" });
    } finally {
      onClose();
    }
  };
  const changeBundle = async () => {
    setSaving(true);
    try {
      const newBundle = selectedBundle;
      if (!newBundle) {
        throw new Error("Invalid state: No bundle selected");
      }
      await catalogProvider.changeBundle({
        studentId: bookedQuote.studentId,
        oldQuoteId: bookedQuote.id,
        newBundleId: newBundle.id,
      });
      await postCondition(async () => {
        const { data: student } = await studentsProvider.getOne("students", { id: bookedQuote.studentId });
        return student.bookedTrainings.some((it) => it.bundleName === newBundle.name);
      });
      notify(`Paket ${bookedQuote.bundleName} erfolgreich durch Paket ${newBundle.name} ersetzt.`);
      refresh();
    } catch (error) {
      reportError("Failed to change bundle", error);
      notify("Oje, das hat leider nicht geklappt.", { type: "error" });
    } finally {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {dialogState === "change driving license class"
          ? "Führerscheinklasse ändern"
          : dialogState === "change price class"
            ? "Preisklasse ändern"
            : dialogState === "prices overview" && selectedBundle
              ? `Paket "${bookedQuote.bundleName}" durch Paket "${selectedBundle.name}" ersetzen`
              : "Paket ändern"}
      </DialogTitle>
      <DialogCloseButton onClose={onClose} />
      <DialogContent>
        {dialogState === "choose what to change" && (
          <ChooseWhatToChange bookedQuote={bookedQuote} setDialogState={setDialogState} />
        )}
        {dialogState === "change driving license class" && (
          <ChooseDrivingLicenseClassList
            bookedQuote={bookedQuote}
            selectedDrivingLicenseClasses={selectedDrivingLicenseClasses}
            setSelectedDrivingLicenseClasses={setSelectedDrivingLicenseClasses}
          />
        )}
        {dialogState === "change price class" && (
          <ChooseBundleList
            bookedQuote={bookedQuote}
            mode="identical driving license classes"
            selectedBundle={selectedBundle}
            setSelectedBundle={setSelectedBundle}
          />
        )}
        {dialogState === "change bundle" && (
          <ChooseBundleList
            bookedQuote={bookedQuote}
            mode="compatible driving license classes"
            selectedBundle={selectedBundle}
            setSelectedBundle={setSelectedBundle}
          />
        )}
        {dialogState === "prices overview" && selectedBundle && (
          <>
            <DialogContentText sx={{ mt: "1em", mb: "1em" }}>
              Paket "{bookedQuote.bundleName}" durch Paket "{selectedBundle.name}" ersetzen.
            </DialogContentText>
            <PricesOverview bookedQuote={bookedQuote} newBundle={selectedBundle} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        {dialogState === "change driving license class" && (
          <LoadingButton
            variant="contained"
            loading={saving}
            startIcon={<SaveIcon />}
            disabled={areSetsEqual(new Set(bookedQuote.drivingLicenseClasses), new Set(selectedDrivingLicenseClasses))}
            onClick={changeDrivingLicenseClasses}
          >
            Speichern
          </LoadingButton>
        )}
        {(dialogState === "change price class" || dialogState === "change bundle") && (
          <Button
            variant="outlined"
            startIcon={<NavigateNextIcon />}
            disabled={!selectedBundle}
            onClick={() => setDialogState("prices overview")}
          >
            Zur Preisübersicht
          </Button>
        )}
        {dialogState === "prices overview" && (
          <LoadingButton variant="contained" loading={saving} startIcon={<SaveIcon />} onClick={changeBundle}>
            Speichern
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

function ChooseWhatToChange({
  bookedQuote,
  setDialogState,
}: {
  bookedQuote: BookedQuote;
  setDialogState: (dialogState: ChangeBundleDialogState) => void;
}) {
  return (
    <List>
      <DialogContentText>Was soll geändert werden?</DialogContentText>
      <ListItemButton onClick={() => setDialogState("change driving license class")}>
        <ListItemText>
          Führerscheinklasse / Schlüsselzahl
          <br />
          <span style={{ color: autovioColors.grey, fontSize: "80%" }}>(Preise bleiben gleich)</span>
        </ListItemText>
        <NavigateNextIcon sx={{ ml: "1em" }} />
      </ListItemButton>
      <ListItemButton onClick={() => setDialogState("change price class")}>
        <ListItemText>
          Preisklasse
          <br />
          <span style={{ color: autovioColors.grey, fontSize: "80%" }}>
            {bookedQuote.trainingIds.length === 1
              ? "(Führerscheinklasse bleibt gleich)"
              : "(Führerscheinklassen bleiben gleich)"}
          </span>
        </ListItemText>
        <NavigateNextIcon sx={{ ml: "1em" }} />
      </ListItemButton>
      <ListItemButton onClick={() => setDialogState("change bundle")}>
        <ListItemText>
          Paket durch ein anderes Paket ersetzen
          <br />
          <span style={{ color: autovioColors.grey, fontSize: "80%" }}>(Für besondere Fälle.)</span>
        </ListItemText>
        <NavigateNextIcon sx={{ ml: "1em" }} />
      </ListItemButton>
    </List>
  );
}

export function ChooseDrivingLicenseClassList({
  bookedQuote,
  selectedDrivingLicenseClasses,
  setSelectedDrivingLicenseClasses,
}: {
  bookedQuote: BookedQuote;
  selectedDrivingLicenseClasses: Array<DrivingLicenseClass>;
  setSelectedDrivingLicenseClasses: (selection: Array<DrivingLicenseClass>) => void;
}) {
  return (
    <>
      <DialogContentText sx={{ mt: "1em", mb: "1em" }}>
        {bookedQuote.trainingIds.length === 1
          ? "Bitte wähle die neue Führerscheinklasse aus:"
          : "Bitte wähle die neuen Führerscheinklassen aus:"}
      </DialogContentText>
      <Stack direction="column" spacing={2}>
        {bookedQuote.drivingLicenseClasses.map((drivingLicenseClass, index) => (
          <ChooseDrivingLicenseClassListItem
            key={drivingLicenseClass}
            oldValue={drivingLicenseClass}
            newValue={selectedDrivingLicenseClasses[index]}
            onChange={(newValue) => {
              const a = selectedDrivingLicenseClasses.map((v, i) => (i === index ? newValue : v));
              setSelectedDrivingLicenseClasses(a);
            }}
          />
        ))}
      </Stack>
    </>
  );
}

function ChooseDrivingLicenseClassListItem({
  oldValue,
  newValue,
  onChange,
}: {
  oldValue: DrivingLicenseClass;
  newValue: DrivingLicenseClass;
  onChange: (value: DrivingLicenseClass) => void;
}) {
  const drivingLicenseClasses = getAlternativeDrivingLicenseClasses(oldValue);
  return (
    <Container>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <span>{oldValue}</span>
        <span>{"➔"}</span>
        <Select value={newValue} onChange={({ target: { value } }) => onChange(value as DrivingLicenseClass)}>
          {drivingLicenseClasses.map((value) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </Container>
  );
}

function getAlternativeDrivingLicenseClasses(drivingLicenseClass: DrivingLicenseClass): Array<DrivingLicenseClass> {
  if (CAR_DRIVING_LICENSE_CLASSES.has(drivingLicenseClass)) {
    return [...CAR_DRIVING_LICENSE_CLASSES];
  } else if (TRAILER_DRIVING_LICENSE_CLASSES.has(drivingLicenseClass)) {
    return [...TRAILER_DRIVING_LICENSE_CLASSES];
  } else if (MOTORCYCLE_DRIVING_LICENSE_CLASSES.has(drivingLicenseClass)) {
    return [...MOTORCYCLE_DRIVING_LICENSE_CLASSES];
  } else {
    return [drivingLicenseClass];
  }
}

function areCompatible(a: DrivingLicenseClass, b: DrivingLicenseClass) {
  return (
    a === b ||
    (CAR_DRIVING_LICENSE_CLASSES.has(a) && CAR_DRIVING_LICENSE_CLASSES.has(b)) ||
    (TRAILER_DRIVING_LICENSE_CLASSES.has(a) && TRAILER_DRIVING_LICENSE_CLASSES.has(b)) ||
    (MOTORCYCLE_DRIVING_LICENSE_CLASSES.has(a) && MOTORCYCLE_DRIVING_LICENSE_CLASSES.has(b))
  );
}

function ChooseBundleList({
  bookedQuote,
  mode,
  selectedBundle,
  setSelectedBundle,
}: {
  bookedQuote: BookedQuote;
  mode: "identical driving license classes" | "compatible driving license classes";
  selectedBundle: Bundle | undefined;
  setSelectedBundle: (selection: Bundle | undefined) => void;
}) {
  const { bundleName, drivingSchoolId, drivingLicenseClasses } = bookedQuote;
  const { data } = useGetList<Bundle>("bundles", {
    filter: { drivingSchoolId },
    sort: { field: "name", order: "ASC" },
    pagination: {} as any as PaginationPayload,
  });
  return (
    <>
      <DialogContentText sx={{ mt: "1em", mb: "1em" }}>Bitte wähle ein alternatives Paket aus:</DialogContentText>
      {!data && (
        <List>
          <Skeleton height="64px" />
          <Skeleton height="64px" />
        </List>
      )}
      {data &&
        (() => {
          const a = [...drivingLicenseClasses].sort();
          const isAlternativeBundle =
            mode === "identical driving license classes"
              ? (bundle: Bundle) => {
                  const b = bundle.trainings.map((it) => it.drivingLicenseClass).sort();
                  return a.length === b.length && b.every((v, i) => a[i] === v);
                }
              : mode === "compatible driving license classes"
                ? (bundle: Bundle) => {
                    const b = bundle.trainings.map((it) => it.drivingLicenseClass);
                    return a.length === b.length && b.every((c1) => a.some((c2) => areCompatible(c1, c2)));
                  }
                : assertNever(mode);
          const alternativeBundles = data.filter((bundle) => bundle.name !== bundleName).filter(isAlternativeBundle);
          if (alternativeBundles.length === 0) {
            if (drivingLicenseClasses.length === 1) {
              return (
                <p className="error">
                  Keine alternativen Pakete für die Führerscheinklasse {drivingLicenseClasses[0]} gefunden.
                </p>
              );
            } else {
              return (
                <p className="error">
                  Keine alternativen Pakete für die Führerscheinklassen {drivingLicenseClasses.join(", ")} gefunden.
                </p>
              );
            }
          }
          return (
            <List>
              {alternativeBundles.map((bundle) => {
                const isSelected = selectedBundle?.id === bundle.id;
                return (
                  <ListItemButton key={bundle.id} selected={isSelected} onClick={() => setSelectedBundle(bundle)}>
                    <ListItemText>
                      <span style={{ color: isSelected ? autovioColors.white : autovioColors.green }}>
                        {bundle.name}
                      </span>
                    </ListItemText>
                  </ListItemButton>
                );
              })}
            </List>
          );
        })()}
    </>
  );
}
