import { ReferenceArrayInput, SelectArrayInput } from "react-admin";
import { useIsDisabled } from "../backoffice.utils";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";
import { HiddenInput } from "./HiddenInput";

export function EntitledDrivingSchoolsInput() {
  const disabled = useIsDisabled("entitledDrivingSchools");

  if (restrictAccessToDrivingSchoolIds?.length === 1) {
    return <HiddenInput source="entitledDrivingSchools" value={[restrictAccessToDrivingSchoolIds[0]]} />;
  }

  return (
    <ReferenceArrayInput source="entitledDrivingSchools" reference="drivingSchools">
      <SelectArrayInput
        label="zugeordnete Fahrschule(n)"
        className="fix-SelectArrayInput-label"
        fullWidth
        disabled={disabled}
      />
    </ReferenceArrayInput>
  );
}
