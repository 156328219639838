import { useCallback, useState } from "react";
import { Identifier } from "react-admin";
import { useDialog } from "./useDialog";

export const useStudentDunningDialog = () => {
  const [studentId, setStudentId] = useState<Identifier>("");
  const dunningDialog = useDialog();

  const openDialog = useCallback(
    (rowStudentId: Identifier) => {
      setStudentId(rowStudentId);
      dunningDialog.openDialog();
    },
    [dunningDialog.openDialog],
  );

  const onClose = useCallback(() => {
    setStudentId("");
    dunningDialog.dialogProps.onClose();
  }, [dunningDialog.dialogProps.onClose]);

  return { dialogProps: { ...dunningDialog.dialogProps, onClose, studentId }, openDialog };
};
