import { Fragment, type ReactNode } from "react";
import { type RaRecord } from "react-admin";
import { Divider, List, Typography } from "@mui/material";
import { autovioColors } from "./backofficeTheme";

export function ListWithDividers<T extends RaRecord>({
  items,
  emptyText,
  children: renderItem,
}: {
  items: Array<T>;
  emptyText: ReactNode;
  children: (record: T) => ReactNode;
}) {
  if (items.length === 0) {
    if (typeof emptyText === "string") {
      return (
        <Typography variant="body2" sx={{ color: autovioColors.grey, mb: "10px" }}>
          {emptyText}
        </Typography>
      );
    } else {
      return emptyText;
    }
  }

  return (
    <List>
      <Divider sx={{ mb: "10px" }} />
      {items.map((item, index) => {
        return (
          <Fragment key={item.id}>
            {index > 0 && <Divider sx={{ my: "10px" }} />}
            {renderItem(item)}
          </Fragment>
        );
      })}
      <Divider sx={{ my: "10px" }} />
    </List>
  );
}
