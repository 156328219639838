import { Button } from "@mui/material";
import { useNotify, useRecordContext } from "react-admin";
import { firebaseApp } from "../firebase";
import { autovioColors, linkButtonStyle } from "../misc/backofficeTheme";
import { StripeIcon } from "../icons/StripeIcon";
import { Student } from "../providers/studentsProvider";

export function OpenInStripeButton() {
  const notify = useNotify();
  const { projectId } = firebaseApp.options;
  const student = useRecordContext<Student>();
  const { stripeCustomerId } = student;
  const stripeBaseUrl = `https://dashboard.stripe.com/${projectId !== "autovio-prod" ? "test/" : ""}`;
  const url = `${stripeBaseUrl}customers/${stripeCustomerId}`;
  const { black } = autovioColors;
  return (
    <Button
      variant="text"
      sx={{
        ...linkButtonStyle,
        color: black,
        "&:hover": {
          background: "none",
        },
      }}
      startIcon={<StripeIcon sx={{ ml: 1, fontSize: 20 }} />}
      title="In Stripe öffnen"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!stripeCustomerId) {
          notify(`Keine Stripe-ID für ${student.name} hinterlegt.`, { type: "error" });
        } else {
          open(url, "_blank");
        }
      }}
    >
      In Stripe öffnen
    </Button>
  );
}
