import { TextInput, email } from "react-admin";
import { useWatch } from "react-hook-form";
import { Box } from "@mui/material";

type EmailInputProps = Parameters<typeof TextInput>[0] & { source: string };

export function EmailInput(props: EmailInputProps) {
  const inputValue = useWatch({ name: props.source });
  const validateEmail = email("Bitte gib eine gültige E-Mail-Adresse ein.");
  const isValidEmail = inputValue && !validateEmail(inputValue);
  const { fullWidth } = props;

  return (
    <Box style={{ position: "relative", ...(fullWidth ? { width: "100%" } : {}) }}>
      <TextInput {...props} validate={props.validate ?? validateEmail} />
      {isValidEmail && (
        <div style={{ position: "absolute", top: "-6px", right: 0 }}>
          <a className="input-action-link" href={`mailto:${inputValue}`} target="_blank" tabIndex={-1}>
            E-Mail schreiben
          </a>
        </div>
      )}
    </Box>
  );
}
