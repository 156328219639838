import { List, NumberField, TextField } from "react-admin";
import { MoneyField } from "../../fields/MoneyField";
import { MostRecentLessonField } from "../../fields/MostRecentLessonField";
import { Typography } from "@mui/material";
import { ReferenceDrivingSchoolField } from "../../fields/ReferenceDrivingSchoolField";
import { Money } from "../../misc/Money";
import { autovioColors } from "../../misc/backofficeTheme";
import { GlobalDrivingSchoolFilter } from "../../misc/GlobalDrivingSchoolFilter";
import { useBalanceSum } from "../../hooks/useBalanceSum";
import { useRecoilValue } from "recoil";
import { persistentGlobalDrivingSchoolFilterState } from "../../persistent_state";
import { BackofficeListTitle } from "../../misc/BackofficeListTitle";
import { DunningDatagrid } from "./DunningDatagrid";

export function CandidatesForBlockingList() {
  const listDescription = "Liste aller aktiven Fahrschüler mit einem negativen Saldo";
  const sum = useBalanceSum("candidatesForBlocking");
  const drivingSchoolId = useRecoilValue(persistentGlobalDrivingSchoolFilterState);
  return (
    <>
      {sum ? (
        <>
          <Typography>
            {`${listDescription} • Summe ausstehende Zahlungen: `}
            <Money cents={sum} style={{ color: autovioColors.red }} />
            <sup style={{ color: autovioColors.red }}>*</sup>
          </Typography>
          <Typography color={autovioColors.grey}>
            <sup>*</sup>Es wurden nur Fahrschüler berücksichtigt, die wenigstens eine Theorie- oder Fahrstunde hatten.
          </Typography>
        </>
      ) : (
        <Typography>{listDescription}</Typography>
      )}
      <List
        resource="candidatesForBlocking"
        title={<BackofficeListTitle />}
        sort={{ field: "balance", order: "ASC" }}
        filters={[<GlobalDrivingSchoolFilter source="drivingSchoolId" alwaysOn />]}
        actions={false}
        exporter={false}
        empty={false}
      >
        <DunningDatagrid>
          <TextField label="Name" source="name" />
          {!drivingSchoolId && <ReferenceDrivingSchoolField label="Fahrschule" />}
          <MoneyField label="Saldo" source="balance" redForNegativeValues />
          <NumberField
            label="genommene Theoriestunden"
            source="kpis.finishedTheoryLessonsCount"
            options={{ maximumFractionDigits: 1 }}
          />
          <NumberField
            label="genommene Fahrstunden"
            source="kpis.finishedDrivingLessonsCount"
            options={{ maximumFractionDigits: 1 }}
          />
          <MostRecentLessonField label="letzte genommene Stunde" />
          {/* TODO: nächste gebuchte Fahrstunde, nächste gebuchte Theoriestunde, Prüfung geplant? */}
        </DunningDatagrid>
      </List>
    </>
  );
}
