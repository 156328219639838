import { useMemo, useState } from "react";
import { EventInput, DayCellMountArg } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import deLocale from "@fullcalendar/core/locales/de";
import FullCalendar from "@fullcalendar/react";
import multimonthPlugin from "@fullcalendar/multimonth";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import { Box, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { DateTime } from "luxon";
import { Row } from "../../misc/Row";
import { Button } from "react-admin";
import { Column } from "../../misc/Column";
import { HoverableNumberCircle } from "../../misc/HoverableNumberCircle";
import { DrivingLessonFormValues } from "../AddAppointmentDialog/DrivingLessonForm";
import { autovioColors } from "../../misc/backofficeTheme";

interface DrivingLessonRepetitionsCalendarProps {
  onBack: () => void;
}
export const DrivingLessonRepetitionsCalendar = ({ onBack }: DrivingLessonRepetitionsCalendarProps) => {
  const { watch, setValue } = useFormContext<DrivingLessonFormValues>();

  const initialLessonDate = watch("dateTime")!;
  const repetitions = watch("repetitions");

  const [selectedDates, setSelectedDates] = useState([initialLessonDate, ...repetitions]);

  const addRepetitionDate = (cellDetail: DateClickArg) => {
    // avoid getting fired on clicking remove date icon
    if (
      cellDetail.jsEvent.target instanceof SVGSVGElement ||
      cellDetail.jsEvent.target instanceof SVGPathElement ||
      cellDetail.jsEvent.target instanceof HTMLButtonElement
    ) {
      return;
    }
    const cellDate = DateTime.fromJSDate(cellDetail.date);
    // Prevent adding dates in the past or modifiying the initial lesson date
    if (
      cellDate.startOf("day").diffNow("milliseconds").milliseconds < 0 ||
      cellDate.hasSame(initialLessonDate, "day")
    ) {
      return;
    }
    const isSelected = selectedDates.some((date) => date.hasSame(cellDate, "day"));
    if (isSelected) {
      removeSelectedDate(cellDate);
      return;
    }
    setSelectedDates([...selectedDates, cellDate].sort((a, b) => a.toMillis() - b.toMillis()));
  };

  const removeSelectedDate = (date: DateTime) => {
    const newDates = selectedDates.filter((selectedDate) => !selectedDate.hasSame(date, "day"));
    setSelectedDates(newDates);
  };

  const saveSelectedDates = () => {
    // remove the main lesson date from the repetitions.
    setValue("repetitions", selectedDates.slice(1));
    onBack();
  };

  const renderDayCellContent = (options: DayCellMountArg) => {
    const dayDate = DateTime.fromJSDate(options.date);
    const dateIndex = selectedDates.findIndex((selectedDate) => selectedDate.hasSame(dayDate, "day"));
    const date = selectedDates[dateIndex];
    const isSameInitialLessonDate = dayDate.hasSame(initialLessonDate, "day");
    return (
      <Row
        sx={{
          p: 0,
          maxWidth: "45px",
          minWidth: "45px",
          height: "48px",
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 1,
          gap: "3px",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          background: isSameInitialLessonDate ? autovioColors.greyLight : "transparent",
        }}
      >
        <Row
          sx={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            mt: "4px",
          }}
        >
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            {options.dayNumberText}
          </Typography>
          {dateIndex !== -1 && (
            <Box sx={{ top: "-2px", right: "13px" }} position="absolute">
              <HoverableNumberCircle
                isRemovable={!date.hasSame(initialLessonDate, "day")}
                onClick={() => removeSelectedDate(date)}
                label={`${dateIndex + 1}`}
              />
            </Box>
          )}
        </Row>
      </Row>
    );
  };

  const calendarEventInputs = useMemo(() => {
    return selectedDates.map(dateToEventInput);
  }, [selectedDates]);

  return (
    <Column>
      <Row sx={{ gap: 3, alignItems: "center", mb: 3 }}>
        <Typography variant="body2">
          Wähle alle Tage aus, an denen die Fahrstunde um {initialLessonDate.toFormat("HH:mm")} Uhr stattfinden soll.
        </Typography>
        <Row sx={{ ml: "auto", gap: 1 }}>
          <Button variant="outlined" label="Zurück" size="medium" onClick={onBack} />
          <Button
            sx={{ borderRadius: "12px", px: "16px" }}
            label="Weiter"
            variant="contained"
            onClick={saveSelectedDates}
          />
        </Row>
      </Row>
      <Box width="1100px" minHeight="600px">
        <FullCalendar
          multiMonthMinWidth={280}
          dayCellContent={renderDayCellContent}
          eventDisplay="none"
          timeZone="Europe/Berlin"
          locales={[deLocale]}
          height="auto"
          plugins={[dayGridPlugin, multimonthPlugin, interactionPlugin]}
          initialView="multiMonthFourMonth"
          views={{
            multiMonthFourMonth: {
              type: "multiMonth",
              duration: { months: 6 },
              multiMonthMaxColumns: 3,
              fixedWeekCount: false,
            },
          }}
          initialDate={initialLessonDate.toISO()}
          editable={true}
          dayMaxEvents={2}
          nowIndicator={true}
          events={calendarEventInputs}
          dateClick={addRepetitionDate}
        />
      </Box>
    </Column>
  );
};

const dateToEventInput = (date: DateTime, index: number): EventInput => ({
  editable: false,
  extendedProps: {
    index,
    date,
  },
});
