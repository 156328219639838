import { SaveButton, useGetManyReference, useGetOne, useNotify } from "react-admin";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { DateTime } from "luxon";
import { ExamType } from "../../model/ExamType";
import { Student } from "../../providers/studentsProvider";
import { Instructor } from "../../providers/instructorsProvider";
import { grants } from "../../backoffice.access_control";
import { ExamLocation } from "../../model/ExamLocation";
import { renderAddress } from "../../misc/AddressDisplay";
import { SelectInput } from "../../inputs/SelectInput";
import { InstructorInput } from "../../inputs/InstructorInput";
import { DateTimeInput } from "../../inputs/DateTimeInput";
import { StudentInputField } from "./StudentInputField";
import { DrivingLicenseClassInput } from "../../inputs/DrivingLicenseClassInput";
import { VehiclesInput, OWN_MOTORCYCLE_PSEUDO_ID } from "../../inputs/VehiclesInput";
import { DrivingLicenseClass, isForMotorcycle, isForTrailer } from "../../model/DrivingLicenseClass";
import { examLocationsProvider } from "../../providers/examLocationsProvider";
import { bookExam } from "../../api/backoffice.api";
import { Column } from "../../misc/Column";
import { Row } from "../../misc/Row";
import { PriceSuggestion } from "./PriceSuggestion";

interface ExamFormProps {
  drivingSchoolId: string;
  onSuccess: () => Promise<void>;
  onFailure: (error: unknown, student?: Student) => Promise<void>;
  instructor?: Instructor;
  initialExamType?: "theoretischePruefung" | "praktischePruefung";
  initialDateTime?: DateTime;
}

interface SharedExamFormValues {
  dateTime?: DateTime;
  examType?: "theoretischePruefung" | "praktischePruefung";
  student?: Student;
}

interface UniqueTheoryExamValues {
  theoryExamLocationId?: string;
}
interface TheoryExamValues extends SharedExamFormValues, UniqueTheoryExamValues {}

interface UniquePracticalExamValues {
  instructorId?: string;
  practicalExamLocationId?: string;
  carId?: string;
  motorcycleId?: string;
  trailerId?: string;
  drivingLicenseClass?: DrivingLicenseClass;
}
interface PracticalExamValues extends SharedExamFormValues, UniquePracticalExamValues {}

export interface ExamFormValues extends TheoryExamValues, PracticalExamValues {
  drivingLessonType?: never;
}

export interface BookTheoryExamParams {
  examType: "theoretischePruefung";
  instructorId: string;
  studentId: string;
  bookedTrainingId: string;
  dateTime: DateTime;
  examLocationId: string;
}

export interface BookPracticalExamParams {
  examType: "praktischePruefung";
  instructorId: string;
  studentId: string;
  bookedTrainingId: string;
  dateTime: DateTime;
  duration?: never;
  startingPointId?: never;
  examLocationId: string;
  resourceIds: Array<string>;
}

export const ExamForm = ({
  drivingSchoolId,
  instructor,
  initialDateTime,
  initialExamType,
  onSuccess,
  onFailure,
}: ExamFormProps) => {
  const notify = useNotify();

  const defaultValues = {
    examType: initialExamType,
    dateTime: initialDateTime,
    instructorId: instructor?.id,
    student: undefined,
    theoryExamLocationId: undefined,
    practicalExamLocationId: undefined,
    carId: undefined,
    motorcycleId: undefined,
    trailerId: undefined,
    drivingLicenseClass: undefined,
  } satisfies ExamFormValues;

  const formProps = useForm<ExamFormValues>({ defaultValues });
  const { examType, dateTime } = formProps.watch();

  function validate(formValues: ExamFormValues): BookTheoryExamParams | BookPracticalExamParams {
    const {
      examType,
      dateTime,
      student,
      theoryExamLocationId,
      practicalExamLocationId,
      carId,
      motorcycleId,
      trailerId,
      drivingLicenseClass,
    } = formValues;
    if (!dateTime) {
      throw new Error(`Invalid dateTime: ${dateTime}`);
    }
    if (!examType) {
      throw new Error(`Invalid examType: ${examType}`);
    }
    if (!student) {
      throw new Error(`Invalid student: ${student}`);
    }

    if (examType === "theoretischePruefung") {
      if (!theoryExamLocationId) {
        throw new Error(`Invalid theoryExamLocationId: ${theoryExamLocationId}`);
      }

      const instructorId = formValues.instructorId || student.instructorIds[0];
      if (!instructorId) {
        throw new Error(`Student ${student.id} has no instructor`);
      }
      const relevantBookedTrainings = student.bookedTrainings.filter((it) => it.includesTheoryExam);
      // Prefer booked training for motorcycle if present ...
      const bookedTraining =
        relevantBookedTrainings.find((it) => isForMotorcycle(it.drivingLicenseClass)) ?? relevantBookedTrainings[0];
      if (!bookedTraining) {
        throw new Error(`Student ${student.id} has no booked training with a theory exam`);
      }
      return {
        examType: "theoretischePruefung",
        studentId: student.id,
        instructorId,
        bookedTrainingId: bookedTraining.id,
        dateTime,
        examLocationId: theoryExamLocationId,
      } satisfies BookTheoryExamParams;
    } else {
      // praktischePruefung
      const instructorId = formValues.instructorId;
      if (!instructorId) {
        throw new Error(`Invalid instructorId: ${instructorId}`);
      }
      if (!drivingLicenseClass) {
        throw new Error(`Invalid drivingLicenseClass: ${drivingLicenseClass}`);
      }
      let resourceIds: Array<string>;
      if (isForMotorcycle(drivingLicenseClass)) {
        if (!motorcycleId) {
          throw new Error(`Invalid motorcycleId: ${motorcycleId}`);
        }
        const studentUsesOwnMotorcycle = motorcycleId === OWN_MOTORCYCLE_PSEUDO_ID;
        if (!carId) {
          throw new Error(`Invalid carId: ${carId}`);
        }
        resourceIds = studentUsesOwnMotorcycle ? [carId] : [motorcycleId, carId];
      } else if (isForTrailer(drivingLicenseClass)) {
        if (!carId) {
          throw new Error(`Invalid carId: ${carId}`);
        }
        if (!trailerId) {
          throw new Error(`Invalid trailerId: ${motorcycleId}`);
        }
        resourceIds = [carId, trailerId];
      } else {
        if (!carId) {
          throw new Error(`Invalid carId: ${carId}`);
        }
        resourceIds = [carId];
      }
      const bookedTraining = student.bookedTrainings.find((it) => it.drivingLicenseClass === drivingLicenseClass);
      if (!bookedTraining) {
        throw new Error(
          `Student ${student.id} has no booked training for driving license class ${drivingLicenseClass}`,
        );
      }
      if (!practicalExamLocationId) {
        throw new Error(`Invalid practicalExamLocationId: ${practicalExamLocationId}`);
      }
      return {
        examType: "praktischePruefung",
        instructorId,
        studentId: student.id,
        bookedTrainingId: bookedTraining.id,
        dateTime,
        examLocationId: practicalExamLocationId,
        resourceIds,
      } satisfies BookPracticalExamParams;
    }
  }

  const onSubmit = async (formValues: ExamFormValues) => {
    try {
      const params = validate(formValues);
      const studentRsvp = params.dateTime < DateTime.now() ? "accepted" : "pending";
      const { examType, examLocationId, ...rest } = params;
      const renamedExamType = examType === "theoretischePruefung" ? "theoryExam" : "practicalExam";
      const { data: examLocation } = await examLocationsProvider.getOne("examLocations", { id: examLocationId });
      const location = examLocation.postalAddress;
      await bookExam({ ...rest, examType: renamedExamType, location, studentRsvp });
      notify(studentRsvp === "pending" ? "Einladung erfolgreich versandt" : "Prüfungstermin erfolgreich eingetragen", {
        type: "success",
      });
      await onSuccess();
    } catch (error) {
      await onFailure(error, formValues.student);
    }
  };

  return (
    <FormProvider {...formProps}>
      <form onSubmit={formProps.handleSubmit(onSubmit)} style={{ height: "100%" }}>
        <Column height="100%" minHeight={500} gap="1em">
          <DateTimeInput
            source="dateTime"
            allowPastDates={grants.includes("addExamInThePast")}
            sx={{ mt: "5px" /* <-- prevents that the input label is truncated */ }}
          />
          <ExamTypeInputField />
          {examType === "theoretischePruefung" && (
            <TheoryExamFormInputs instructor={instructor} drivingSchoolId={drivingSchoolId} />
          )}
          {examType === "praktischePruefung" && (
            <PracticalExamFormInputs instructor={instructor} drivingSchoolId={drivingSchoolId} />
          )}
          <PriceSuggestion validate={validate} />
          <Row sx={{ justifyContent: "flex-end", mt: "auto" }}>
            <SaveButton
              icon={<></>}
              label={dateTime && dateTime < DateTime.now() ? "Termin eintragen" : "Einladung senden"}
            />
          </Row>
        </Column>
      </form>
    </FormProvider>
  );
};

const TheoryExamFormInputs = ({
  instructor,
  drivingSchoolId,
}: {
  instructor?: Instructor;
  drivingSchoolId: string;
}) => {
  const { watch } = useFormContext<TheoryExamValues>();
  const student = watch("student");
  return (
    <>
      {<StudentInputField {...(instructor ? { instructorId: instructor.id } : { drivingSchoolId })} />}
      {student && <TheoryExamLocationInputField drivingSchoolId={drivingSchoolId} />}
    </>
  );
};

const PracticalExamFormInputs = ({
  instructor,
  drivingSchoolId,
}: {
  instructor?: Instructor;
  drivingSchoolId: string;
}) => {
  const { watch } = useFormContext<PracticalExamValues>();
  const student = watch("student");
  const drivingLicenseClass = watch("drivingLicenseClass");
  const instructorId = watch("instructorId");
  const { data: selectedInstructor } = useGetOne("instructors", { id: instructorId }, { enabled: !!instructorId });

  return (
    <>
      {!instructor && <InstructorInput drivingSchoolId={drivingSchoolId} source="instructorId" />}
      {instructorId && <StudentInputField {...(instructor ? { instructorId: instructor.id } : { drivingSchoolId })} />}
      {student && <DrivingLicenseClassInput source="drivingLicenseClass" student={student} />}
      {student && selectedInstructor && (
        <VehiclesInput
          student={student}
          instructor={selectedInstructor}
          drivingLessonType="praktischePruefung"
          drivingLicenseClass={drivingLicenseClass ?? ""}
        />
      )}
      {student && <PracticalExamLocationInputField drivingSchoolId={drivingSchoolId} />}
    </>
  );
};

function ExamTypeInputField() {
  return (
    <SelectInput
      source="examType"
      label="Art der Prüfung"
      options={[
        ["theoretischePruefung", "Theorieprüfung"],
        ["praktischePruefung", "Praktische Prüfung"],
      ]}
      validate={(value) => (value ? true : "Bitte wähle die Art der Prüfung aus.")}
    />
  );
}

function TheoryExamLocationInputField(props: { drivingSchoolId: string }) {
  const { data: examLocations } = useGetManyReference<ExamLocation>("examLocations", {
    target: "drivingSchoolId",
    id: props.drivingSchoolId,
    filter: { types: "theoryExam" satisfies ExamType },
    sort: { field: "name", order: "ASC" },
    pagination: { page: 1, perPage: 999 },
  });
  return (
    <SelectInput
      source="theoryExamLocationId"
      label="Ort"
      options={examLocations?.map((it) => [
        it.id,
        `${it.name} (${renderAddress(it.postalAddress, { oneLine: true })})`,
      ])}
      validate={(value) => (value ? true : "Bitte wähle den Ort aus.")}
    />
  );
}

function PracticalExamLocationInputField(props: { drivingSchoolId: string }) {
  const { data: examLocations } = useGetManyReference<ExamLocation>("examLocations", {
    target: "drivingSchoolId",
    id: props.drivingSchoolId,
    filter: { types: "practicalExam" satisfies ExamType },
    sort: { field: "name", order: "ASC" },
    pagination: { page: 1, perPage: 999 },
  });
  return (
    <SelectInput
      source="practicalExamLocationId"
      label="Startpunkt"
      options={examLocations?.map((it) => [
        it.id,
        `${it.name} (${renderAddress(it.postalAddress, { oneLine: true })})`,
      ])}
      validate={(value) => (value ? true : "Bitte wähle den Startpunkt aus.")}
    />
  );
}
