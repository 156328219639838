import { useGetManyReference } from "react-admin";
import { FieldValues, Path } from "react-hook-form";
import { Instructor } from "../providers/instructorsProvider";
import { SelectInput } from "./SelectInput";
import { SxProps } from "@mui/material";

interface InstructorInputProps {
  drivingSchoolId: string;
  source: Path<FieldValues>;
  sx?: SxProps;
}
export const InstructorInput = ({ drivingSchoolId, source, sx }: InstructorInputProps) => {
  const { data: instructors } = useGetManyReference<Instructor>("instructors", {
    target: "drivingSchoolId",
    id: drivingSchoolId,
    sort: { field: "name", order: "ASC" },
    pagination: { page: 1, perPage: 999 },
  });
  return (
    <SelectInput
      sx={sx}
      source={source}
      label="Fahrlehrer"
      options={instructors?.map((instructor) => [instructor.id, instructor.name])}
      validate={(value) => (value ? true : "Bitte wähle den Fahrlehrer aus.")}
    />
  );
};
