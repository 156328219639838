import { useState } from "react";
import { DialogProps } from "../misc/DialogProps";
import { useLocation } from "react-router-dom";

export function useDialog(dialogName?: string): {
  dialogProps: DialogProps;
  isDialogOpen: boolean;
  openDialog: () => void;
  closeDialog: () => void;
} {
  const location = useLocation();
  const [counter, setCounter] = useState(dialogName && location.search.includes(`openDialog=${dialogName}`) ? 1 : 0);

  // Return a new key whenever the dialog is closed, to ensure
  // that no state of a previously opened dialog is reused ...
  const key = counter;
  const open = counter % 2 === 1;
  const openDialog = () => {
    if (dialogName) {
      const url = new URL(window.location.href);
      url.searchParams.set("openDialog", dialogName);
      browserHistory.replace(url.toString());
    }
    setCounter((value) => {
      if (value % 2 === 1) {
        // The dialog is already open.
        return value;
      }
      return value + 1;
    });
  };
  const closeDialog = () => {
    if (dialogName) {
      const url = new URL(window.location.href);
      url.searchParams.delete("openDialog");
      browserHistory.replace(url.toString());
    }
    setCounter((value) => {
      if (value % 2 === 0) {
        // The dialog is already closed.
        return value;
      }
      return value + 1;
    });
  };
  const dialogProps: DialogProps = { key, open, onClose: closeDialog };

  return { dialogProps, isDialogOpen: open, openDialog, closeDialog };
}
