export function EmptyCalendarIcon({ width = 54, height = 56 }: { width?: number; height?: number }) {
  return (
    <svg width={width} height={height} viewBox="0 0 54 56" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <g stroke="#979797" strokeWidth="2" transform="translate(1 1)">
          <rect y="5.717" width="52" height="48" rx="3" />
          <path d="M0 21.326h51.931" />
          <circle cx="10" cy="13.717" r="2" />
          <circle cx="43" cy="13.717" r="2" />
          <path strokeLinecap="round" d="M10 10.967V0M43 10.967V0" />
        </g>
        <path
          d="M22 37.957c0-2.494.413-4.443 1.238-5.849C24.063 30.703 25.483 30 27.5 30c2.017 0 3.438.703 4.262 2.108.825 1.406 1.238 3.355 1.238 5.849 0 2.522-.413 4.493-1.238 5.913S29.517 46 27.5 46c-2.017 0-3.438-.71-4.262-2.13C22.413 42.45 22 40.48 22 37.957zm8.63 0c0-1.168-.073-2.155-.221-2.962-.148-.808-.448-1.467-.9-1.979-.45-.511-1.12-.767-2.009-.767-.888 0-1.558.256-2.01.767-.45.512-.75 1.171-.899 1.979-.148.807-.222 1.794-.222 2.962 0 1.21.07 2.227.212 3.048.14.822.44 1.485.899 1.99.458.504 1.132.756 2.02.756.888 0 1.562-.252 2.02-.756.459-.505.758-1.168.9-1.99.14-.821.21-1.837.21-3.048z"
          fill="#9B9B9B"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
