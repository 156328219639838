import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { CSSProperties, forwardRef, useImperativeHandle, useState } from "react";
import {
  Create,
  FileInput,
  Identifier,
  RecordContextProvider,
  SaveButton,
  SimpleForm,
  useGetOne,
  useNotify,
  useRecordContext,
} from "react-admin";
import { useQueryClient } from "react-query";
import { reportError } from "../backoffice.utils";
import { DateField } from "../fields/DateField";
import { useThreadId } from "../hooks/useThreadId.js";
import { autovioColors } from "../misc/backofficeTheme";
import { Spinner } from "../misc/Spinner";
import { Document } from "../model/Document";
import type { Thread } from "../model/Thread.js";
import { determineMimeType } from "../utils/determineMimeType";
import { getFileNameExtension } from "../utils/getFileNameExtension";
import { gcs } from "../utils/storage.js";

export const ThreadDocumentsList = forwardRef(({ style }: { style?: CSSProperties }, ref) => {
  const threadId = useThreadId();
  return (
    <div style={style}>
      <_ThreadDocumentsList threadId={threadId} ref={ref} />
    </div>
  );
});

export const _ThreadDocumentsList = forwardRef(({ threadId }: { threadId: string }, ref) => {
  const { data: thread, isLoading } = useGetOne<Thread>("threads", { id: threadId });
  const postsWithAttachments = thread?.sortedPosts ?? [];
  const attachments = postsWithAttachments.flatMap((post) => post.attachments ?? []);
  const documents = attachments.map((attachment) => {
    return {
      id: attachment.id,
      path: attachment.path,
      fileName: attachment.name,
      contentType: attachment.mimeType,
      createdAt: attachment.createdAt,
      getDownloadUrl: () => gcs.getDownloadUrl(attachment.path),
    };
  });
  const [state, setState] = useState<"show button" | "show form">("show button");

  useImperativeHandle(ref, function () {
    return {
      showForm: () => setState("show form"),
    };
  });

  const form = state === "show form" && (
    <_UploadDocumentForm recordId={threadId} close={() => setState("show button")} />
  );

  if (isLoading) {
    return <Spinner style={{ margin: "20px" }} />;
  }

  if (!documents.length) {
    return (
      form || (
        <>
          <Typography
            variant="body2"
            style={{
              marginTop: "20px",
              height: "40px",
              marginBottom: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            Keine Dokumente vorhanden.
          </Typography>
        </>
      )
    );
  }
  return (
    <>
      {form}
      <List sx={{ mt: "22px" }}>
        {documents.map((document) => (
          <RecordContextProvider key={document.id} value={document}>
            <_DocumentsListItem />
          </RecordContextProvider>
        ))}
      </List>
    </>
  );
});

function _DocumentsListItem() {
  const document = useRecordContext<Document>();
  const notify = useNotify();
  return (
    <ListItem
      disablePadding
      style={{
        marginTop: 0,
        marginBottom: "16px",
      }}
    >
      <ListItemButton
        sx={{ padding: 0, overflowWrap: "break-word" }}
        onClick={async () => {
          try {
            const downloadUrl = await document.getDownloadUrl();
            window.open(downloadUrl, "_blank");
          } catch (error) {
            notify("Fehler beim Öffnen des Dokuments.", { type: "error" });
            reportError(`Failed to get download URL for document ${document.id}`, error);
          }
        }}
      >
        <ListItemIcon>
          <OpenInNewIcon htmlColor={autovioColors.green} />
        </ListItemIcon>
        <ListItemText
          primary={document.fileName}
          secondary={<DateField className="RaLabeled-label" source="createdAt" showTime />}
        />
      </ListItemButton>
    </ListItem>
  );
}

function _UploadDocumentForm({ recordId, close }: { recordId: Identifier; close: () => void }) {
  const queryClient = useQueryClient();
  const [files, setFiles_] = useState<Array<File>>([]);
  const [mimeTypes, setMimeTypes] = useState<Array<string>>([]);
  const setFiles = async (newFiles: Array<File>) => {
    const newMimeTypes = await Promise.all(newFiles.map(determineMimeType));
    for (let i = 0; i < newFiles.length; i++) {
      const extension = getFileNameExtension(newFiles[i].name);
      if (extension === "pdf" && newMimeTypes[i] !== "application/pdf") {
        newMimeTypes[i] = ""; // <-- this will render the name of the invalid pdf file in red color
      }
    }
    setFiles_(files.concat(newFiles));
    setMimeTypes(mimeTypes.concat(newMimeTypes));
  };
  return (
    <Create
      resource="threadDocuments"
      className="upload-document"
      sx={{
        marginTop: 0,
        marginBottom: "8px", // ... not 16px because the <ul> below this form already has a top padding of 8px
        border: "1px solid #D8D8D8",
      }}
      record={{ recordId }}
      mutationOptions={{
        onSuccess: async () => {
          // Invalidate notes queries too, because a note is added,
          // when documents are added to a student ...
          await queryClient.invalidateQueries(["threads"]);
          close();
        },
      }}
    >
      <SimpleForm
        sx={{ padding: "6px" }}
        toolbar={
          <div style={{ padding: "0 6px 6px 6px", display: "flex", justifyContent: "flex-end" }}>
            <Button variant="outlined" size="small" onClick={close} style={{ marginRight: "6px" }}>
              Abbrechen
            </Button>
            <SaveButton />
          </div>
        }
      >
        <FileInput
          label={false}
          name="files"
          source="files"
          multiple
          options={{ onDrop: setFiles }}
          placeholder={
            <>
              {files.length === 0 && (
                <p style={{ color: "#bdbdbd" }}>
                  Datei hier hineinziehen oder
                  <br />
                  hier klicken, um Datei auszuwählen ...
                </p>
              )}
              {files.map((file: File, index) => (
                <p
                  key={file.name}
                  style={{
                    marginTop: index === 0 ? "16px" : 0,
                    marginBottom: index === files.length - 1 ? "16px" : 0,
                    textAlign: "left",
                    whiteSpace: "nowrap",
                    color: file.size === 0 || !mimeTypes[index] ? autovioColors.red : autovioColors.black,
                  }}
                >
                  {file.name} ({formatFileSize(file.size)})
                </p>
              ))}
            </>
          }
        />
      </SimpleForm>
    </Create>
  );
}

function formatFileSize(sizeInBytes: number): string {
  if (sizeInBytes <= 0) {
    return "0";
  } else if (sizeInBytes < 10000) {
    return sizeInBytes.toString();
  } else if (sizeInBytes < 10000000) {
    return `${Math.round(sizeInBytes / 1000)} KB`;
  } else {
    return `${Math.round(sizeInBytes / 1000000)} MB`;
  }
}
