import { useRecordContext } from "react-admin";
import { DrivingLicenseAuthority } from "../../providers/drivingLicenseAuthoritiesProvider";
import { Column } from "../../misc/Column";
import { drivingSchoolsProvider } from "../../providers/drivingSchoolsProvider";
import { Row } from "../../misc/Row";
import { DrivingSchoolLogo } from "../../misc/DrivingSchoolLogo";
import { Box } from "@mui/material";
import { autovioColors } from "../../misc/backofficeTheme";
import { ListWithDividers } from "../../misc/ListWithDividers";

export function AssignedBranches() {
  const authority = useRecordContext<DrivingLicenseAuthority>();

  return (
    <ListWithDividers items={authority.assignedBranches} emptyText="Keine Filialen zugeordnet.">
      {({ id: branchId, drivingSchoolId }) => {
        const drivingSchool = drivingSchoolsProvider.getOneFromCache(drivingSchoolId);
        const branch = drivingSchool?.branches.find((it) => it.uid === branchId);
        return (
          <Row gap="10px" sx={{ alignItems: "center" }}>
            <DrivingSchoolLogo drivingSchool={drivingSchool} />
            <Column>
              <Box sx={{ fontSize: "14px" }}>{drivingSchool?.name ?? `Fahrschule ${drivingSchoolId}`}</Box>
              <Box sx={{ fontSize: "12px", color: autovioColors.grey }}>{branch?.name ?? "???"}</Box>
            </Column>
          </Row>
        );
      }}
    </ListWithDividers>
  );
}
