import { AbstractProvider } from "./abstractProvider";
import {
  CreateParams,
  CreateResult,
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { getAuthenticatedServerClient, schemas } from "../api/server.api";
import { ZodiosPlugin } from "@zodios/core";
import { z } from "zod";
import isEqual from "lodash/isEqual";

type DrivingLicenseAuthorityDto = z.infer<typeof schemas.DrivingLicenseAuthorityDto>;
type CreateDrivingLicenseAuthorityDto = z.infer<typeof schemas.CreateDrivingLicenseAuthorityDto>;
type UpdateDrivingLicenseAuthorityDto = z.infer<typeof schemas.UpdateDrivingLicenseAuthorityDto>;

export type DrivingLicenseAuthority = DrivingLicenseAuthorityDto & {
  assignedBranchesIds: Array<string>;
};

export class DrivingLicenseAuthoritiesProvider extends AbstractProvider<DrivingLicenseAuthority> {
  async getList(
    _: string,
    { filter, sort, pagination }: GetListParams,
  ): Promise<GetListResult<DrivingLicenseAuthority>> {
    const { q, ...unsupportedFilterProperties } = filter;
    if (Object.keys(unsupportedFilterProperties).length > 0) {
      throw new Error(`Unexpected filter: ${JSON.stringify(filter)} -- expected: {"q": ...}`);
    }
    const serverClient = await getAuthenticatedServerClient();
    let total: undefined | number;
    const extractTotalFromContentRangeHeader: ZodiosPlugin = {
      response: async (_api, _config, response) => {
        try {
          const contentRangeHeader = response.headers["content-range"];
          if (contentRangeHeader) {
            total = parseInt(contentRangeHeader.substring(contentRangeHeader.lastIndexOf("/") + 1));
          }
        } catch (error) {
          console.warn("Failed to extract total number from Content-Range header", error);
        }
        return response;
      },
    };
    serverClient.use(extractTotalFromContentRangeHeader);
    const array = await serverClient.listDrivingLicenseAuthorities({
      queries: {
        ...(q ? { q } : {}),
        order: [{ field: sort.field, dir: sort.order.toLowerCase() as Lowercase<typeof sort.order> }],
        offset: (pagination.page - 1) * pagination.perPage,
        limit: pagination.perPage,
      },
    });
    return { data: array.map(_fromDto), total };
  }

  async getOne(_: string, { id }: GetOneParams): Promise<GetOneResult<DrivingLicenseAuthority>> {
    const serverClient = await getAuthenticatedServerClient();
    return { data: _fromDto(await serverClient.getDrivingLicenseAuthority({ params: { id } })) };
  }

  async create(
    _: string,
    { data }: CreateParams<DrivingLicenseAuthority>,
  ): Promise<CreateResult<DrivingLicenseAuthority>> {
    const serverClient = await getAuthenticatedServerClient();
    return {
      data: _fromDto(await serverClient.createDrivingLicenseAuthority(_toCreateDto(data))),
    };
  }

  async update(
    _: string,
    { id, data, previousData }: UpdateParams<DrivingLicenseAuthority>,
  ): Promise<UpdateResult<DrivingLicenseAuthority>> {
    const serverClient = await getAuthenticatedServerClient();
    return {
      data: _fromDto(
        await serverClient.updateDrivingLicenseAuthority(_toUpdateDto(data, previousData), { params: { id } }),
      ),
    };
  }
}

export const drivingLicenseAuthoritiesProvider = new DrivingLicenseAuthoritiesProvider();

function _fromDto(dto: DrivingLicenseAuthorityDto): DrivingLicenseAuthority {
  const record = {
    ...dto,
    assignedBranchesIds: dto.assignedBranches.map((it) => it.id),
  };
  record.forms.sort((a, b) => a.name.localeCompare(b.name));
  const authorityId = dto.id;
  // Include the authorityId in all form ids (as expected by the drivingLicenseAuthorityFormsProvider) ...
  for (const form of record.forms) {
    (form as any)._displayedId = form.id;
    form.id = `${authorityId}:${form.id}`;
  }
  return record;
}

function _toCreateDto(data: Partial<DrivingLicenseAuthority>): CreateDrivingLicenseAuthorityDto {
  return schemas.CreateDrivingLicenseAuthorityDto.parse(data);
}

function _toUpdateDto(
  data: Partial<DrivingLicenseAuthority>,
  previousData: DrivingLicenseAuthorityDto,
): UpdateDrivingLicenseAuthorityDto {
  const delta = Object.fromEntries(Object.entries(data).filter(([k, v]) => !isEqual(v, previousData[k])));
  return schemas.UpdateDrivingLicenseAuthorityDto.parse(delta);
}
