import { Box, Button, CircularProgress, IconButton, SxProps } from "@mui/material";
import { Row } from "../../misc/Row";
import { useCallback, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useNotify, useRecordContext } from "react-admin";
import { DrivingLicenseAuthority } from "../../providers/drivingLicenseAuthoritiesProvider";
import { BranchSelect } from "../../inputs/BranchSelect";
import CloseIcon from "@mui/icons-material/Close";
import { autovioColors } from "../../misc/backofficeTheme";
import { Branch } from "../../providers/drivingSchoolsProvider";
import { useQueryClient } from "react-query";
import { serverAPI } from "../../api/server.api";

export function AssignBranchButton({ sx }: { sx?: SxProps }) {
  const authority = useRecordContext<DrivingLicenseAuthority>();
  const { assignedBranchesIds } = authority;
  const filter = useCallback((branch: Branch) => !assignedBranchesIds.includes(branch.uid), [assignedBranchesIds]);
  const [showBranchSelect, setShowBranchSelect] = useState(false);
  const [assigningBranch, setAssigningBranch] = useState(false);
  const notify = useNotify();
  const queryClient = useQueryClient();

  const assignBranch = async (branch: Branch) => {
    try {
      setAssigningBranch(true);
      await serverAPI.assignBranchToDrivingLicenseAuthority({ branchId: branch.uid, authorityId: authority.id });
    } catch (error) {
      console.error(`Failed to assign branch ${branch.uid} to driving license authority ${authority.id}`, error);
      notify(`Fehler beim Hinzufügen der Filiale.`, { type: "error" });
    } finally {
      await queryClient.invalidateQueries(["drivingLicenseAuthorities"]);
      setAssigningBranch(false);
      setShowBranchSelect(false);
    }
  };

  return showBranchSelect ? (
    <Row sx={sx} alignItems="center" spacing="10px">
      <BranchSelect
        autoFocus
        label="Filiale hinzufügen"
        disabled={assigningBranch}
        size="small"
        onChange={assignBranch}
        filter={filter}
        sx={{ flex: 1 }}
      />
      {assigningBranch ? (
        <CircularProgress size={24} sx={{ color: "grey" }} />
      ) : (
        <IconButton size="small" onClick={() => setShowBranchSelect(false)}>
          <CloseIcon sx={{ fill: autovioColors.black }} />
        </IconButton>
      )}
    </Row>
  ) : (
    <Box sx={sx}>
      <Button
        variant="outlined"
        sx={{ height: "40px" /* ... same height as <BranchSelect> */ }}
        startIcon={<AddIcon />}
        onClick={() => setShowBranchSelect(true)}
      >
        Filiale hinzufügen
      </Button>
    </Box>
  );
}
