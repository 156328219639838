import {
  Autocomplete,
  AutocompleteRenderInputParams,
  FormControl,
  FormHelperText,
  SxProps,
  TextField as MuiTextField,
} from "@mui/material";
import { useMemo, useState } from "react";

import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { Branch, DrivingSchool, drivingSchoolsProvider } from "../providers/drivingSchoolsProvider";
import { DrivingSchoolLogo } from "../misc/DrivingSchoolLogo";
import { Row } from "../misc/Row";

export const BranchSelect = (props: {
  inputRef?: React.Ref<HTMLInputElement>;
  disabled?: boolean;
  autoFocus?: boolean;
  onChange: (branch: Branch) => void;
  onBlur?: () => void;
  sx?: SxProps;
  size: "small" | "medium";
  label: string;
  error?: false | string;
  filter?: (branch: Branch) => boolean;
  value?: Branch | null;
}) => {
  const { open, setOpen, value, setValue, inputValue, setInputValue, options } = useAutocompleteSearch(props.filter);
  return (
    <FormControl sx={props.sx}>
      <Autocomplete
        disabled={props.disabled}
        sx={props.sx}
        size={props.size}
        blurOnSelect
        openOnFocus
        autoHighlight
        clearOnEscape
        value={props.value ? branchToSearchOption(props.value) : value}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        isOptionEqualToValue={(option, value) => option.branch.uid === value.branch.uid}
        getOptionLabel={searchOptionToLabel}
        openText="Öffnen"
        closeText="Schließen"
        noOptionsText="Keine passende Filiale gefunden"
        options={options}
        onChange={(_, value) => {
          if (value) {
            props.onChange(value?.branch);
          }
          setValue(value);
        }}
        onBlur={props.onBlur}
        groupBy={(option) => option.drivingSchool.id}
        renderGroup={({ group: drivingSchoolId, children }) => {
          const drivingSchool = drivingSchoolsProvider.getOneFromCache(drivingSchoolId)!;
          const matches = match(drivingSchool.name, inputValue, { insideWords: true });
          const parts = parse(drivingSchool.name, matches);
          return (
            <div key={drivingSchoolId}>
              <Row sx={{ m: "10px" }}>
                <DrivingSchoolLogo drivingSchool={drivingSchool} size={24} />
                <div style={{ width: "10px" }} />
                {parts.map((part, index) => (
                  <span key={index} style={{ fontSize: "14px", fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text.replace(" ", "\u00A0")}
                  </span>
                ))}
              </Row>
              {children}
            </div>
          );
        }}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(option.branch.name, inputValue, { insideWords: true });
          const parts = parse(option.branch.name, matches);
          return (
            <li {...props} key={option.branch.uid}>
              {parts.map((part, index) => (
                <span key={index} style={{ fontSize: "14px", fontWeight: part.highlight ? 400 : 700 }}>
                  {part.text.replace(" ", "\u00A0")}
                </span>
              ))}
            </li>
          );
        }}
        renderInput={(params) => (
          <SearchInput autoFocus={props.autoFocus} label={props.label} error={props.error} {...params} />
        )}
      />
      {props.error && <FormHelperText error>{props.error}</FormHelperText>}
    </FormControl>
  );
};

function SearchInput(
  props: {
    label: string;
    error?: false | string;
    autoFocus?: boolean;
  } & AutocompleteRenderInputParams,
) {
  return (
    <MuiTextField
      {...props}
      autoFocus={props.autoFocus}
      label={props.label}
      size={props.size}
      InputProps={{
        ...props.InputProps,
      }}
      error={!!props.error}
    />
  );
}

interface SearchOption {
  branch: Branch;
  drivingSchool: DrivingSchool;
}

function useAutocompleteSearch(filter?: (branch: Branch) => boolean): {
  open: boolean;
  setOpen: (open: boolean) => void;
  value: SearchOption | null;
  setValue: (value: SearchOption | null) => void;
  inputValue: string;
  setInputValue: (value: string) => void;
  options: SearchOption[];
} {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<SearchOption | null>(null);
  const [inputValue, setInputValue] = useState("");
  const options = useMemo(() => {
    const options: Array<SearchOption> = [];
    const drivingSchools = [...drivingSchoolsProvider.getAllFromCache()];
    drivingSchools.sort((a, b) => a.name.localeCompare(b.name));
    for (const drivingSchool of drivingSchools) {
      for (const branch of drivingSchool.branches) {
        if (!filter || filter(branch)) {
          options.push({ branch, drivingSchool });
        }
      }
    }
    return options;
  }, [filter]);
  return { open, setOpen, value, setValue, inputValue, setInputValue, options };
}

function branchToSearchOption(branch?: Branch | null): SearchOption | null {
  if (!branch) {
    return null;
  }
  const drivingSchool = drivingSchoolsProvider.getOneFromCache(branch.drivingSchoolUid)!;
  return { branch, drivingSchool };
}

function searchOptionToLabel(option: SearchOption): string {
  return `${option.branch.name} • ${option.drivingSchool.name}`;
}
