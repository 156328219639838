import EmailIcon from "@mui/icons-material/Email";
import SecurityIcon from "@mui/icons-material/Security";
import ScienceIcon from "@mui/icons-material/Science";
import { Alert, Snackbar, Stack, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useAuthProvider } from "react-admin";
import LoadingButton from "@mui/lab/LoadingButton";
import { reportError } from "../backoffice.utils";
import { FirebaseError } from "firebase/app";

const hostname = location.hostname;
const allowLoginAsTestUser = hostname === "localhost" || hostname.includes("dev");
const allowLoginWithCustomToken = location.search.includes("cl=1");

type PageState = "show buttons" | "login with email" | "login as test user" | "login with custom token";

type LoginMethod =
  | "with Google"
  | "with Apple"
  | "with Facebook"
  | "with email + password"
  | "with custom token"
  | "as test user";

export function LoginPage() {
  const authProvider = useAuthProvider();
  const [pageState, setPageState] = useState<PageState>("show buttons");
  const [showLoginFailedAlert, setShowLoginFailedAlert] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [customToken, setCustomToken] = useState("");
  const [testUserEmail, setTestUserEmail] = useState("");
  const passwordInputRef = useRef<HTMLInputElement>();
  const [signingIn, setSigningIn] = useState<LoginMethod | "">("");
  useEffect(() => {
    if (pageState !== "show buttons") {
      const showButtonsWhenUserPressesEscape = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          setPageState("show buttons");
          event.preventDefault();
          event.stopPropagation();
        }
      };
      document.body.addEventListener("keydown", showButtonsWhenUserPressesEscape);
      return () => document.body.removeEventListener("keydown", showButtonsWhenUserPressesEscape);
    }
  }, [pageState]);

  const login = async (method: LoginMethod, fn: () => Promise<any>) => {
    setSigningIn(method);
    try {
      await fn();
      location.reload();
    } catch (error) {
      if (error instanceof FirebaseError && error.code === "auth/popup-closed-by-user") {
        return;
      }
      // Make brute force attacks harder by waiting a little bit here ...
      await new Promise((resolve) => setTimeout(resolve, 1000));
      reportError("login failed", error);
      setShowLoginFailedAlert(true);
    } finally {
      setSigningIn("");
    }
  };

  return (
    <>
      <h1 style={{ margin: "1em", position: "absolute", top: 0, left: 0 }}>{"AUTOVIO\xA0Backoffice"}</h1>
      <Snackbar
        open={showLoginFailedAlert}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
        onClose={() => setShowLoginFailedAlert(false)}
      >
        <Alert onClose={() => setShowLoginFailedAlert(false)} severity="error" sx={{ width: "100%" }}>
          Anmeldung fehlgeschlagen
        </Alert>
      </Snackbar>
      <Stack className="centered" style={{ margin: "auto", maxWidth: "300px", alignItems: "stretch" }} spacing={2}>
        {pageState === "show buttons" && (
          <LoadingButton
            key="login with Google button"
            loading={signingIn === "with Google"}
            disabled={!!signingIn}
            variant="contained"
            startIcon={<img src="/Google_G_logo.png" height="20" />}
            onClick={() => login("with Google", () => authProvider.loginWithGoogle())}
          >
            Mit Google anmelden
          </LoadingButton>
        )}
        {pageState === "show buttons" && (
          <LoadingButton
            key="login with Apple button"
            loading={signingIn === "with Apple"}
            disabled={!!signingIn}
            variant="contained"
            startIcon={<img src="/Apple_logo.png" height="20" />}
            onClick={() => login("with Apple", () => authProvider.loginWithApple())}
          >
            Mit Apple anmelden
          </LoadingButton>
        )}
        {pageState === "show buttons" && (
          <LoadingButton
            key="login with Facebook button"
            loading={signingIn === "with Facebook"}
            disabled={!!signingIn}
            variant="contained"
            startIcon={<img src="/Facebook_f_logo.png" height="20" />}
            onClick={() => login("with Facebook", () => authProvider.loginWithFacebook())}
          >
            Mit Facebook anmelden
          </LoadingButton>
        )}
        {pageState === "login with email" && (
          <TextField
            autoFocus
            margin="dense"
            label="E-Mail"
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                passwordInputRef.current?.focus();
                event.preventDefault();
                event.stopPropagation();
              }
            }}
          />
        )}
        {pageState === "login with email" && (
          <TextField
            inputRef={passwordInputRef}
            margin="dense"
            label="Passwort"
            type="password"
            fullWidth
            variant="standard"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                void login("with email + password", () => authProvider.loginWithEmailAndPassword(email, password));
                event.preventDefault();
                event.stopPropagation();
              }
            }}
          />
        )}
        {(pageState === "show buttons" || pageState === "login with email") && (
          <LoadingButton
            key="login with email button"
            loading={signingIn === "with email + password"}
            disabled={!!signingIn}
            variant="contained"
            startIcon={<EmailIcon />}
            onClick={
              pageState === "show buttons"
                ? () => setPageState("login with email")
                : () => login("with email + password", () => authProvider.loginWithEmailAndPassword(email, password))
            }
          >
            Mit E-Mail anmelden
          </LoadingButton>
        )}
        {pageState === "login as test user" && (
          <TextField
            autoFocus
            margin="dense"
            label="E-Mail"
            type="email"
            fullWidth
            variant="standard"
            value={testUserEmail}
            onChange={(event) => setTestUserEmail(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                void login("as test user", () => authProvider.loginAsTestUser(testUserEmail));
                event.preventDefault();
                event.stopPropagation();
              }
            }}
          />
        )}
        {allowLoginAsTestUser && (pageState === "show buttons" || pageState === "login as test user") && (
          <LoadingButton
            loading={signingIn === "as test user"}
            disabled={!!signingIn}
            variant="contained"
            startIcon={<ScienceIcon />}
            onClick={
              pageState === "show buttons"
                ? () => setPageState("login as test user")
                : () => login("as test user", () => authProvider.loginAsTestUser(testUserEmail))
            }
          >
            Als Testnutzer anmelden
          </LoadingButton>
        )}
        {pageState === "login with custom token" && allowLoginWithCustomToken && (
          <TextField
            margin="dense"
            label="Custom Token"
            autoFocus
            onChange={(event) => setCustomToken(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                void login("with custom token", () => authProvider.loginWithCustomToken(customToken));
                event.preventDefault();
                event.stopPropagation();
              }
            }}
          />
        )}
        {allowLoginWithCustomToken && (pageState === "show buttons" || pageState === "login with custom token") && (
          <LoadingButton
            key="login with custom token button"
            loading={signingIn === "with custom token"}
            disabled={!!signingIn}
            variant="contained"
            startIcon={<SecurityIcon />}
            onClick={
              pageState === "show buttons"
                ? () => setPageState("login with custom token")
                : () => login("with custom token", () => authProvider.loginWithCustomToken(customToken))
            }
          >
            Mit Custom Token anmelden
          </LoadingButton>
        )}
      </Stack>
    </>
  );
}
