import { type ReactElement, type ReactNode, useCallback, useEffect, useRef } from "react";
import {
  FieldProps,
  FilterPayload,
  Identifier,
  ListContextProvider,
  ListControllerResult,
  RaRecord,
  removeEmpty,
  ResourceContextProvider,
  SortPayload,
  useGetManyReference,
  useNotify,
  useRecordContext,
  useRecordSelection,
  useResourceContext,
  useSafeSetState,
} from "react-admin";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import { usePersistentPaginationState } from "../hooks/usePersistentPaginationState";
import { usePersistentSortState } from "../hooks/usePersistentSortState";

export interface ReferenceManyFieldProps<RecordType extends Record<string, unknown> = Record<string, any>>
  extends FieldProps<RecordType> {
  children: ReactNode;
  filter?: FilterPayload;
  page?: number;
  pagination?: ReactElement;
  perPage?: number;
  reference: string;
  sort?: SortPayload;
  target: string;
  meta?: any;
}

/**
 * Replacement for the react-admin component `ReferenceManyField`,
 * which persists the pagination and sort state.
 * Copied and adapted from https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/field/ReferenceManyField.tsx
 */
export const ReferenceManyField = <
  RecordType extends RaRecord = RaRecord,
  ReferenceRecordType extends RaRecord = RaRecord,
>(
  props: Omit<ReferenceManyFieldProps<RecordType>, "page" | "perPage">,
) => {
  const { children, filter = {}, pagination = null, reference, sort, target, meta } = props;
  const resource = useResourceContext();
  const record = useRecordContext(props);
  const controllerProps = useReferenceManyFieldController<RecordType, ReferenceRecordType>({
    filter,
    record: record as any,
    reference,
    resource: props.resource ?? resource,
    sort,
    target,
    meta,
  });

  return (
    <ResourceContextProvider value={reference}>
      <ListContextProvider value={controllerProps}>
        {children}
        {pagination}
      </ListContextProvider>
    </ResourceContextProvider>
  );
};

export interface UseReferenceManyFieldControllerParams<RecordType extends RaRecord = RaRecord> {
  filter?: any;
  page?: number;
  perPage?: number;
  record?: RecordType;
  reference: string;
  resource?: string;
  sort?: SortPayload;
  source?: string;
  target: string;
  meta?: any;
}

const defaultFilter = {};

// Copied and adapted from https://github.com/marmelab/react-admin/blob/master/packages/ra-core/src/controller/field/useReferenceManyFieldController.ts
export const useReferenceManyFieldController = <
  RecordType extends RaRecord = RaRecord,
  ReferenceRecordType extends RaRecord = RaRecord,
>(
  props: UseReferenceManyFieldControllerParams<RecordType>,
): ListControllerResult<ReferenceRecordType> => {
  const { reference, resource, record, target, filter = defaultFilter, sort: initialSort, meta } = props;
  const notify = useNotify();
  if (!resource) {
    if (process.env.NODE_ENV !== "production") {
      throw new Error(
        "Please add the resource property to the <ReferenceManyField> -- it is needed to determine the storageKey",
      );
    }
  }

  // pagination logic
  const { page, setPage, perPage, setPerPage } = usePersistentPaginationState({
    storageKey: `${resource}__${reference}`,
  });

  // sort logic
  const { sort, setSort: setSortState } = usePersistentSortState({
    storageKey: `${resource}__${reference}`,
    initialSort,
  });
  const setSort = useCallback(
    (sort: SortPayload) => {
      setSortState(sort);
      setPage(1);
    },
    [setPage, setSortState],
  );

  // selection logic
  const [selectedIds, selectionModifiers] = useRecordSelection(`${resource}.${record?.id}.${reference}`);

  // filter logic
  const filterRef = useRef(filter);
  const [displayedFilters, setDisplayedFilters] = useSafeSetState<{
    [key: string]: boolean;
  }>({});
  const [filterValues, setFilterValues] = useSafeSetState<{
    [key: string]: any;
  }>(filter);
  const hideFilter = useCallback(
    (filterName: string) => {
      setDisplayedFilters((previousState) => {
        const { [filterName]: _, ...newState } = previousState;
        return newState;
      });
      setFilterValues((previousState) => {
        const { [filterName]: _, ...newState } = previousState;
        return newState;
      });
    },
    [setDisplayedFilters, setFilterValues],
  );
  const showFilter = useCallback(
    (filterName: string, defaultValue: any) => {
      setDisplayedFilters((previousState) => ({
        ...previousState,
        [filterName]: true,
      }));
      setFilterValues((previousState) => ({
        ...previousState,
        [filterName]: defaultValue,
      }));
    },
    [setDisplayedFilters, setFilterValues],
  );
  const setFilters = useCallback(
    (filters: FilterPayload, displayedFilters: { [key: string]: boolean }) => {
      setFilterValues(removeEmpty(filters));
      setDisplayedFilters(displayedFilters);
      setPage(1);
    },
    [setDisplayedFilters, setFilterValues, setPage],
  );
  // handle filter prop change
  useEffect(() => {
    if (!isEqual(filter, filterRef.current)) {
      filterRef.current = filter;
      setFilterValues(filter);
    }
  });

  const { data, total, pageInfo, error, isFetching, isLoading, refetch } = useGetManyReference<ReferenceRecordType>(
    reference,
    {
      target,
      id: get(record, "id") as Identifier,
      pagination: { page, perPage },
      sort,
      filter: filterValues,
      meta,
    },
    {
      keepPreviousData: true,
      onError: (error) =>
        notify(typeof error === "string" ? error : error.message || "ra.notification.http_error", {
          type: "error",
          messageArgs: {
            _: typeof error === "string" ? error : error && error.message ? error.message : undefined,
          },
        }),
    },
  );

  return {
    sort,
    data: data as Array<ReferenceRecordType>,
    defaultTitle: "",
    displayedFilters,
    error,
    filterValues,
    hideFilter,
    isFetching,
    isLoading,
    onSelect: selectionModifiers.select,
    onToggleItem: selectionModifiers.toggle,
    onUnselectItems: selectionModifiers.clearSelection,
    page,
    perPage,
    refetch,
    resource: reference,
    selectedIds,
    setFilters,
    setPage,
    setPerPage,
    hasNextPage: pageInfo?.hasNextPage ?? (typeof total === "number" ? page * perPage < total : false),
    hasPreviousPage: pageInfo?.hasPreviousPage ?? page > 1,
    setSort,
    showFilter,
    total: total ?? -1,
  };
};
