import React, { type ReactNode } from "react";
import { EditBase, Form, type RaRecord, useDataProvider, useNotify, useRecordContext } from "react-admin";
import { useQueryClient } from "react-query";
import { FieldValues } from "react-hook-form";

export function EditForm<T extends RaRecord = any>({
  resource,
  id,
  children,
  onError,
  onSuccess,
}: {
  resource: string;
  id: T["id"];
  children: ReactNode;
  onError?: (error: any) => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}) {
  return (
    <EditBase<T> resource={resource} id={id}>
      <_LazyForm resource={resource} onError={onError} onSuccess={onSuccess}>
        {children}
      </_LazyForm>
    </EditBase>
  );
}

function _LazyForm({
  resource,
  children,
  onError,
  onSuccess,
}: {
  resource: string;
  children: ReactNode;
  onError?: (error: any) => void | Promise<void>;
  onSuccess: () => void | Promise<void>;
}) {
  const dataProvider = useDataProvider();
  const record = useRecordContext();
  const queryClient = useQueryClient();
  const notify = useNotify();

  if (!record) {
    return null;
  }

  const onSubmit = async (formData: FieldValues, event?: React.BaseSyntheticEvent) => {
    event?.preventDefault();
    let success = false;
    try {
      await dataProvider.update(resource, { id: record.id, data: formData, previousData: record });
      success = true;
    } catch (error) {
      if (onError) {
        void onError(error);
      } else {
        notify("Fehler beim Speichern der Änderungen", { type: "error" });
      }
    } finally {
      if (success) {
        await queryClient.invalidateQueries();
        void onSuccess();
      }
    }
  };

  return <Form onSubmit={onSubmit}>{children}</Form>;
}
