import { SxProps, Theme, Typography } from "@mui/material";
import { PostalAddress } from "../model/PostalAddress";
import { ReactNode } from "react";

type AddressDisplayProps = {
  address?: Partial<PostalAddress>;
  oneLine?: boolean;
  sx?: SxProps<Theme>;
  variant?: "body1" | "body2";
};

export const AddressDisplay = ({ address, oneLine, variant, sx }: AddressDisplayProps) => {
  if (!address) {
    return null;
  }
  return (
    <Typography component="span" variant={variant ?? "body2"} sx={sx}>
      {renderAddress(address, { oneLine })}
    </Typography>
  );
};

export function renderAddress(address: undefined | Partial<PostalAddress>, options?: { oneLine: true }): string;
export function renderAddress(address: undefined | Partial<PostalAddress>, options?: { oneLine?: boolean }): ReactNode;
export function renderAddress(address: undefined | Partial<PostalAddress>, options?: { oneLine?: boolean }) {
  const { street, postalCode, city } = address ?? {};
  const postalCodeAndCity = `${postalCode ?? ""} ${city ?? ""}`.trim();

  if (!street) {
    return postalCodeAndCity;
  }

  if (!postalCodeAndCity) {
    return street;
  }

  if (options?.oneLine) {
    return `${street}, ${postalCodeAndCity}`;
  }

  return (
    <>
      {street}
      <br />
      {postalCodeAndCity}
    </>
  );
}
