import {
  Datagrid,
  FunctionField,
  ReferenceArrayField,
  ReferenceField,
  Toolbar,
  ToolbarClasses,
  useRecordContext,
} from "react-admin";
import { grants } from "../../backoffice.access_control";
import { AddVehicleButton } from "../../buttons/AddVehicleButton";
import { ReferenceManyField } from "../../fields/ReferenceManyField";
import { autovioColors } from "../../misc/backofficeTheme";
import type { Vehicle } from "../../providers/resourceProviders";
import { VehicleIcon } from "../../icons/VehicleIcon";
import { PageTitle } from "../../misc/PageTitle";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import IconInACircle from "../../misc/IconInACircle";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Typography } from "@mui/material";
import { CommaSeparatedFieldList } from "../../misc/CommaSeparatedFieldList";
import { cloneElement, ReactElement } from "react";
import { DateField } from "../../fields/DateField";

export function DrivingSchoolFleet() {
  const drivingSchool = useRecordContext<DrivingSchool>();

  if (!drivingSchool) {
    return (
      <>
        <PageTitle>Fuhrpark</PageTitle>
        <LoadingIndicator />
      </>
    );
  }

  return (
    <>
      <PageTitle>Fuhrpark</PageTitle>
      <ReferenceManyField reference="vehicles" target="entitledDrivingSchoolId" label="">
        <Datagrid
          rowClick={(vehicleId) => `/vehicles/${vehicleId}?disabled=["entitledDrivingSchools"]`}
          bulkActionButtons={false}
          className="vehicles-list"
        >
          <_StyledVehicleWrapper label="Name">
            <FunctionField render={_renderIconAndName} />
          </_StyledVehicleWrapper>
          <_StyledVehicleWrapper label="Kennzeichen">
            <FunctionField render={_renderPlateNumber} />
          </_StyledVehicleWrapper>
          <_StyledVehicleWrapper label="Geteilt">
            <FunctionField render={_renderGeteilte} />
          </_StyledVehicleWrapper>
          <ReferenceArrayField label="Fahrlehrer" reference="instructors" source="entitledUsers">
            <CommaSeparatedFieldList>
              <ReferenceField source="id" reference="instructors" />
            </CommaSeparatedFieldList>
          </ReferenceArrayField>
          {grants.includes("viewDeletedVehicles") && (
            <_StyledVehicleWrapper label="Gelöscht am">
              <DateField source="deletedAt" />
            </_StyledVehicleWrapper>
          )}
        </Datagrid>
      </ReferenceManyField>
      <Toolbar className="no-padding" sx={{ backgroundColor: autovioColors.white }}>
        <div className={ToolbarClasses.defaultToolbar}>
          <div>{/* An empty div as placeholder, so that the following buttons are aligned right */}</div>
          <div>
            <AddVehicleButton type="car" style={{ marginRight: "1em" }} data-testid="add-car-button" />
            <AddVehicleButton type="motorcycle" style={{ marginRight: "1em" }} data-testid="add-motorcycle-button" />
            <AddVehicleButton type="trailer" data-testid="add-trailer-button" />
          </div>
        </div>
      </Toolbar>
    </>
  );
}

function _StyledVehicleWrapper({ children }: { children: ReactElement; label: string }) {
  const vehicle = useRecordContext<Vehicle>();

  if (vehicle?.deletedAt) {
    return cloneElement(children, { sx: { color: autovioColors.grey } });
  }

  return children;
}

function _renderGeteilte(vehicle: Vehicle) {
  return vehicle.isShared ? <DoneIcon fontSize="small" className="RaBooleanField-trueIcon" /> : null;
}

function _renderPlateNumber(vehicle: Vehicle) {
  const { type } = vehicle;
  return vehicle[type].plateNumber ?? "";
}

function _renderIconAndName(vehicle: Vehicle) {
  return (
    <Box sx={{ display: "flex", gap: "15px", alignItems: "center", marginLeft: "16px" }}>
      <IconInACircle icon={<VehicleIcon type={vehicle.type} />} />
      <Typography sx={{ fontSize: 14 }}>{vehicle.name}</Typography>
    </Box>
  );
}
