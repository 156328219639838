import { SvgIcon, SvgIconProps } from "@mui/material";

export function AttachmentIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="7" height="12" viewBox="0 0 7 12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7 8.25c0 1.04-.34 1.925-1.022 2.655C5.297 11.635 4.471 12 3.5 12c-.97 0-1.797-.365-2.478-1.095C.341 10.175 0 9.29 0 8.25V2.7C0 1.95.245 1.312.735.787 1.225.263 1.82 0 2.52 0s1.295.263 1.785.787c.49.525.735 1.163.735 1.913v5.25c0 .46-.15.85-.448 1.17-.299.32-.663.48-1.092.48-.43 0-.793-.16-1.092-.48a1.652 1.652 0 0 1-.448-1.17V2.4h1.12v5.55c0 .13.04.237.119.323.08.085.18.127.301.127a.394.394 0 0 0 .301-.127.454.454 0 0 0 .119-.323V2.7a1.572 1.572 0 0 0-.413-1.065A1.286 1.286 0 0 0 2.52 1.2c-.392 0-.723.145-.994.435-.27.29-.406.645-.406 1.065v5.55c-.01.71.22 1.313.686 1.807.467.495 1.031.743 1.694.743.653 0 1.209-.248 1.666-.743.457-.495.695-1.097.714-1.807V2.4H7v5.85z"
        fill="#888"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
}
