import { DateTime } from "luxon";
import type { ZodString, ZodType } from "zod";
import { z } from "zod";
import { Timestamp } from "firebase/firestore";

export const t = {
  ...z,
  id(): ZodString {
    return z.string().nonempty();
  },
  uid(): ZodString {
    return z.string().nonempty();
  },
  hubspotCompanyId(): ZodString {
    return z.string().nonempty();
  },
  hubspotContactId(): ZodString {
    return z.string().nonempty();
  },
  dateTime(): ZodType<DateTime> {
    return z
      .any()
      .transform((arg: unknown) => {
        if (typeof arg === "string") {
          return DateTime.fromISO(arg);
        } else if (arg instanceof DateTime) {
          return arg;
        } else if (arg instanceof Date) {
          return DateTime.fromJSDate(arg);
        } else if (arg instanceof Timestamp) {
          return DateTime.fromJSDate(arg.toDate());
        } else {
          return DateTime.invalid(
            "unexpected type",
            `unexpected type: ${(arg && (arg as any).constructor?.name) || typeof arg}`,
          );
        }
      })
      .refine(
        (it) => it.isValid,
        (it) => ({
          message: it.invalidExplanation ?? "invalid DateTime",
        }),
      );
  },
};
