import { EventContentArg } from "@fullcalendar/core";
import deLocale from "@fullcalendar/core/locales/de";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Box } from "@mui/material";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useGetManyReference } from "react-admin";
import { useLocation } from "react-router";
import { useQueryClient } from "react-query";

import { AddAppointmentDialog } from "../../dialogs/AddAppointmentDialog/AddAppointmentDialog";
import { autovioCalendarEventToEventInput, EventContent } from "../../misc/calendar/CalendarEventCardContent";
import { AutovioCalendarEvent } from "../../model/autovioCalendarEvents";
import { calendarEventsProvider } from "../../providers/calendarEventsProvider";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import { wasCancelled } from "../../utils/calendar";
import { useAutovioContext } from "../../hooks/useAutovioContext";
import { useDialog } from "../../hooks/useDialog";

export function DrivingSchoolCalendar() {
  useEffect(() => {
    document.title = "Kalender";
  }, []);
  const [{ drivingSchoolId }] = useAutovioContext();
  const { hash } = useLocation(); // allow setting date range via URL params
  const hashKeyValue: { [K in string]: string } = hash
    .slice(1)
    .split("&")
    .map((it) => it.split("="))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  const date = hashKeyValue.date ? DateTime.fromISO(hashKeyValue.date) : DateTime.now();
  const [dateRange, setDateRange] = useState<{ from: string; to: string }>({
    from: date.startOf("week").toISODate(),
    // date.endOf("week") returns a DateTime for the week's sunday at 23:59:59.999,
    // but we need the date of next weeks monday, therefore we add 13 hours ...
    to: date.endOf("week").plus({ hour: 13 }).toISODate(),
  });

  // Sometimes a calendarEvents query stays in the fetching state forever
  // and the calendar shows outdated data. The following workaround mitigates this issue ...
  const queryClient = useQueryClient();
  const [refetchCounter, setRefetchCounter] = useState(0);
  const refetch = useCallback(() => setRefetchCounter((counter) => counter + 1), []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (calendarEventsProvider.numRunningQueries === 0) {
        const fetchingQueries = (queryClient as any).queryCache.findAll({
          fetching: true,
          queryKey: ["calendarEvents"],
        });
        if (fetchingQueries.length) {
          for (const query of fetchingQueries) {
            query.reset();
          }
          refetch();
        }
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [queryClient, refetch]);

  const { data, isLoading } = useGetManyReference<AutovioCalendarEvent>(
    "calendarEvents",
    {
      target: "drivingSchoolId",
      id: drivingSchoolId,
      filter: {
        dateRange,
        type: ["TheoryLesson", ...["TheoryExam"], "PracticalExam"],
      },
      pagination: { page: 1, perPage: 9999 },
      meta: { refetchCounter },
    },
    {
      enabled: !!drivingSchoolId,
    },
  );

  const minHour = data?.reduce((acc, it) => Math.min(acc, it.start.hour), 6) ?? 6;
  const { dialogProps, openDialog } = useDialog();

  return (
    <Box>
      <FullCalendar
        timeZone="Europe/Berlin"
        locales={[deLocale]}
        height="auto"
        slotMinTime={`0${minHour}:00:00`}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        eventMinHeight={1}
        headerToolbar={{
          left: "title",
          center: "",
          right: "prev today next appointmentButton timeGridDay,timeGridWeek,dayGridMonth",
        }}
        customButtons={{
          appointmentButton: {
            text: "＋ Termin",
            click: openDialog,
          },
        }}
        initialDate={date.toISO()}
        initialView="timeGridWeek"
        scrollTime="08:00:00"
        allDaySlot={false}
        editable={false}
        weekNumbers={true}
        dayMaxEvents={true}
        nowIndicator={true}
        events={data?.map(autovioCalendarEventToEventInput) ?? []}
        eventClassNames={(arg: EventContentArg) => {
          const classNames: string[] = [];
          classNames.push("overflow-hidden");

          const event = arg.event.extendedProps?.event as AutovioCalendarEvent | undefined;
          if (!event) {
            return classNames;
          }
          if (event.student?.rsvp === "pending") {
            classNames.push("pending-event");
          }
          if (wasCancelled(event)) {
            classNames.push("canceled-event");
          }
          return classNames;
        }}
        eventContent={(eventInfo) => <EventContent eventInfo={eventInfo} for="drivingSchool" />}
        datesSet={(dates) => {
          const from = DateTime.fromJSDate(dates.start, { zone: "Europe/Berlin" }).toISODate();
          const to = DateTime.fromJSDate(dates.end, { zone: "Europe/Berlin" }).toISODate();
          if (dateRange.from !== from || dateRange.to !== to) {
            setDateRange({ from, to });
          }
        }}
        dateClick={AddAppointmentDialog.fullCalendarDateClickHandler(openDialog)}
      />
      <AddAppointmentDialog
        {...dialogProps}
        drivingSchoolId={drivingSchoolId}
        availableTabs={["exam", "theoryCourse"]}
      />
      {isLoading && <LoadingIndicator />}
    </Box>
  );
}
