import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from "@mui/material/IconButton";
import { TheoryLessonStudentAttendance } from "../model/autovioCalendarEvents";
import { useEventDialogContext } from "../contexts/EventDialogContext";
import { autovioColors } from "../misc/backofficeTheme";
import { Row } from "../misc/Row";

interface SetStudentAttendanceButtonsProps {
  studentId: string;
  studentAttendance: TheoryLessonStudentAttendance;
}
export const SetStudentAttendanceButtons = ({ studentId, studentAttendance }: SetStudentAttendanceButtonsProps) => {
  const { studentsTempAttendance, handleAttendButtonClick, handleNoShowButtonClick } = useEventDialogContext();

  const studentTempAttendance = studentsTempAttendance[studentId];
  const tempAttendedState = studentTempAttendance === true || undefined;
  const tempNoShowState = studentTempAttendance === false || undefined;
  const attended = tempAttendedState ?? (studentTempAttendance === undefined && studentAttendance.attended);
  const noShow = tempNoShowState ?? (studentTempAttendance === undefined && studentAttendance.noShow);

  return (
    <Row sx={{ gap: "12px", my: "8px", alignItems: "center" }}>
      <IconButton
        sx={{
          backgroundColor: noShow ? autovioColors.red : autovioColors.greyUltraLight,
          width: "24px",
          height: "24px",
        }}
        onClick={() => {
          handleNoShowButtonClick(studentId);
        }}
        disableRipple
        data-testid={`no-show-button-${studentId}`}
      >
        <CloseIcon
          sx={{
            fill: noShow ? autovioColors.white : autovioColors.greyLight,
            width: "16px",
            height: "16px",
          }}
        />
      </IconButton>
      <IconButton
        sx={{
          backgroundColor: attended ? autovioColors.green : autovioColors.greyUltraLight,
          width: "24px",
          height: "24px",
        }}
        onClick={() => {
          handleAttendButtonClick(studentId);
        }}
        disableRipple
        data-testid={`attended-button-${studentId}`}
      >
        <CheckIcon
          sx={{
            fill: attended ? autovioColors.white : autovioColors.greyLight,
            width: "16px",
            height: "16px",
          }}
        />
      </IconButton>
    </Row>
  );
};
