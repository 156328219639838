import { Box, Grid, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { LinearProgress, useGetManyReference } from "react-admin";
import { ApprovalStatus, Student } from "../../../providers/studentsProvider";
import { StudentTheoryExamSimulationsField } from "../../../fields/StudentTheoryExamSimulationsField";
import EditIcon from "@mui/icons-material/EditOutlined";
import { autovioColors, fullWidthTabsStyle, overviewCardStyle } from "../../../misc/backofficeTheme";
import { useState } from "react";
import { CarIcon } from "../../../icons/CarIcon";
import { InstructorIcon } from "../../../icons/InstructorIcon";
import { ExamIcon } from "../../../icons/ExamIcon";
import { SmartphoneIcon } from "../../../icons/SmartphoneIcon";
import { calculateTheoryLearningProgress } from "../../../backoffice.utils";
import { formatDate } from "../../../utils/calendar";
import { ResetTheoryLearningButton } from "../../../buttons/ResetTheoryLearningButton";
import { grants } from "../../../backoffice.access_control";
import { LicenseApplicationDialog } from "../../../dialogs/LicenseApplicationDialog";
import { useDialog } from "../../../hooks/useDialog";
import { BookedTraining } from "../../../model/BookedTraining";
import { BookedQuotesDialog } from "../../../dialogs/BookedQuotesDialog";
import { AutobahnIcon } from "../../../icons/AutobahnIcon";
import { NachtIcon } from "../../../icons/NachtIcon";
import { UeberlandIcon } from "../../../icons/UeberlandIcon";
import { ManualGearIcon } from "../../../icons/ManualGearIcon";
import { Column } from "../../../misc/Column";

const { grey, green } = autovioColors;

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

export function TrainingsCard({ student }: { student: Student }) {
  const [selectedTrainingIndex, setSelectedTrainingIndex] = useState(0);
  const training = student.bookedTrainings[selectedTrainingIndex];

  return (
    <Box sx={{ ...overviewCardStyle, padding: "20px 30px 50px" }}>
      <_BookedTrainingsTabs
        student={student}
        selectedTrainingState={[selectedTrainingIndex, setSelectedTrainingIndex]}
      />
      <_LicenseApplication student={student} training={training} />
      <_Practice training={training} />
      <_Theory student={student} training={training} />
    </Box>
  );
}

function _BookedTrainingsTabs({
  student,
  selectedTrainingState,
}: {
  student: Student;
  selectedTrainingState: [number, React.Dispatch<React.SetStateAction<number>>];
}) {
  const [selectedTrainingIndex, setSelectedTrainingIndex] = selectedTrainingState;
  const { dialogProps, openDialog } = useDialog("BookedQuotesDialog");
  return (
    <>
      <Column>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: "2px" }}>
          <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>Ausbildung</Typography>
          <IconButton sx={{ background: "transparent", color: green, width: 30, height: 30 }} onClick={openDialog}>
            <EditIcon style={{ fontSize: 20 }} />
          </IconButton>
        </Box>
        {student.bookedTrainings.length > 1 && (
          <Tabs
            value={selectedTrainingIndex}
            onChange={(_, value) => setSelectedTrainingIndex(value)}
            sx={{ ...fullWidthTabsStyle, mt: "8px" }}
            TabIndicatorProps={{ style: { display: "none" } }}
          >
            {student.bookedTrainings.map(({ shortLabel }, index) => (
              <Tab key={index} sx={{ borderBottom: "none", flex: 1 }} label={shortLabel} {...a11yProps(index)} />
            ))}
          </Tabs>
        )}
        {student.bookedTrainings.length === 1 && (
          <Typography
            variant="body2"
            sx={{ color: grey }}
          >{`Klasse ${student.bookedTrainings[0].shortLabel}`}</Typography>
        )}
      </Column>
      <BookedQuotesDialog student={student} {...dialogProps} />
    </>
  );
}

function _LicenseApplication({ student, training }: { student: Student; training: BookedTraining }) {
  const { administrativeOnboardingState, administrativeOnboardingForChangersState } = student;
  const { dialogProps, openDialog } = useDialog("LicenseApplicationDialog");

  let status: ApprovalStatus | undefined;
  if (administrativeOnboardingState) {
    status = administrativeOnboardingState.applicationApproved;
  } else if (administrativeOnboardingForChangersState) {
    status = administrativeOnboardingForChangersState.changeApproved;
  }

  if (!training.isForDrivingLicense || !status) {
    return null;
  }

  return (
    <>
      <Box sx={{ marginTop: 3, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Box>
          <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>
            {administrativeOnboardingState ? "Führerscheinantrag" : "Fahrschulwechsel"}
          </Typography>
          <Typography sx={{ fontSize: 14, color: grey }}>
            {status === "approved"
              ? `Prüfauftrag gültig bis ${
                  student.examinationAssignmentExpiresAt ? formatDate(student.examinationAssignmentExpiresAt) : "???"
                }`
              : status === "pending"
                ? "Genehmigung ausstehend"
                : status === "notApproved"
                  ? "Genehmigung abgelehnt"
                  : `[${status}]`}
          </Typography>
        </Box>
        <IconButton sx={{ background: "transparent", color: green, width: 30, height: 30 }} onClick={openDialog}>
          <EditIcon style={{ fontSize: 20 }} />
        </IconButton>
      </Box>
      <LicenseApplicationDialog student={student} {...dialogProps} />
    </>
  );
}

function _Practice({ training }: { training: BookedTraining }) {
  if (!training.isForDrivingLicense) {
    return null;
  }

  const kpis = training?.kpis;
  const finishedDrivingLessonsCount = kpis?.finishedDrivingLessonsCount;

  return (
    <Box sx={{ marginTop: 3 }}>
      <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>Praxis</Typography>
      <Grid container spacing={2} sx={{ marginTop: "-12px" }}>
        <Grid item lg={6}>
          <_TrainingItem icon={<CarIcon />} label="Übung" value={finishedDrivingLessonsCount?.normal ?? 0} unit="UE" />
        </Grid>
        <Grid item lg={6}>
          {" "}
          <_TrainingItem
            icon={<ManualGearIcon />}
            label="Schalter"
            value={kpis?.finishedManualGearLessonsCount ?? 0}
            unit="UE"
          />
        </Grid>
        <Grid item lg={6}>
          {" "}
          <_TrainingItem
            icon={<UeberlandIcon />}
            label="Überland"
            value={finishedDrivingLessonsCount?.ueberlandfahrt ?? 0}
            unit="UE"
          />
        </Grid>
        <Grid item lg={6}>
          {" "}
          <_TrainingItem
            icon={<AutobahnIcon />}
            label="Autobahn"
            value={finishedDrivingLessonsCount?.autobahnfahrt ?? 0}
            unit="UE"
          />
        </Grid>
        <Grid item lg={6}>
          {" "}
          <_TrainingItem
            icon={<NachtIcon />}
            label="Nacht"
            value={finishedDrivingLessonsCount?.nachtfahrt ?? 0}
            unit="UE"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

function _Theory({ student, training }: { student: Student; training: BookedTraining }) {
  if (!training.isForDrivingLicense) {
    return null;
  }
  const { total: numExamSimulations } = useGetManyReference("theoryExamSimulationResults", {
    target: "studentId",
    id: student.id,
    pagination: { page: 1, perPage: 10 },
    sort: { field: "timestamp", order: "DESC" },
  });

  const theoryLearningProgress = calculateTheoryLearningProgress(student.theoryKPIs?.theoryLearningProgress);

  return (
    <>
      <Box sx={{ marginTop: 3 }}>
        <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>Theorie</Typography>
        <Grid container spacing={2} sx={{ marginTop: "-12px" }}>
          <Grid item lg={6}>
            <_TrainingItem
              icon={<InstructorIcon />}
              label="Unterricht"
              value={student.theoryKPIs?.finishedTheoryLessonsCount ?? 0}
              unit="Lekt."
            />
          </Grid>
          <Grid item lg={6}>
            <_TrainingItem
              icon={<ExamIcon />}
              value={theoryLearningProgress}
              label="Lernstand"
              unit="%"
              trailing={
                theoryLearningProgress !== "0,0" &&
                grants.includes("resetTheoryLearning") && <ResetTheoryLearningButton />
              }
            />
          </Grid>
          <Grid item lg={6}>
            <_TrainingItem icon={<SmartphoneIcon />} value={numExamSimulations} label="Prüfungssim." unit="x" />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ marginTop: 3 }}>
        <Typography sx={{ fontWeight: "bold", fontSize: 15 }}>Prüfungssimulationen</Typography>
        <Box sx={{ marginLeft: -1, marginTop: 4 }}>
          <StudentTheoryExamSimulationsField />
        </Box>
      </Box>
    </>
  );
}

function _TrainingItem({
  icon,
  label,
  value,
  unit,
  trailing,
}: {
  icon: JSX.Element;
  label: string;
  value?: number | string;
  unit?: string;
  trailing?: React.ReactNode;
}) {
  const formatValue = (val: number | string) => {
    if (typeof val === "number") return Math.floor(val);
    return val;
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box>
        <IconButton
          sx={{
            background: green,
            color: "white",
            cursor: "default",
            ":hover": {
              background: green,
            },
          }}
        >
          {icon}
        </IconButton>
      </Box>
      <Box width="16px" />
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          {typeof value === "undefined" && <LinearProgress timeout={0} sx={{ width: "1.5em" }} />}
          {typeof value !== "undefined" && (
            <>
              <Typography sx={{ fontWeight: "bold", fontSize: 19 }}>{formatValue(value)}</Typography>
              {unit && <Typography sx={{ fontSize: 14 }}>{unit}</Typography>}
            </>
          )}
        </Box>
        <Typography sx={{ color: grey, fontSize: 12, marginTop: -0.5 }}>{label}</Typography>
      </Box>
      {trailing}
    </Box>
  );
}
