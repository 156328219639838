import { required, TextInput, Title, useRecordContext } from "react-admin";
import { DrivingLicenseAuthority } from "../providers/drivingLicenseAuthoritiesProvider";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Row } from "../misc/Row";
import { Card, Typography } from "@mui/material";
import { autovioColors } from "../misc/backofficeTheme";
import { NameHeader } from "../misc/NameHeader";
import { HeaderField } from "../misc/HeaderField";
import IconInACircle from "../misc/IconInACircle";
import { OfficeIcon } from "../icons/OfficeIcon";
import { LoadingIndicator } from "../misc/LoadingIndicator";
import { ShowBase } from "../misc/ShowBase";
import { BackofficeRecordTitle } from "../misc/BackofficeRecordTitle";
import { Column } from "../misc/Column";
import { DashboardCard } from "../misc/DashboardCard";
import { FormCard } from "../misc/FormCard";
import { AssignedBranches } from "./drivingLicenseAuthority/AssignedBranches";
import { AssignBranchButton } from "./drivingLicenseAuthority/AssignBranchButton";
import { AddDrivingLicenseAuthorityFormButton } from "./drivingLicenseAuthority/AddDrivingLicenseAuthorityFormButton";
import { DrivingLicenseAuthorityForms } from "./drivingLicenseAuthority/DrivingLicenseAuthorityForms";
import { UrlInput } from "../inputs/UrlInput";
import { EmailInput } from "../inputs/EmailInput";
import { PhoneNumberInput } from "../inputs/PhoneNumberInput";

/** See https://zpl.io/JQQNrX9 */
export function DrivingLicenseAuthorityDetails() {
  return (
    <ShowBase>
      <Title title={<BackofficeRecordTitle header={<DrivingLicenseAuthorityDetailsHeader />} />} />
      <_DrivingLicenseAuthorityDetails />
    </ShowBase>
  );
}

function _DrivingLicenseAuthorityDetails() {
  const authority = useRecordContext<DrivingLicenseAuthority>();

  if (!authority) {
    return <LoadingIndicator />;
  }

  // See https://mui.com/material-ui/react-grid2 ...
  return (
    <Grid2 container columns={3} spacing="30px">
      <Grid2 flex={1}>
        <Column gap="30px">
          <FormCard title="Adresse">
            <TextInput label="Name" source="name" validate={required()} fullWidth />
            <TextInput source="address.street" label="Straße" validate={required()} fullWidth />
            <Row gap="16px">
              <TextInput
                className="plz-input"
                source="address.postalCode"
                label="PLZ"
                sx={{ width: "25%" }}
                validate={required()}
              />
              <TextInput source="address.city" label="Stadt" sx={{ width: "75%" }} validate={required()} />
            </Row>
          </FormCard>
          <FormCard title="Kontaktdaten">
            <PhoneNumberInput label="Telefon" source="phone" fullWidth />
            <EmailInput label="E-Mail" source="email" fullWidth />
            <UrlInput label="Website-URL" source="websiteUrl" fullWidth />
          </FormCard>
        </Column>
      </Grid2>
      <Grid2 flex={1}>
        <Column gap="30px">
          <FormCard title="Öffnungszeiten">
            <UrlInput label="Öffnungszeiten-URL" source="openingHoursUrl" fullWidth />
          </FormCard>
          <FormCard title="Notizen & Hinweise">
            <TextInput label="" source="furtherNotes" fullWidth multiline />
          </FormCard>
          <DashboardCard title="Formulare">
            <DrivingLicenseAuthorityForms />
            <AddDrivingLicenseAuthorityFormButton />
          </DashboardCard>
        </Column>
      </Grid2>
      <Grid2 flex={1}>
        <DashboardCard title="Zugeordnete Filialen">
          <AssignedBranches />
          <AssignBranchButton />
        </DashboardCard>
      </Grid2>
    </Grid2>
  );
}

function DrivingLicenseAuthorityDetailsHeader() {
  const authority = useRecordContext<DrivingLicenseAuthority>();

  return (
    <Card sx={{ my: "30px", padding: "25px 30px", width: "100%" }}>
      <Row sx={{ my: "20px" }} spacing="50px" alignItems="start">
        <Row spacing="20px" alignItems="center">
          <IconInACircle
            style={{ backgroundColor: "#eee", size: 60 }}
            icon={<OfficeIcon sx={{ width: "30px", height: "30px" }} />}
          />
          <Column sx={{ ml: "20px" }}>
            <NameHeader>{authority.name}</NameHeader>
            <Typography sx={{ color: autovioColors.grey, fontSize: "14px", lineHeight: "1.43" }}>
              {`Führerscheinstelle in ${authority.address.city}`}
            </Typography>
          </Column>
        </Row>
        <Row sx={{ flexWrap: "wrap" }} alignItems="center">
          <HeaderField label="Zugeordnete Filialen">{authority.assignedBranches.length}</HeaderField>
        </Row>
      </Row>
    </Card>
  );
}
