import { BackofficeListTitle } from "../../misc/BackofficeListTitle";
import { List, NumberField, TextField } from "react-admin";
import { MoneyField } from "../../fields/MoneyField";
import { MostRecentLessonField } from "../../fields/MostRecentLessonField";
import { Typography } from "@mui/material";
import { SendSecondDunningNoticeButton } from "../../buttons/SendSecondDunningNoticeButton";
import { ReferenceDrivingSchoolField } from "../../fields/ReferenceDrivingSchoolField";
import { Money } from "../../misc/Money";
import { autovioColors } from "../../misc/backofficeTheme";
import { DateField } from "../../fields/DateField";
import { GlobalDrivingSchoolFilter } from "../../misc/GlobalDrivingSchoolFilter";
import { useBalanceSum } from "../../hooks/useBalanceSum";
import { useRecoilValue } from "recoil";
import { persistentGlobalDrivingSchoolFilterState } from "../../persistent_state";
import { DunningDatagrid } from "./DunningDatagrid";

export function CandidatesForSecondDunningNoticeList() {
  const drivingSchoolId = useRecoilValue(persistentGlobalDrivingSchoolFilterState);
  const listDescription = "Liste aller Fahrschüler, die bereits einmal erfolglos angemahnt wurden";
  const sum = useBalanceSum("candidatesForSecondDunningNotice", "candidatesForSecondDunningNoticeParkingLot");

  return (
    <>
      {sum ? (
        <Typography>
          {`${listDescription} • Summe ausstehende Zahlungen: `}
          <Money cents={sum} style={{ color: autovioColors.red }} />
        </Typography>
      ) : (
        <Typography>{listDescription}</Typography>
      )}
      <List
        resource="candidatesForSecondDunningNotice"
        title={<BackofficeListTitle />}
        filters={[<GlobalDrivingSchoolFilter source="drivingSchoolId" alwaysOn />]}
        sort={{ field: "balance", order: "ASC" }}
        actions={false}
        exporter={false}
        empty={false}
      >
        <DunningDatagrid>
          <TextField label="Name" source="name" />
          {!drivingSchoolId && <ReferenceDrivingSchoolField label="Fahrschule" />}
          <MoneyField label="Saldo" source="balance" redForNegativeValues />
          <TextField label="Status" source="verboseStatus" />
          <NumberField
            label="genommene Theoriestunden"
            source="kpis.finishedTheoryLessonsCount"
            options={{ maximumFractionDigits: 1 }}
          />
          <NumberField
            label="genommene Fahrstunden"
            source="kpis.finishedDrivingLessonsCount"
            options={{ maximumFractionDigits: 1 }}
          />
          <MostRecentLessonField label="letzte genommene Stunde" />
          <DateField label="1. Mahnung" source="dunningProcess.firstDunningNoticeSentAt" showDate="with diff" />
          <SendSecondDunningNoticeButton />
        </DunningDatagrid>
      </List>
    </>
  );
}
