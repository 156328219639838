import { TextInput } from "react-admin";
import { useWatch } from "react-hook-form";
import { url } from "../utils/validators";
import { Box } from "@mui/material";

type UrlInputProps = Parameters<typeof TextInput>[0] & { source: string };

export function UrlInput(props: UrlInputProps) {
  const inputValue = useWatch({ name: props.source });
  const validateUrl = url();
  const isValidUrl = inputValue && !validateUrl(inputValue);
  const { fullWidth } = props;

  return (
    <Box style={{ position: "relative", ...(fullWidth ? { width: "100%" } : {}) }}>
      <TextInput {...props} validate={props.validate ?? validateUrl} />
      {isValidUrl && (
        <div style={{ position: "absolute", top: "-6px", right: 0 }}>
          <a className="input-action-link" href={inputValue} target="_blank" tabIndex={-1}>
            Link öffnen
          </a>
        </div>
      )}
    </Box>
  );
}
