import { Box, ListItem, ListItemButton } from "@mui/material";
import { Row } from "./Row";
import { autovioColors } from "./backofficeTheme";
import EditIcon from "@mui/icons-material/EditOutlined";
import { type ComponentType, type PropsWithChildren, useEffect, useRef, useState } from "react";
import { useDialog } from "../hooks/useDialog";
import { DialogProps } from "./DialogProps";
import { type RaRecord, useRecordContext } from "react-admin";

type EditableListItemProps = PropsWithChildren<{
  dialog: ComponentType<DialogProps>;
  record?: RaRecord;
}>;

/**
 * A list item for a `ListWithDividers` component,
 * which shows a pen icon on hover and opens the
 * given dialog on click.
 */
export function EditableListItem({ children, dialog: DialogComponent }: EditableListItemProps) {
  const record = useRecordContext();
  const [hovered, setHovered] = useState(false);
  const [focused, setFocused] = useState(false);
  const { dialogProps, openDialog } = useDialog(`${DialogComponent.name}!${(record as any)._displayedId ?? record.id}`);
  const showEditIcon = hovered || focused || dialogProps.open;
  // The `onFocus` and `onBlur` callbacks of <ListItemButton> do not work reliable,
  // therefore we use a MutationObserver to watch if the <ListItemButton> is focused ...
  const listItemButtonRef = useRef<HTMLDivElement>();
  useEffect(() => {
    const div = listItemButtonRef.current;
    if (div) {
      const mutationObserver = new MutationObserver(() => {
        setFocused(div.classList.contains("Mui-focusVisible"));
      });
      mutationObserver.observe(div, { attributeFilter: ["class"] });
      return () => mutationObserver.disconnect();
    }
  }, [listItemButtonRef.current]);

  return (
    <ListItem disablePadding>
      <ListItemButton
        ref={listItemButtonRef /* make tsc happy: */ as any}
        sx={{
          pl: 0,
          /* make the highlight-on-hover effect go from divider to divider: */ my: "-10px",
          py: "10px",
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={openDialog}
      >
        <Row gap="10px" sx={{ width: "100%", alignItems: "center" }}>
          {children}
          <Box sx={{ flex: 1 }} />
          <Box
            sx={{
              display: showEditIcon ? "block" : "hidden",
              opacity: showEditIcon ? 1 : 0,
              transition: "opacity 0.15s",
            }}
          >
            <EditIcon style={{ fill: autovioColors.green, fontSize: 20 }} />
          </Box>
        </Row>
      </ListItemButton>
      <DialogComponent {...dialogProps} />
    </ListItem>
  );
}
