import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  Card,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { diff } from "deep-object-diff";
import { ReactElement, useMemo, useState } from "react";
import { grants } from "../../backoffice.access_control";
import {
  AutovioCalendarEvent,
  isPaidLesson,
  isTheoryExam,
  isTheoryLesson,
  isDrivingLesson,
} from "../../model/autovioCalendarEvents";
import { AutovioCalendarEventHistory } from "../../providers/calendarEventHistoryProvider";
import { calendarEventTitle, formatDateTime } from "../../utils/calendar";
import { EventIdBar } from "../IdBar";
import { EventInfoCard } from "./EventInfoCard";
import { autovioColors } from "../backofficeTheme";
import { DateTime } from "luxon";
import { UserDisplayName } from "../UserDisplayName";
import { useGetOne } from "react-admin";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { CancelAppointmentDialog } from "./CancelAppointmentDialog";
import { useDialog } from "../../hooks/useDialog";
import { DeleteInvitationDialog } from "./DeleteInvitationDialog";
import { DialogCloseButton } from "../DialogCloseButton";
import ChangedIcon from "@mui/icons-material/EditOutlined";
import BookedIcon from "@mui/icons-material/ThumbUpOffAlt";
import CreatedIcon from "@mui/icons-material/AddCircleOutline";
import CanceledIcon from "@mui/icons-material/HighlightOff";
import DeclinedIcon from "@mui/icons-material/ThumbDownOffAlt";
import EditDrivingLessonForm from "./EditDrivingLessonForm";
import { EditTheoryLessonForm } from "./EditTheoryLessonForm";
import { EventDialogProvider, useEventDialogContext } from "../../contexts/EventDialogContext";
import LoadingButton from "@mui/lab/LoadingButton";

export const useEventDialog = () => {
  return {
    canBeOpenedInDialog: (event?: AutovioCalendarEvent): boolean => {
      switch (event?.type) {
        case "ASFCourseSession":
        case "DrivingLesson":
        case "TheoryLesson":
        case "TheoryExam":
        case "Other":
          return true;
        default:
          return false;
      }
    },
  };
};

type EventDialogProps = {
  event: AutovioCalendarEvent;
  isOpen: boolean;
  onClose: () => void;
};

type DialogMode = "view" | "edit";

export const EventDialog = ({ event, isOpen, onClose }: EventDialogProps) => {
  const {
    isDialogOpen: isDeleteDialogOpen,
    openDialog: openDeleteDialog,
    closeDialog: closeDeleteDialog,
  } = useDialog();
  const {
    isDialogOpen: isCancelDialogOpen,
    openDialog: openCancelDialog,
    closeDialog: closeCancelDialog,
  } = useDialog();
  const [mode, setMode] = useState<DialogMode>("view");
  const showDeleteButton = (isTheoryExam(event) || isPaidLesson(event)) && event.student.rsvp === "pending";
  const showCancelButton = (isTheoryExam(event) || isPaidLesson(event)) && event.student.rsvp === "accepted";
  const showEditButton =
    !event.deleted && ((isDrivingLesson(event) && event.start > DateTime.now()) || isTheoryLesson(event));

  return (
    <EventDialogProvider event={event}>
      <Dialog open={isOpen} onClose={onClose} maxWidth="lg">
        <DialogTitle>
          <EventDialogHeader event={event} />
        </DialogTitle>
        <DialogCloseButton onClose={onClose} />
        <DialogContent sx={{ p: 0 }}>
          <EventDialogBody
            event={event}
            mode={mode}
            closeEditMode={() => setMode("view")}
            openCancelDialog={openCancelDialog}
          />
        </DialogContent>
        <EventDialogActions
          mode={mode}
          showEditButton={showEditButton}
          showDeleteButton={showDeleteButton}
          showCancelButton={showCancelButton}
          openDeleteDialog={openDeleteDialog}
          openCancelDialog={openCancelDialog}
          showEditForm={() => setMode("edit")}
        />
      </Dialog>
      <DeleteInvitationDialog
        isOpen={isDeleteDialogOpen}
        invitation={event}
        onClose={(invitationDeleted) => {
          closeDeleteDialog();
          if (invitationDeleted) {
            onClose();
          }
        }}
      />
      <CancelAppointmentDialog
        isOpen={isCancelDialogOpen}
        calendarEvent={event}
        onClose={(appointmentCancelled) => {
          closeCancelDialog();
          if (appointmentCancelled) {
            onClose();
          }
        }}
      />
    </EventDialogProvider>
  );
};

const EventDialogHeader = (props: { event: AutovioCalendarEvent }) => {
  const invitationPrefix = props.event.student?.rsvp === "pending" ? "Einladung - " : "";
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
      <span>{invitationPrefix + calendarEventTitle(props.event)}</span>
      {grants.includes("viewFirestore") && <EventIdBar id={props.event.id} />}
    </Stack>
  );
};

interface EventDialogBodyProps {
  event: AutovioCalendarEvent;
  mode: DialogMode;
  closeEditMode: () => void;
  openCancelDialog: () => void;
}

const EventDialogBody = ({ event, mode, closeEditMode, openCancelDialog }: EventDialogBodyProps) => {
  if (mode === "edit") {
    switch (event.type) {
      case "DrivingLesson":
        return <EditDrivingLessonForm event={event} closeEditMode={closeEditMode} />;
      case "TheoryLesson":
        return <EditTheoryLessonForm event={event} closeEditMode={closeEditMode} openCancelDialog={openCancelDialog} />;
      default:
        return null;
    }
  }
  return (
    <Stack direction="column" spacing="1em">
      <ShortEventHistory event={event} />
      <Box px="1.5em">
        <EventInfoCard event={event} showStartedAtEndedAt={grants.includes("viewAdditionalEventDetails")} />
      </Box>
      {event.updatedAt && (
        <Box px="1.5em" py="1em">
          <EventChangeLog event={event} />
        </Box>
      )}
    </Stack>
  );
};

interface EventDialogActionsProps {
  mode: DialogMode;
  showEditButton: boolean;
  showDeleteButton: boolean;
  showCancelButton: boolean;
  openDeleteDialog: () => void;
  openCancelDialog: () => void;
  showEditForm: () => void;
}

const EventDialogActions = ({
  mode,
  showEditButton,
  showDeleteButton,
  showCancelButton,
  openDeleteDialog,
  openCancelDialog,
  showEditForm,
}: EventDialogActionsProps) => {
  const { isSaving, studentsTempAttendance, handleSaveStudentsAttendance } = useEventDialogContext();

  if (mode === "edit") return null;

  const showSaveAttendanceButton = Object.keys(studentsTempAttendance).length > 0;

  return (
    <DialogActions sx={{ mt: "4px" }}>
      {showEditButton && (
        <Button
          variant="outlined"
          sx={{ height: "40px" /* ... same height as other buttons in dialog. */ }}
          startIcon={<EditIcon />}
          onClick={showEditForm}
        >
          Termin bearbeiten
        </Button>
      )}
      {showSaveAttendanceButton && (
        <LoadingButton loading={isSaving} variant="contained" onClick={handleSaveStudentsAttendance}>
          Anwesenheit speichern
        </LoadingButton>
      )}
      {showDeleteButton && (
        <Button
          variant="outlined"
          sx={{ height: "40px" /* ... same height as other buttons in dialog. */ }}
          startIcon={<DeleteIcon />}
          onClick={openDeleteDialog}
        >
          Einladung löschen
        </Button>
      )}
      {showCancelButton && (
        <Button
          variant="outlined"
          sx={{ height: "40px" /* ... same height as other buttons in dialog. */ }}
          startIcon={<CancelIcon />}
          onClick={openCancelDialog}
        >
          Termin absagen
        </Button>
      )}
    </DialogActions>
  );
};

const ShortEventHistory = ({ event }: { event: AutovioCalendarEvent }) => {
  const createdAt = event.createdAt;
  const { data: createdBy } = useGetOne("users", { id: event.createdBy }, { enabled: !!event.createdBy });
  const createdByStudent = event.createdBy === event.student?.uid;
  const bookedAt = event.student?.bookedAt;
  const declinedAt = event.student?.declinedAt;
  const canceledByStudentAt = event.student?.canceledAt;
  const cancelReason = event.student?.cancelReason;
  const deletedAt = event.deletedAt;
  const deletedByUserUid = event.deletedBy;
  const deleteReason = event.deletionReason;
  const studentId = event.student?.uid;
  const { data: student } = useGetOne("students", { id: studentId }, { enabled: !!studentId });
  const { data: deletedBy } = useGetOne("users", { id: event.deletedBy }, { enabled: !!deletedByUserUid });

  return (
    <Box px="1.5em" sx={{ color: "#9b9b9b" }}>
      {!createdByStudent && (
        <Typography>
          Erstellt: {formatDateTime(createdAt)} von {createdBy?.name ?? "..."}
        </Typography>
      )}
      {createdByStudent && (
        <Typography>
          Erstellt/Gebucht: {formatDateTime(createdAt)} von {createdBy?.name ?? "..."}
        </Typography>
      )}
      {!createdByStudent && bookedAt && (
        <Typography>
          Gebucht: {formatDateTime(bookedAt)} von {student?.name ?? "..."}
        </Typography>
      )}
      {declinedAt && (
        <Typography>
          Abgelehnt: {formatDateTime(declinedAt)} von {student?.name ?? "..."}
        </Typography>
      )}
      {canceledByStudentAt && (
        <Typography>
          Abgesagt: {formatDateTime(canceledByStudentAt)} von {student?.name ?? "..."}
          {cancelReason && `, Grund: ${cancelReason}`}
        </Typography>
      )}
      {event.deleted && deletedAt && (
        <Typography>
          Abgesagt: {formatDateTime(deletedAt)} von {deletedBy?.name ?? "..."}
          {deleteReason && `, Grund: ${deleteReason}`}
        </Typography>
      )}
    </Box>
  );
};

const EventChangeLog = ({ event }: { event: AutovioCalendarEvent }) => {
  const [isExpanded, setIsExpanded_] = useState(location.search.includes("showHistory"));
  const setIsExpanded = (value: boolean) => {
    // Side effect: update browser URL ...
    const url = new URL(location.href);
    if (value) {
      url.searchParams.set("showHistory", "1");
      browserHistory.replace(url.toString());
    } else {
      url.searchParams.delete("showHistory");
      browserHistory.replace(url.toString());
    }
    setIsExpanded_(value);
  };
  const { data: history } = useGetOne<AutovioCalendarEventHistory>(
    "calendarEventHistory",
    { id: event.id, meta: { drivingSchoolId: event.drivingSchoolId } },
    { enabled: !!event.updatedAt },
  );

  if (!event.updatedAt) {
    return null;
  }

  const { events, changes } = history ?? {};
  const originalEvent = events?.at(-1);

  return (
    <Stack direction="column" spacing="1em">
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{ cursor: "pointer" }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Stack direction="column">
          {!changes && <Typography variant="h6">Verlauf</Typography>}
          {changes && changes.length === 1 && <Typography variant="h6">Verlauf (1 Änderung)</Typography>}
          {changes && changes.length > 1 && <Typography variant="h6">Verlauf ({changes.length} Änderungen)</Typography>}
        </Stack>
        <Box>
          <IconButton>{isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
        </Box>
      </Stack>
      <Collapse in={isExpanded} enter={false} exit={false} unmountOnExit>
        <Stack direction="column" spacing="1em">
          {event.deletedAt && event.deletedBy && (
            <EventChangeLogAction what="Abgesagt" at={event.deletedAt} by={event.deletedBy} />
          )}
          {event.student.canceledAt && (
            <EventChangeLogAction what="Abgesagt" at={event.student.canceledAt} by={event.student.uid} />
          )}
          {event.student.declinedAt && (
            <EventChangeLogAction what="Einladung abgelehnt" at={event.student.declinedAt} by={event.student.uid} />
          )}
          {event.student.bookedAt && event.student.bookedAt > (event.updatedAt ?? event.createdAt) && (
            <EventChangeLogAction what="Gebucht" at={event.student.bookedAt} by={event.student.uid} />
          )}
          {changes?.flatMap(([oldEvent, newEvent], i) => {
            const a: Array<ReactElement> = [];
            if (i > 0 && newEvent.student.rsvp === "pending") {
              // Check if the old event of the last change was booked ...
              const bookedAt = changes[i - 1][0].student.bookedAt;
              if (bookedAt) {
                a.push(
                  <EventChangeLogAction
                    key={`booked_${i - 1}_${i}`}
                    what="Gebucht"
                    at={bookedAt}
                    by={changes[i - 1][0].student.uid}
                  />,
                );
              }
            }
            a.push(<EventChangeLogEntry key={`change_${i}`} oldEvent={oldEvent} newEvent={newEvent} />);
            return a;
          })}
          {originalEvent && originalEvent.createdBy === originalEvent.student?.uid && (
            <EventChangeLogAction what="Erstellt/Gebucht" at={originalEvent.createdAt} by={originalEvent.createdBy} />
          )}
          {originalEvent && originalEvent.createdBy !== originalEvent.student?.uid && (
            <EventChangeLogAction what="Erstellt" at={originalEvent.createdAt} by={originalEvent.createdBy} />
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const EventChangeLogEntry = (props: { oldEvent: AutovioCalendarEvent; newEvent: AutovioCalendarEvent }) => {
  const { oldEvent, newEvent } = props;

  const changedKeys = useMemo(() => {
    const eventDiff = diff(oldEvent, newEvent);
    const changedKeys = Object.keys(eventDiff);

    // Determine if the event duration has changed
    const oldDuration = oldEvent.end.diff(oldEvent.start);
    const newDuration = newEvent.end.diff(newEvent.start);
    if (!oldDuration.equals(newDuration)) {
      changedKeys.push("duration");
    }

    // Determine if the booked training has changed
    const oldBookedTrainingId = oldEvent.student?.bookedTrainingId;
    const newBookedTrainingId = newEvent.student?.bookedTrainingId;
    if (oldBookedTrainingId !== newBookedTrainingId) {
      changedKeys.push("bookedTrainingId");
    }

    return changedKeys;
  }, [oldEvent.id, newEvent.id]);

  return (
    <Card sx={newEvent.editReason ? { bgcolor: autovioColors.redUltraLight } : {}}>
      <Stack direction="column">
        <Typography variant="body1" px="16px" py="10px">
          <ChangedIcon sx={{ mt: "3px", mb: "-3px", mr: "3px", fontSize: "16px" }} />
          Geändert am {<b>{formatDateTime(newEvent.updatedAt ?? newEvent.createdAt)}</b>} von{" "}
          {
            <UserDisplayName
              component="span"
              userId={newEvent.updatedBy ?? newEvent.createdBy}
              variant="body1"
              fontWeight="bold"
            />
          }
          {newEvent.editReason?.trim() ? (
            <>
              {" (Grund: "}
              <b>{newEvent.editReason.trim()}</b>
              {")"}
            </>
          ) : null}
        </Typography>
        <Divider />
        <Stack direction="row" spacing="24px" p="16px">
          <Box flex="1">
            <EventInfoCard
              event={oldEvent}
              mode="beforeChange"
              changedKeys={changedKeys}
              chip={<Chip label="Vorher" size="small" sx={{ bgcolor: "#fff0c8" }} />}
            />
          </Box>
          <div style={{ borderLeft: "1px solid #E3E3E7", flex: 0 }} />
          <Box flex="1">
            <EventInfoCard
              event={newEvent}
              mode="afterChange"
              changedKeys={changedKeys}
              chip={<Chip label="Nachher" size="small" sx={{ bgcolor: "#d3f9e0" }} />}
            />
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
};

const EventChangeLogAction = ({
  what,
  at,
  by,
}: {
  what?: "Erstellt" | "Erstellt/Gebucht" | "Gebucht" | "Einladung abgelehnt" | "Abgesagt";
  at: DateTime;
  by: string;
}) => {
  const iconSx = { mt: "3px", mb: "-3px", mr: "3px", fontSize: "16px" };
  return (
    <Card>
      <Typography variant="body1" px="16px" py="10px">
        {what === "Erstellt" && <CreatedIcon sx={iconSx} />}
        {what === "Erstellt/Gebucht" && <BookedIcon sx={iconSx} />}
        {what === "Gebucht" && <BookedIcon sx={iconSx} />}
        {what === "Einladung abgelehnt" && <DeclinedIcon sx={iconSx} />}
        {what === "Abgesagt" && <CanceledIcon sx={iconSx} />}
        {what} am {<b>{formatDateTime(at)}</b>} von{" "}
        {<UserDisplayName component="span" userId={by} variant="body1" fontWeight="bold" />}
      </Typography>
    </Card>
  );
};
