import { EditBase, NumberInput, SaveButton, SimpleForm, Toolbar, useRecordContext } from "react-admin";
import { FeatureTogglesInput } from "../../inputs/FeatureTogglesInput";
import { Student } from "../../providers/studentsProvider";

export function StudentAdminForm() {
  const student = useRecordContext<Student>();

  return (
    <EditBase redirect={false} resource="students" id={student.id}>
      <SimpleForm
        sx={{ padding: "1em 0" }}
        shouldUnregister
        warnWhenUnsavedChanges
        toolbar={
          <Toolbar style={{ padding: 0 }}>
            <SaveButton />
          </Toolbar>
        }
      >
        <NumberInput source="applicationFeePercentage" required />
        <h4 style={{ marginTop: 0 }}>Feature Toggles</h4>
        <FeatureTogglesInput />
      </SimpleForm>
    </EditBase>
  );
}
