export const RobotIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 1.5c.825 0 1.5.675 1.5 1.5 0 .555-.3 1.043-.75 1.298v.952h.75a5.246 5.246 0 0 1 5.25 5.25h.75c.413 0 .75.338.75.75v2.25c0 .412-.337.75-.75.75h-.75V15c0 .832-.668 1.5-1.5 1.5H3.75a1.5 1.5 0 0 1-1.5-1.5v-.75H1.5a.752.752 0 0 1-.75-.75v-2.25c0-.412.337-.75.75-.75h.75A5.246 5.246 0 0 1 7.5 5.25h.75v-.952A1.488 1.488 0 0 1 7.5 3c0-.825.675-1.5 1.5-1.5zm1.5 5.25h-3c-2.07 0-3.75 1.68-3.75 3.75V12h-1.5v.75h1.5V15h10.5v-2.25h1.5V12h-1.5v-1.5c0-2.07-1.68-3.75-3.75-3.75zm0 6v.75a.75.75 0 0 1-.75.75h-1.5a.75.75 0 0 1-.75-.75v-.75h3zm-.25-4.5a2.5 2.5 0 0 1 2.5 2.5V12h-7.5v-1.25a2.5 2.5 0 0 1 2.5-2.5h2.5zm-3.5 1.5a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5zm4.5 0a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5z"
        fill="#74A089"
        fillRule="nonzero"
      />
    </svg>
  );
};
