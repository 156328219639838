import { RecordContextProvider, useRecordContext } from "react-admin";
import { DrivingLicenseAuthority } from "../../providers/drivingLicenseAuthoritiesProvider";
import { Column } from "../../misc/Column";
import LinkIcon from "@mui/icons-material/Link";
import { Box } from "@mui/material";
import { autovioColors } from "../../misc/backofficeTheme";
import IconInACircle from "../../misc/IconInACircle";
import { EditDrivingLicenseAuthorityFormDialog } from "./EditDrivingLicenseAuthorityFormDialog";
import { ListWithDividers } from "../../misc/ListWithDividers";
import { EditableListItem } from "../../misc/EditableListItem";

export function DrivingLicenseAuthorityForms() {
  const authority = useRecordContext<DrivingLicenseAuthority>();

  return (
    <ListWithDividers items={authority.forms} emptyText="Keine Formulare eingepflegt.">
      {(form) => (
        <RecordContextProvider value={form}>
          <EditableListItem dialog={EditDrivingLicenseAuthorityFormDialog}>
            <IconInACircle style={{ size: 20 }} icon={<LinkIcon sx={{ width: 12 }} />} />
            <Column>
              <Box sx={{ fontSize: "14px" }}>{form.name}</Box>
              <Box sx={{ fontSize: "12px", color: autovioColors.grey }}>{_renderFormSubtitle(form)}</Box>
            </Column>
          </EditableListItem>
        </RecordContextProvider>
      )}
    </ListWithDividers>
  );
}

function _renderFormSubtitle(form: ArrayItem<DrivingLicenseAuthority["forms"]>): string {
  const { drivingLicenseClasses } = form;
  if (drivingLicenseClasses.length === 0) {
    return "alle Klassen";
  } else if (drivingLicenseClasses.length === 1) {
    return `für Klasse ${drivingLicenseClasses[0]}`;
  } else {
    return `für Klassen ${drivingLicenseClasses.slice(0, -1).join(", ")} & ${drivingLicenseClasses.at(-1)}`;
  }
}
