import memoize from "lodash/memoize";
import { isValidPhoneNumber } from "./isValidPhoneNumber";

export const phoneNumber = memoize(
  (message: string = "Bitte gib eine gültige Telefonnummer ein.") =>
    (value: string): undefined | string => {
      if (value) {
        if (!isValidPhoneNumber(value)) {
          return message;
        }
        const normalized = value.replaceAll(/[^\d+]/g, "");
        if (!(normalized.startsWith("+") || normalized.startsWith("0"))) {
          return "Bitte gib die Telefonnummer mit Vorwahl ein.";
        }
      }
    },
);

export const url = memoize(
  (message: string = "Bitte gib eine gültige URL ein.") =>
    (value: string): undefined | string => {
      if (value) {
        try {
          new URL(value);
        } catch {
          return message;
        }
      }
    },
);
