import type {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { Payment, PaymentsProvider, paymentsProvider } from "./paymentsProvider";
import { applyFilter, applyPagination } from "../backoffice.utils";
import cloneDeep from "lodash/cloneDeep";
import { BalanceProvider, balanceProvider, BalanceTransaction } from "./balanceProvider";
import { DateTime } from "luxon";
import { reportError } from "../backoffice.utils";

type PaymentWithSaldo = Payment & {
  readonly usedCredit: number; // <-- in Cents
  readonly saldo: number; // <-- in Cents
};

type CreditNoteWithSaldo = {
  readonly id: string;
  readonly createdAt: DateTime;
  readonly description: string | null;
  readonly amount: number; // <-- in Cents
  readonly usedCredit: 0;
  readonly amountReceived: 0;
  readonly saldo: number; // <-- in Cents
};

export type SaldoTransaction = PaymentWithSaldo | CreditNoteWithSaldo;

type _PaymentOrCreditNote =
  | {
      createdAt: DateTime;
      payment: Payment;
      usedCredit: number;
    }
  | {
      createdAt: DateTime;
      creditNote: BalanceTransaction;
      payment?: never;
    };

export class SaldoProvider {
  constructor(
    private paymentsProvider: PaymentsProvider,
    private balanceProvider: BalanceProvider,
  ) {}

  async create(_: string, __: CreateParams): Promise<CreateResult<SaldoTransaction>> {
    throw new Error("not implemented");
  }

  async delete(_: string, __: DeleteParams<SaldoTransaction>): Promise<DeleteResult<SaldoTransaction>> {
    throw new Error("not implemented");
  }

  async deleteMany(_: string, __: DeleteManyParams<SaldoTransaction>): Promise<DeleteManyResult<SaldoTransaction>> {
    throw new Error("not implemented");
  }

  async getList(_: string, __: GetListParams): Promise<GetListResult<SaldoTransaction>> {
    throw new Error("not implemented");
  }

  async getMany(_: string, __: GetManyParams): Promise<GetManyResult<SaldoTransaction>> {
    throw new Error("not implemented");
  }

  async getManyReference(
    _: string,
    { target, id, filter, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<SaldoTransaction>> {
    if (sort.field !== "createdAt" || sort.order !== "ASC") {
      throw new Error(`Unexpected sort: ${JSON.stringify(sort)} -- expected: {field: "createdAt", order: "ASC"}`);
    }
    const { data: payments } = await this.paymentsProvider.getManyReference("payments", {
      target,
      id,
      filter: undefined,
      sort: { field: "createdAt", order: "ASC" },
      pagination: { page: 1, perPage: 999999 },
    });
    const balance = (
      await this.balanceProvider.getManyReference("balances", {
        target,
        id,
        filter: undefined,
        sort: { field: "createdAt", order: "ASC" },
        pagination: { page: 1, perPage: 1 },
      })
    ).data[0];
    const creditNotes: Array<BalanceTransaction> = [];
    const usedCredits: { [invoiceId: string]: number } = {};
    for (const balanceTransaction of balance.transactions) {
      const { amount, type, invoiceId } = balanceTransaction;
      if (type === "applied_to_invoice" && invoiceId) {
        usedCredits[invoiceId] = (usedCredits[invoiceId] ?? 0) - amount;
      } else {
        creditNotes.push(balanceTransaction);
      }
    }
    const transactions = (
      payments.map((payment) => {
        const { invoiceId } = payment;
        const usedCredit = invoiceId ? (usedCredits[invoiceId] ?? 0) : 0;
        return {
          createdAt: DateTime.fromISO(payment.createdAt),
          payment,
          usedCredit,
        };
      }) as Array<_PaymentOrCreditNote>
    ).concat(
      creditNotes.map((creditNote) => ({
        createdAt: DateTime.fromISO(creditNote.createdAt),
        creditNote,
      })),
    );
    transactions.sort((a, b) => a.createdAt.toMillis() - b.createdAt.toMillis());
    const data: Array<SaldoTransaction> = [];
    const retries = new Map<string, string>();
    let saldo = 0;
    for (const t of transactions) {
      const { createdAt, payment } = t;
      if (payment) {
        const { usedCredit } = t;
        if (usedCredit) {
          saldo -= usedCredit;
        }
        if (isRetry(payment)) {
          const retryOf = payment.stripePaymentIntent?.metadata?.retryOf;
          if (retryOf) {
            retries.set(retryOf, payment.id);
          }
        } else {
          saldo -= payment.amount;
        }
        // TODO: this code is duplicated in functions -- extract it into a shared package
        // APP-3201: We are optimistic here and treat payments with the status "processing" like successful payments ...
        if (payment.status === "processing" || payment.status === "succeeded" || payment.status === "refunded") {
          saldo += payment.amount;
        }
        const amount = payment.amount + usedCredit;
        data.push(Object.assign(cloneDeep(payment), { createdAt, amount, usedCredit, saldo }));
      } else {
        const { id, amount, description } = t.creditNote;
        saldo += amount;
        data.push({
          id,
          createdAt,
          description: description ?? "???",
          amount,
          usedCredit: 0,
          amountReceived: 0,
          saldo,
        });
      }
    }
    for (const [retriedId, retryId] of retries.entries()) {
      const saldoTransaction = data.find((it) => it.id === retriedId);
      if (!saldoTransaction) {
        reportError(`Could not find Saldo transaction ${retriedId} <-- retried by ${retryId}`);
      } else {
        (saldoTransaction as Payment).status = "retried";
      }
    }
    return applyPagination(applyFilter(data, filter), pagination);
  }

  async getOne(_: string, __: GetOneParams): Promise<GetOneResult<SaldoTransaction>> {
    throw new Error("not implemented");
  }

  async update(_: string, __: UpdateParams): Promise<UpdateResult<SaldoTransaction>> {
    throw new Error("not implemented");
  }

  async updateMany(_: string, __: UpdateManyParams): Promise<UpdateManyResult<SaldoTransaction>> {
    throw new Error("not implemented");
  }
}

function isRetry(payment: Payment) {
  const { type, description, stripePaymentIntent } = payment;
  return type === "internal" || description.endsWith(" (Retry)") || stripePaymentIntent?.metadata?.retryOf;
}

export const saldoProvider = new SaldoProvider(paymentsProvider, balanceProvider);
