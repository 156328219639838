import { Children, cloneElement, ReactElement, useEffect, useRef, useState } from "react";
import { MenuTabItemElement } from "./MenuTabItem";
import { Menu as MuiMenu, PopoverOrigin, Tab as MuiTab } from "@mui/material";

export interface MenuTabProps {
  label: string | ReactElement;
  children: Array<MenuTabItemElement | false>;
  value?: string | number;
  path?: never;
}

const menuAnchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "center",
};

const menuTransformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "center",
};

type _NativeMouseMoveListener = (event: { clientX: number; clientY: number }) => void;
type _NativeMouseMoveListenerRef = React.MutableRefObject<undefined | _NativeMouseMoveListener>;

function _addNativeMouseMoveListener(ref: _NativeMouseMoveListenerRef, listener: _NativeMouseMoveListener) {
  if (ref.current) {
    window.removeEventListener("mousemove", ref.current);
  }
  ref.current = listener;
  window.addEventListener("mousemove", listener);
}

function _removeNativeMouseMoveListener(ref: _NativeMouseMoveListenerRef) {
  if (ref.current) {
    window.removeEventListener("mousemove", ref.current);
    ref.current = undefined;
  }
}

function _clearTimeout(timeoutIdRef: React.MutableRefObject<undefined | ReturnType<typeof setTimeout>>) {
  clearTimeout(timeoutIdRef.current);
  timeoutIdRef.current = undefined;
}

export function MenuTab({ children: menuTabItems, ...rest }: MenuTabProps) {
  const windowMouseMoveListenerRef = useRef<undefined | _NativeMouseMoveListener>();
  useEffect(() => {
    _removeNativeMouseMoveListener(windowMouseMoveListenerRef);
  }, []);
  const cursorOverTabRef = useRef(false);
  const cursorOverMenuRef = useRef(false);
  const isMenuOpenRef = useRef(false);
  const [anchorEl, setAnchorEl_] = useState<null | HTMLElement>(null);
  const setAnchorEl = (anchorEl: null | HTMLElement) => {
    setAnchorEl_(anchorEl);
    isMenuOpenRef.current = !!anchorEl;
  };
  const isMenuOpen = isMenuOpenRef.current;
  const openTimeoutIdRef = useRef<undefined | ReturnType<typeof setTimeout>>();
  const closeTimeoutIdRef = useRef<undefined | ReturnType<typeof setTimeout>>();
  const openMenu = ({ currentTarget }: { currentTarget: HTMLElement }) => {
    if (!currentTarget) {
      console.error(`openMenu called with ${currentTarget}`);
    }
    _clearTimeout(openTimeoutIdRef);
    setAnchorEl(currentTarget);
  };
  const closeMenu = () => {
    _clearTimeout(closeTimeoutIdRef);
    setAnchorEl(null);
  };

  return (
    <>
      <MuiTab
        onMouseEnter={(event) => {
          if (cursorOverTabRef.current) {
            // We did not simulate a onMouseLeave event yet -- do nothing ...
            return;
          }
          cursorOverTabRef.current = true;
          // [UX] We do not immediately open the menu when the mouse cursor enters the <MuiTab> element,
          // instead we wait a little bit to avoid accidental opening of the menu when the mouse cursor
          // just quickly passes over the <MuiTab> element ...
          const { currentTarget } = event;
          if (!openTimeoutIdRef.current) {
            openTimeoutIdRef.current = setTimeout(() => openMenu({ currentTarget }), 200);
          }
          // When the menu opens, the onMouseLeave handler of the <MuiTab> would be called immediately
          // even if the mouse cursor is still hovering over the <MuiTab> element (because the <MuiMenu> backdrop
          // spans the entire browser window and has a high z-index). We therefore cannot use the onMouseLeave
          // handler to close the menu again.
          // Instead, we listen to mousemove events on the window and simulate onMouseLeave when the
          // mouse cursor leaves the rectangle of the <MuiTab> element ...
          const tabRect = currentTarget.getBoundingClientRect();
          const listener = ({ clientX: x, clientY: y }: { clientX: number; clientY: number }) => {
            if (!cursorOverTabRef.current) {
              // There was no onMouseEnter event yet -- do nothing ...
              return;
            }
            if (x > tabRect.left - 16 && x < tabRect.right + 16 && y > tabRect.top - 16 && y <= tabRect.bottom + 16) {
              // The mouse cursor is still hovering over the <MuiTab> element -- do nothing ...
              return;
            }
            // Simulate onMouseLeave ...
            cursorOverTabRef.current = false;
            if (isMenuOpenRef.current) {
              if (!cursorOverMenuRef.current && !closeTimeoutIdRef.current) {
                closeTimeoutIdRef.current = setTimeout(closeMenu, 200);
              }
            } else {
              _clearTimeout(openTimeoutIdRef);
            }
          };
          _addNativeMouseMoveListener(windowMouseMoveListenerRef, listener);
        }}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          return false;
        }}
        className="show-tab"
        sx={{ opacity: 1, ...(isMenuOpen ? { backgroundColor: "rgba(0, 0, 0, 0.04)" } : {}) }}
        {...rest}
      />
      <MuiMenu
        disableScrollLock
        anchorEl={anchorEl}
        anchorOrigin={menuAnchorOrigin}
        transformOrigin={menuTransformOrigin}
        open={isMenuOpen}
        onClose={closeMenu}
        BackdropProps={{
          onClick: (event) => {
            event.preventDefault();
            event.stopPropagation();
            return false;
          },
        }}
        MenuListProps={{
          role: "listbox",
          onMouseEnter: () => {
            cursorOverMenuRef.current = true;
            if (closeTimeoutIdRef.current) {
              _clearTimeout(closeTimeoutIdRef);
            }
          },
          onMouseLeave: () => {
            cursorOverMenuRef.current = false;
            if (!cursorOverTabRef.current && !closeTimeoutIdRef.current) {
              closeTimeoutIdRef.current = setTimeout(closeMenu, 200);
            }
          },
        }}
      >
        {Children.map(menuTabItems.filter(isMenuTabItemElement), (menuTabItem) =>
          cloneElement(menuTabItem, { context: "menuitem", closeMenu: closeMenu }),
        )}
      </MuiMenu>
    </>
  );
}

function isMenuTabItemElement(x: MenuTabItemElement | false): x is MenuTabItemElement {
  return !!x;
}

export type MenuTabElement = React.ReactElement<MenuTabProps, typeof MenuTab>;
