import { DrivingSchoolIcon } from "../icons/DrivingSchoolIcon";

export function DrivingSchoolLogo({
  drivingSchool,
  size = 24,
}: {
  drivingSchool?: { logoUrl?: string };
  size?: 24 | 32 | 44;
}) {
  const { logoUrl } = drivingSchool ?? {};
  if (!logoUrl) {
    return <DrivingSchoolIcon size={size} />;
  }
  return <img src={logoUrl} style={{ width: size, height: size }} alt="" />;
}
