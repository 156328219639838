import { CommonInputProps, ReferenceInput, SelectInput } from "react-admin";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";
import { HiddenInput } from "./HiddenInput";

type DrivingSchoolInputProps = CommonInputProps & {
  label?: string;
};

export function DrivingSchoolInput(props: DrivingSchoolInputProps) {
  const { label, validate, isRequired, ...rest } = props;

  if (restrictAccessToDrivingSchoolIds?.length === 1) {
    return <HiddenInput source={props.source} value={restrictAccessToDrivingSchoolIds[0]} />;
  }

  return (
    <ReferenceInput {...rest} reference="drivingSchools" page={1} perPage={999} sort={{ field: "name", order: "ASC" }}>
      <SelectInput
        label={label}
        sx={{ minWidth: "337px" }}
        optionText="name"
        validate={validate}
        isRequired={isRequired}
      />
    </ReferenceInput>
  );
}
