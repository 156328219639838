import { Student } from "../../providers/studentsProvider";
import { Avatar, IconButton, Paper, Typography } from "@mui/material";
import { LinearProgress, useGetManyReference, useGetOne } from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { DateTime, Interval } from "luxon";
import { DrivingLesson } from "../../model/autovioCalendarEvents";
import { Row } from "../../misc/Row";
import { useAutovioContext } from "../../hooks/useAutovioContext";
import { autovioColors } from "../../misc/backofficeTheme";
import { Column } from "../../misc/Column";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { formatTime } from "../../utils/calendar";

export function _StudentSuggestions() {
  const [{ drivingSchoolId }] = useAutovioContext();
  const dateTime = useWatch({ name: "dateTime" });
  const instructorId = useWatch({ name: "instructorId" });
  if (!drivingSchoolId || !dateTime) {
    return null;
  }
  return <__StudentSuggestions drivingSchoolId={drivingSchoolId} dateTime={dateTime} instructorId={instructorId} />;
}

function __StudentSuggestions({
  drivingSchoolId,
  dateTime,
  instructorId,
}: {
  drivingSchoolId: string;
  dateTime: DateTime;
  instructorId?: string;
}) {
  const drivingLessons: Array<DrivingLesson> = [];
  let isLoading = false;
  for (const daysAgo of [7, 14, 21, 28]) {
    // A general rule is: Hooks should not be called in if statements or loops.
    // But this is one exception to this rule, which is safe, because the number
    // of iterations is always the same.
    const { data } = useGetManyReference("drivingLessons", {
      target: "drivingSchoolId",
      id: drivingSchoolId,
      filter: {
        ...(instructorId ? { instructorId } : {}),
        dateRange: Interval.fromDateTimes(
          dateTime.minus({ days: daysAgo, minutes: 90 }),
          dateTime.minus({ days: daysAgo }).plus({ minute: 90 }),
        ),
      },
      pagination: { page: 1, perPage: 999 },
      sort: { field: "start", order: "ASC" },
    });
    if (data) {
      drivingLessons.push(...data);
    } else {
      isLoading = true;
    }
  }
  const drivingLessonsByStudentUid: { [studentUid: string]: Array<DrivingLesson> } = {};
  for (const drivingLesson of drivingLessons) {
    const a = drivingLessonsByStudentUid[drivingLesson.student.uid];
    if (a) {
      a.push(drivingLesson);
    } else {
      drivingLessonsByStudentUid[drivingLesson.student.uid] = [drivingLesson];
    }
  }
  return (
    <div>
      <h4 style={{ color: autovioColors.grey }}>Vorschläge</h4>
      <div>
        {isLoading && <LinearProgress />}
        {!isLoading && drivingLessons.length === 0 && (
          <>
            {"Keine Fahrstunden in den letzten 4 Wochen"}
            <br />
            {`um ${formatTime(dateTime)} Uhr ±90 Minuten gefunden.`}
          </>
        )}
        {Object.entries(drivingLessonsByStudentUid).map(([studentUid, drivingLessons]) => (
          <_StudentSuggestion
            key={studentUid}
            dateTime={dateTime}
            studentUid={studentUid}
            drivingLessons={drivingLessons}
          />
        ))}
      </div>
    </div>
  );
}

function _StudentSuggestion({
  dateTime,
  studentUid,
  drivingLessons,
}: {
  dateTime: DateTime;
  studentUid: string;
  drivingLessons: Array<DrivingLesson>;
}) {
  const { data: student } = useGetOne<Student>("students", { id: studentUid });
  const { setValue } = useFormContext();
  if (!student || student.status !== "active") {
    return null;
  }
  const drivingLesson = drivingLessons[0];
  const weeksAgo = Math.floor(dateTime.diff(drivingLesson.start, "weeks").weeks);
  return (
    <Paper
      sx={{
        border: "0.8px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "20px",
        padding: "15px",
        cursor: "pointer",
        ":hover": {
          backgroundColor: autovioColors.greyUltraLight,
        },
      }}
      onClick={() => {
        setValue("student", student);
        setValue("drivingLicenseClass", student.activeOrMostRecentBookedTraining.drivingLicenseClass);
      }}
    >
      <Row alignItems="center" spacing={1}>
        <Avatar src={student.avatarUrl} />
        <Column sx={{ flex: 1 }}>
          <Typography variant="body2" fontWeight="bold">
            {student?.name}
          </Typography>
          <Typography variant="body2">
            {`Fahrstunde vor ${weeksAgo} ${weeksAgo === 1 ? "Woche" : "Wochen"} um ${formatTime(
              drivingLesson.start,
            )} Uhr`}
          </Typography>
        </Column>
        <IconButton
          onClick={(event) => {
            window.open(`/students/${studentUid}`, "_blank");
            event.stopPropagation();
            return false;
          }}
        >
          <OpenInNewIcon htmlColor={autovioColors.green} />
        </IconButton>
      </Row>
    </Paper>
  );
}
