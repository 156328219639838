import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
  query,
  Query,
  QueryDocumentSnapshot,
  setDoc,
  where,
} from "firebase/firestore";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";
import { PostalAddress } from "../model/PostalAddress";
import { FirestoreProvider } from "./firestoreProvider";
import { RaRecord, UpdateParams, UpdateResult } from "react-admin";
import { FeatureTogglesForInstructors } from "../model/FeatureToggles";
import { firestore } from "../firebase";
import { baseUrl as serverBaseUrl } from "../api/server.api";

export interface Instructor {
  id: string;
  autovioUserId: string;
  firstName: string;
  lastName: string;
  name: string;
  avatarUrl?: string;
  avatarOverrideUrl?: string;
  hubspotContactId?: string;
  applicationFeePercentage?: number;
  postalAddress?: Partial<PostalAddress>;
  drivingSchoolId: string;
  isDrivingSchoolManager: boolean;
  instructing: {
    drivingLicenseClasses: Array<string>;
  };
  studentIds: Array<string>;
  featureToggles: FeatureTogglesForInstructors;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  roles: Array<"instructor" | "drivingSchoolManager" | string>;
  grants: {
    [key: string]: boolean;
  };
}

export function isInstructor(record: RaRecord): record is Instructor {
  return Array.isArray(record.instructing?.drivingLicenseClasses);
}

interface InstructorDocumentData {
  autovioUserId: string;
  hubspotContactId?: string;
  applicationFeePercentage?: number;
  publicProfile: {
    firstName?: string;
    lastName?: string;
    avatarOverrideUrl?: string;
  };
  postalAddress?: Partial<PostalAddress>;
  drivingSchoolUid: string;
  instructing?: {
    drivingLicenseClasses?: Array<string>;
  };
  myStudents: Array<string>;
  roles?: Array<string>;
  featureToggles?: FeatureTogglesForInstructors;
  grants?: Array<string>;
}
class InstructorProvider extends FirestoreProvider<Instructor> {
  private instructorsByAutovioUserId: Map<string, Instructor> = new Map();
  querySingle(id: string): [DocumentReference | Query, (record: Instructor) => void] {
    return [
      doc(firestore, `/users/${id}`),
      (instructor) => {
        if (
          restrictAccessToDrivingSchoolIds &&
          !restrictAccessToDrivingSchoolIds.includes(instructor.drivingSchoolId)
        ) {
          throw new Error(`Access to instructor ${id} denied`);
        }
      },
    ];
  }

  queryAll(): Query {
    return query(
      collection(firestore, `/${this.collection}`),
      restrictAccessToDrivingSchoolIds?.length === 1
        ? where("drivingSchoolUid", "==", restrictAccessToDrivingSchoolIds[0])
        : restrictAccessToDrivingSchoolIds
          ? where("drivingSchoolUid", "in", restrictAccessToDrivingSchoolIds)
          : where("drivingSchoolUid", "!=", ""),
    );
  }

  async fromFirestore(snapshot: QueryDocumentSnapshot<InstructorDocumentData>): Promise<Instructor> {
    const data = snapshot.data();
    const firstName = data.publicProfile.firstName ?? "???";
    const lastName = data.publicProfile.lastName ?? "???";
    const grants: { [key: string]: boolean } = {
      // defaults ...
      changePrice: (data.roles ?? []).includes("drivingSchoolManager"),
    };
    for (const grant of data.grants ?? []) {
      if (grant.startsWith("!")) {
        grants[grant.substring(1)] = false;
      } else {
        grants[grant] = true;
      }
    }
    const { id } = snapshot;
    const instructor: Instructor = {
      id,
      autovioUserId: data.autovioUserId,
      hubspotContactId: data.hubspotContactId,
      firstName,
      lastName,
      name: `${firstName} ${lastName}`,
      avatarUrl: `${serverBaseUrl}/user/${id}/avatar`,
      avatarOverrideUrl: data.publicProfile.avatarOverrideUrl,
      drivingSchoolId: data.drivingSchoolUid,
      instructing: {
        drivingLicenseClasses: data.instructing?.drivingLicenseClasses ?? [],
      },
      isDrivingSchoolManager: data.roles?.includes("drivingSchoolManager") ?? false,
      studentIds: data.myStudents ?? [],
      postalAddress: data.postalAddress,
      featureToggles: {
        ..._defaultFeatureToggles,
        ...(data.featureToggles ?? {}),
      },
      grants,
      roles: data.roles ?? [],
    };
    this.instructorsByAutovioUserId.set(instructor.autovioUserId, instructor);
    return instructor;
  }

  getOneFromCache(id: string): Instructor | undefined {
    return this.instructorsByAutovioUserId.get(id) ?? super.getOneFromCache(id);
  }

  async getByDrivingSchoolId(drivingSchoolId: string): Promise<Array<Instructor>> {
    const { records } = await this.snapshot();
    return records.filter((it) => it.drivingSchoolId === drivingSchoolId);
  }

  async update(resource: string, update: UpdateParams<Instructor>): Promise<UpdateResult<Instructor>> {
    const updatePayload = this.toFirestore(update.data);
    if (!Object.keys(updatePayload).length) {
      throw new Error("InstructorProvider.toFirestore(...) returned empty object");
    }
    console.info(`Updating users/${update.id} ...`, updatePayload);
    await setDoc(doc(firestore, `users/${update.id}`), updatePayload, { merge: true });
    return super.getOne(resource, { id: update.id });
  }

  toFirestore(updateData: Partial<Instructor>): DocumentData {
    let grants: undefined | Array<string>;
    if (updateData.grants) {
      grants = Object.entries(updateData.grants).reduce((acc, [key, value]) => {
        if (value) {
          acc.push(key);
        }
        return acc;
      }, [] as Array<string>);
    }
    return {
      ...(Object.keys(updateData.featureToggles ?? {}).length > 0 ? { featureToggles: updateData.featureToggles } : {}),
      ...(grants ? { grants } : {}),
    };
  }
}

const _defaultFeatureToggles: FeatureTogglesForInstructors = {
  lab: false,
};

export const instructorsProvider = new InstructorProvider("Instructor", "users");
