import { FieldProps, ReferenceOneField, TextField, useRecordContext } from "react-admin";
import { AutovioCalendarEvent } from "../model/autovioCalendarEvents";
import get from "lodash/get";
import { SxProps } from "@mui/system";
import { type ReactNode } from "react";

export function InstructorField({ source, sx }: FieldProps & { source: string; sx?: SxProps }): ReactNode {
  const record = useRecordContext<AutovioCalendarEvent>();

  if (!record || !get(record, source)) {
    return null;
  }

  return (
    <ReferenceOneField reference="instructors" target="id" source={source}>
      <TextField source="name" sx={sx} />
    </ReferenceOneField>
  );
}
