import { FieldProps, sanitizeFieldRestProps, useRecordContext } from "react-admin";
import get from "lodash/get";
import { Typography, TypographyProps } from "@mui/material";
import * as React from "react";
import { t } from "../model/types";
import ErrorIcon from "@mui/icons-material/Error";
import { DateTime } from "luxon";

interface DateFieldProps extends FieldProps {
  source: string;
  /** default: true */
  showDate?: boolean | "with diff";
  /** default: false */
  showTime?: boolean;
  /** default: "de" */
  locale?: string;
  /** default: "body2" */
  variant?: "body1" | "body2";
}

export function DateField(props: DateFieldProps & TypographyProps) {
  const {
    source,
    className,
    emptyText,
    showDate = true,
    showTime = false,
    locale = "de",
    variant = "body2",
    ...rest
  } = props;
  const record = useRecordContext(props);
  if (!record) {
    return null;
  }
  const value = get(record, source);
  if (!value) {
    return emptyText ? (
      <Typography component="span" variant="body2" className={className} {...sanitizeFieldRestProps(rest)}>
        {emptyText}
      </Typography>
    ) : null;
  }
  const parseResult = t.dateTime().safeParse(value);
  if (!parseResult.success) {
    return <ErrorIcon role="presentation" color="error" fontSize="small" />;
  }
  const dateTime = parseResult.data;
  let displayed: string;
  let title: string | undefined;
  if (showTime && showDate) {
    displayed = dateTime.toLocaleString(DateTime.DATETIME_SHORT, { locale });
    title = dateTime.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS, { locale });
  } else if (showDate) {
    displayed = dateTime.toLocaleString(undefined, { locale });
    if (showDate === "with diff") {
      displayed += ` (${formatDateDiff(DateTime.now(), dateTime, { locale })})`;
    }
  } else if (showTime) {
    displayed = dateTime.toLocaleString(DateTime.TIME_SIMPLE, { locale });
    title = dateTime.toLocaleString(DateTime.TIME_WITH_SECONDS, { locale });
  } else {
    throw new Error("<DateField> cannot have showTime and showDate false at the same time");
  }

  return (
    <Typography
      component="span"
      variant={variant}
      className={className}
      title={title}
      {...sanitizeFieldRestProps(rest)}
    >
      {displayed}
    </Typography>
  );
}

export function formatDateDiff(d1: DateTime, d2: DateTime, { locale }: { locale: string } = { locale: "de" }) {
  d1 = DateTime.fromObject({ year: d1.year, month: d1.month, day: d1.day }, { zone: d1.zone });
  d2 = DateTime.fromObject({ year: d2.year, month: d2.month, day: d2.day }, { zone: d2.zone });
  const dateDiff = Math.round(d1.diff(d2, "days").days);
  if (dateDiff < -1) {
    return locale.startsWith("de") ? `in ${-dateDiff} Tagen` : `in ${-dateDiff} days`;
  } else if (dateDiff === -1) {
    return locale.startsWith("de") ? "morgen" : "tomorrow";
  } else if (dateDiff === 0) {
    return locale.startsWith("de") ? "heute" : "today";
  } else if (dateDiff === 1) {
    return locale.startsWith("de") ? "gestern" : "yesterday";
  } else {
    return locale.startsWith("de") ? `vor ${dateDiff} Tagen` : `${dateDiff} days ago`;
  }
}
