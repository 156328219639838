import { required, SimpleForm, TextInput } from "react-admin";
import { RecoverToolbar } from "../../misc/RecoverToolbar";
import { EntitledDrivingSchoolsInput } from "../../inputs/EntitledDrivingSchoolsInput";
import { EntitledUsersInput } from "../../inputs/EntitledUsersInput";
import { HiddenInput } from "../../inputs/HiddenInput";

export function TrailerForm({ mode }: { mode: "create" | "edit" }) {
  return (
    <SimpleForm warnWhenUnsavedChanges toolbar={<RecoverToolbar />}>
      <HiddenInput source="type" value="trailer" />
      <TextInput label="Name" source="name" fullWidth autoFocus={mode === "create"} validate={required()} />
      <TextInput label="Notizen" source="description" fullWidth />
      <EntitledDrivingSchoolsInput />
      <EntitledUsersInput />
      <TextInput label="Kennzeichen" source="trailer.plateNumber" validate={required()} />
    </SimpleForm>
  );
}
