import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { DialogProps } from "../../misc/DialogProps";
import { DialogCloseButton } from "../../misc/DialogCloseButton";
import { DrivingLicenseAuthorityFormForm } from "./DrivingLicenseAuthorityFormForm";
import { EditBase, useRecordContext } from "react-admin";
import { useQueryClient } from "react-query";

export function EditDrivingLicenseAuthorityFormDialog(dialogProps: DialogProps) {
  const { id } = useRecordContext();
  const queryClient = useQueryClient();

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>Formular bearbeiten</DialogTitle>
      <DialogCloseButton onClose={dialogProps.onClose} />
      <DialogContent>
        <EditBase
          redirect={false}
          resource="drivingLicenseAuthorityForms"
          id={id}
          mutationMode="pessimistic"
          mutationOptions={{
            onSuccess: async () => {
              await queryClient.invalidateQueries(["drivingLicenseAuthorities"]);
              dialogProps.onClose();
            },
          }}
        >
          <DrivingLicenseAuthorityFormForm />
        </EditBase>
      </DialogContent>
    </Dialog>
  );
}
