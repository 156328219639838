import {
  useCreateContext,
  useGetRecordRepresentation,
  useGetResourceLabel,
  useRecordContext,
  useRedirect,
  useResourceContext,
  useResourceDefinition,
} from "react-admin";
import { PageTitle } from "./PageTitle";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { Button } from "@mui/material";
import { linkButtonStyle } from "./backofficeTheme";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { type ReactElement } from "react";

/**
 * If no `header` property is given, the record title is rendered inside an `<h2>` element.
 */
export function BackofficeRecordTitle({ header }: { header?: ReactElement }) {
  const resource = useResourceContext();
  const resourceDefinition = useResourceDefinition({ resource });
  const record = useRecordContext();
  const getRecordRepresentation = useGetRecordRepresentation(resource);
  const { defaultTitle: creatingRecord } = useCreateContext();
  const [{ drivingSchoolId }] = useAutovioContext();
  const getResourceLabel = useGetResourceLabel();
  const redirect = useRedirect();

  let title: any;

  if (creatingRecord) {
    title = resourceDefinition.options.createTitle;
  } else if (record) {
    title = getRecordRepresentation(record);
  }

  const pageTitle =
    title && typeof title === "string" ? (
      header ? (
        <PageTitle documentTitle={title}>{header}</PageTitle>
      ) : (
        <PageTitle>{title}</PageTitle>
      )
    ) : null;

  if (drivingSchoolId) {
    return pageTitle;
  }

  // If we are not on a driving school page (and therefore the sidebar is not visible) we show a back button ...
  return (
    <>
      <Button
        sx={{
          ...linkButtonStyle,
          marginLeft: "-10px",
          padding: "10px",
          fontSize: "14px",
          "& .MuiButton-startIcon": { marginRight: "0px" },
        }}
        startIcon={<ChevronLeftIcon sx={{ width: "20px", height: "20px" }} />}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          redirect("list", resource);
        }}
        data-testid="back-button"
      >
        {getResourceLabel(resource)}
      </Button>
      {pageTitle}
    </>
  );
}
