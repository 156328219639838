import { TextInput } from "react-admin";
import { useWatch } from "react-hook-form";
import { Box } from "@mui/material";
import { phoneNumber } from "../utils/validators";

type PhoneNumberInputProps = Parameters<typeof TextInput>[0] & { source: string };

export function PhoneNumberInput(props: PhoneNumberInputProps) {
  const inputValue = useWatch({ name: props.source });
  const validatePhoneNumber = phoneNumber();
  const isValidPhoneNumber = inputValue && !validatePhoneNumber(inputValue);
  const { fullWidth } = props;

  return (
    <Box style={{ position: "relative", ...(fullWidth ? { width: "100%" } : {}) }}>
      <TextInput {...props} type="tel" validate={props.validate ?? validatePhoneNumber} />
      {isValidPhoneNumber && (
        <div style={{ position: "absolute", top: "-6px", right: 0 }}>
          <a className="input-action-link" href={`tel:${inputValue}`} target="_blank" tabIndex={-1}>
            Anrufen
          </a>
        </div>
      )}
    </Box>
  );
}
