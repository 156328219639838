import {
  Auth,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import { AuthProvider, UserIdentity } from "react-admin";
import { grants } from "../backoffice.access_control";
import { FirebaseError } from "firebase/app";
import { usersProvider } from "./usersProvider";

export let userLoggedOut = false;

export class FirebaseAuthProvider implements AuthProvider {
  constructor(private auth: Auth) {
    auth.onIdTokenChanged((user) => {
      userLoggedOut = !user;
    });
  }

  /** Not used. */
  login(): Promise<any> {
    // This method should not be used, because we have our own LoginPage widget,
    // which uses the other login methods defined below.
    return Promise.reject(new Error("not implemented"));
  }

  async loginWithGoogle(): Promise<void> {
    try {
      if (location.search.includes("lwr=1")) {
        await signInWithRedirect(this.auth, new GoogleAuthProvider());
      } else {
        await signInWithPopup(this.auth, new GoogleAuthProvider());
      }
    } catch (error) {
      if ((error as FirebaseError).code === "auth/popup-blocked") {
        // Fallback to redirect-based sign-in ...
        await signInWithRedirect(this.auth, new GoogleAuthProvider());
      } else {
        throw error;
      }
    }
  }

  async loginWithApple(): Promise<void> {
    const authProvider = new OAuthProvider("apple.com");
    authProvider.addScope("name");
    authProvider.addScope("email");
    await signInWithPopup(this.auth, authProvider);
  }

  async loginWithFacebook(): Promise<void> {
    await signInWithPopup(this.auth, new FacebookAuthProvider());
  }

  async loginWithEmailAndPassword(email: string, password: string): Promise<any> {
    await signInWithEmailAndPassword(this.auth, email, password);
  }

  async loginAsTestUser(email: string): Promise<any> {
    await signInWithEmailAndPassword(this.auth, email, "geheim");
  }

  async loginWithCustomToken(customToken: string): Promise<void> {
    await signInWithCustomToken(this.auth, customToken);
  }

  /** Check if a dataProvider error is an authentication error. */
  checkError({ status }: { status: number }): Promise<void> {
    if (status === 403) {
      return Promise.reject({ redirectTo: "/" });
    }
    return Promise.resolve();
  }

  /** Check credentials before moving to a new route. */
  async checkAuth(): Promise<void> {
    const { currentUser } = this.auth;
    return currentUser ? Promise.resolve() : Promise.reject(Error("missing currentUser"));
  }

  async logout(): Promise<void | false | string> {
    userLoggedOut = true;
    await this.auth.signOut();
    location.reload();
  }

  async getIdentity(): Promise<UserIdentity> {
    const { currentUser } = this.auth;
    if (!currentUser) {
      return Promise.reject(new Error("User not logged in"));
    }
    try {
      const { data: user } = await usersProvider.getOne("users", { id: currentUser.uid });
      return {
        id: currentUser.uid,
        avatar: user.avatarUrl,
        fullName: user.name,
      };
    } catch (error) {
      console.info("Failed to get user from usersProvider -- falling back to Firebase Auth data ...", error);
    }
    const { uid: id, displayName, photoURL, providerData } = currentUser;
    return {
      id,
      fullName: displayName ?? providerData.find((it) => it.displayName)?.displayName ?? undefined,
      avatar: photoURL ?? providerData.find((it) => it.photoURL)?.photoURL ?? undefined,
    };
  }

  async getPermissions(): Promise<any> {
    return grants;
  }
}
