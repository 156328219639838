import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import PlaceIcon from "@mui/icons-material/Place";
import PeopleIcon from "@mui/icons-material/People";
import Person4Icon from "@mui/icons-material/Person4";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { EventContentArg, EventInput } from "@fullcalendar/core";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { useState } from "react";

import {
  ASFCourseSession,
  AutovioCalendarEvent,
  DrivingLesson,
  isASFCourseSession,
  isDrivingLesson,
  isOtherEvent,
  isRemoteLesson,
  isTheoryExam,
  isTheoryLesson,
  OtherEvent,
  RemoteLesson,
  TheoryExam,
  TheoryLesson,
} from "../../model/autovioCalendarEvents";
import { calendarEventBackgroundColor, calendarEventTextColor, calendarEventTitle } from "../../utils/calendar";
import { InstructorDisplayName } from "../InstructorDisplayName";
import { StudentDisplayName } from "../StudentDisplayName";
import { autovioColors } from "../backofficeTheme";
import { EventDialog, useEventDialog } from "./EventDialog";
import { VehicleDisplay } from "../VehicleDisplay";
import { Recommendation, RecommendationWithPrice } from "../../api/backoffice.api";
import { useRecoilValue } from "recoil";
import { selectedStartLocationState, useIsDebug } from "./InstructorEventsCalendar";
import { LinearProgress, useGetOne, useRecordContext } from "react-admin";
import { Instructor } from "../../providers/instructorsProvider";
import { ConfirmBookingDialog } from "./ConfirmBookingDialog";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";

interface EventContentProps {
  readonly eventInfo: EventContentArg;
  readonly for: "drivingSchool" | "instructor";
}

export const EventContent = (props: EventContentProps) => {
  const event = props.eventInfo.event.extendedProps?.event as AutovioCalendarEvent | undefined;
  const recommendation = props.eventInfo.event.extendedProps?.recommendation as Recommendation | undefined;

  if (event) {
    return <AutovioCalendarEventContent eventInfo={props.eventInfo} event={event} for={props.for} />;
  }
  if (recommendation) {
    return <RecommendationContent eventInfo={props.eventInfo} recommendation={recommendation} for={props.for} />;
  }

  return <GenericEventContent eventInfo={props.eventInfo} />;
};

interface AutovioCalendarEventContentProps {
  readonly eventInfo: EventContentArg;
  readonly event: AutovioCalendarEvent;
  readonly for: "drivingSchool" | "instructor";
}

const AutovioCalendarEventContent = (props: AutovioCalendarEventContentProps) => {
  const { event } = props;
  const [isDialogOpen, setIsDialogOpen_] = useState<boolean>(() => {
    const openDialog = new URL(location.href).searchParams.get("openDialog");
    return openDialog === `${event.type}Dialog!${event.id}`;
  });
  const { canBeOpenedInDialog } = useEventDialog();
  const setIsDialogOpen = (value: boolean) => {
    // Side effect: update browser URL ...
    const url = new URL(location.href);
    if (value) {
      if (url.searchParams.get("openDialog") !== `${event.type}Dialog!${event.id}`) {
        url.searchParams.set("openDialog", `${event.type}Dialog!${event.id}`);
        browserHistory.replace(url.toString());
      }
    } else {
      url.searchParams.delete("openDialog");
      url.searchParams.delete("showHistory");
      browserHistory.replace(url.toString());
    }
    setIsDialogOpen_(value);
  };

  return (
    <EventContentWithTooltip
      eventInfo={props.eventInfo}
      title={
        <CalendarEventCardContent
          event={event}
          options={{ showDetails: true, hideHourGlass: true, hideInstructor: props.for === "instructor" }}
        />
      }
      onClick={canBeOpenedInDialog(event) ? () => setIsDialogOpen(true) : undefined}
      hideTooltip={isDialogOpen}
    >
      <CalendarEventCardContent
        event={event}
        options={{ showDetails: props.for === "drivingSchool", showStudent: props.for === "instructor" }}
      />
      <EventDialog event={props.event} isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
    </EventContentWithTooltip>
  );
};

interface GenericEventContentProps {
  readonly eventInfo: EventContentArg;
}

const GenericEventContent = (props: GenericEventContentProps) => {
  const content = <EventTitle eventOrTitle={props.eventInfo.event.title} />;

  return (
    <EventContentWithTooltip eventInfo={props.eventInfo} title={content}>
      {content}
    </EventContentWithTooltip>
  );
};

interface RecommendationContentProps {
  readonly eventInfo: EventContentArg;
  readonly recommendation: Recommendation | RecommendationWithPrice;
  readonly for: "drivingSchool" | "instructor";
}

const RecommendationContent = (props: RecommendationContentProps) => {
  const instructor = useRecordContext<Instructor>();
  const [isConfirmBookingDialogOpen, setIsConfirmBookingDialogOpen] = useState(false);
  const { data: drivingSchool } = useGetOne("drivingSchools", { id: instructor.drivingSchoolId });
  const startLocation = useRecoilValue(selectedStartLocationState);

  const content = (
    <>
      <RecommendationCardContent recommendation={props.recommendation} />
      {"price" in props.recommendation && drivingSchool && startLocation && (
        <>
          <HoverContainer onClick={() => setIsConfirmBookingDialogOpen(true)} />
          <ConfirmBookingDialog
            isOpen={isConfirmBookingDialogOpen}
            drivingSchool={drivingSchool}
            startLocation={startLocation}
            recommendation={props.recommendation}
            onClose={() => setIsConfirmBookingDialogOpen(false)}
          />
        </>
      )}
    </>
  );

  return (
    <EventContentWithTooltip
      eventInfo={props.eventInfo}
      title={content}
      onClick={() => setIsConfirmBookingDialogOpen(true)}
      hideTooltip={isConfirmBookingDialogOpen}
    >
      {content}
    </EventContentWithTooltip>
  );
};

const IconWithText = ({ icon, text }: { icon: React.ReactNode; text: string | React.ReactNode }) => {
  return (
    <Stack direction="row" gap="3px">
      {icon}
      {typeof text === "string" ? (
        <Typography fontSize="11px" noWrap>
          {text}
        </Typography>
      ) : (
        text
      )}
    </Stack>
  );
};

interface CalendarEventContentProps<T extends AutovioCalendarEvent> {
  readonly event: T;
  readonly options?: {
    readonly showDetails?: boolean;
    readonly showStudent?: boolean;
    readonly showResources?: boolean;
    readonly hideHourGlass?: boolean;
    readonly hideInstructor?: boolean;
  };
}

const CalendarEventCardContent = (props: CalendarEventContentProps<AutovioCalendarEvent>) => {
  if (isDrivingLesson(props.event)) {
    return <DrivingLessonContent {...props} event={props.event} />;
  }
  if (isTheoryLesson(props.event)) {
    return <TheoryLessonContent {...props} event={props.event} />;
  }
  if (isOtherEvent(props.event)) {
    return <OtherEventContent {...props} event={props.event} />;
  }
  if (isTheoryExam(props.event)) {
    return <TheoryExamContent {...props} event={props.event} />;
  }
  if (isRemoteLesson(props.event)) {
    return <RemoteLessonContent {...props} event={props.event} />;
  }
  if (isASFCourseSession(props.event)) {
    return <ASFCourseSessionContent {...props} event={props.event} />;
  }
  // Fallback for unknown events ...
  return <Typography>Fehler beim Anzeigen</Typography>;
};

const HourGlassIconTopRight = () => {
  return (
    <div style={{ position: "absolute", top: 0, right: 0 }}>
      <HourglassEmptyIcon fontSize="small" sx={{ opacity: 0.5 }} />
    </div>
  );
};

const EventTitle = ({ eventOrTitle }: { readonly eventOrTitle: AutovioCalendarEvent | string }) => {
  return (
    <Typography variant="body2" fontWeight="bold">
      {typeof eventOrTitle === "string" ? eventOrTitle : calendarEventTitle(eventOrTitle)}
    </Typography>
  );
};

const DrivingLessonContent = (props: CalendarEventContentProps<DrivingLesson>) => {
  const isPending = props.event?.student?.rsvp === "pending";

  return (
    <>
      <Stack sx={{ position: "relative" }}>
        {isPending && !props.options?.hideHourGlass && <HourGlassIconTopRight />}
        <EventTitle eventOrTitle={props.event} />
        {(props.options?.showDetails || props.options?.showStudent) && (
          <IconWithText
            icon={<PersonOutlineIcon fontSize="inherit" />}
            text={
              <Stack direction="row" gap="3px">
                <StudentDisplayName studentId={props.event.student.uid} fontSize="11px" noWrap />
                {props.options?.showDetails && !props.options.hideInstructor && (
                  <Stack direction="row">
                    <Typography fontSize="11px" noWrap>
                      {"("}
                    </Typography>
                    <InstructorDisplayName
                      instructorId={props.event.instructorId}
                      source="firstName"
                      fontSize="11px"
                      noWrap
                    />
                    <Typography fontSize="11px" noWrap>
                      {")"}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            }
          />
        )}
        {props.options?.showResources &&
          props.event.resources &&
          props.event.resources.map((resourceId) => (
            <VehicleDisplay vehicleId={resourceId} iconSize="small" key={resourceId} />
          ))}
        {props.options?.showDetails && props.event.drivingLessonType !== "praktischePruefung" && (
          <IconWithText
            icon={<PlaceIcon fontSize="inherit" />}
            text={`${props.event.startLocation.street}, ${props.event.startLocation.city}`}
          />
        )}
      </Stack>
    </>
  );
};

const TheoryLessonContent = (props: CalendarEventContentProps<TheoryLesson>) => {
  const eventIsInPast = props.event.end ? props.event.end.plus({ hours: 2 }) < DateTime.now() : false;
  const numRegisteredStudents = props.event.students
    ? Object.values(props.event.students).filter((it) => it.rsvp === "accepted").length
    : undefined;
  const numAttendedStudents = Object.values(props.event.students).filter((it) => it.attended).length;
  const numMaxStudents = props.event.maxStudents;
  return (
    <>
      <Stack sx={{ position: "relative" }}>
        <EventTitle eventOrTitle={`Lektion ${props.event.theoryUnit}`} />
        {props.options?.showDetails && !props.options.hideInstructor && (
          <IconWithText
            icon={<Person4Icon fontSize="inherit" />}
            text={<InstructorDisplayName instructorId={props.event.instructorId} fontSize="11px" noWrap />}
          />
        )}
        <IconWithText icon={<PlaceIcon fontSize="inherit" />} text={<Location event={props.event} />} />
        <Stack direction="row" justifyContent="space-between" gap="10px">
          {eventIsInPast ? (
            <IconWithText icon={<PeopleIcon fontSize="inherit" />} text={numAttendedStudents?.toString() ?? "0"} />
          ) : (
            <Stack direction="row">
              {(numRegisteredStudents || numMaxStudents) && (
                <IconWithText
                  icon={<PeopleIcon fontSize="inherit" />}
                  text={numRegisteredStudents?.toString() ?? "0"}
                />
              )}
              {numMaxStudents && <Typography fontSize="11px">{`/${numMaxStudents}`}</Typography>}
            </Stack>
          )}
        </Stack>
      </Stack>
    </>
  );
};

const Location = ({ event }: { readonly event: TheoryLesson | ASFCourseSession }) => {
  const { drivingSchoolId, location } = event;
  const { data: drivingSchool } = useGetOne<DrivingSchool>("drivingSchools", { id: drivingSchoolId });

  if (location.type === "OnlineMeeting") {
    return (
      <Typography fontSize="11px" noWrap>
        Online
      </Typography>
    );
  }

  if (!drivingSchool) {
    return <LinearProgress />;
  }

  for (const branch of drivingSchool.branches) {
    const branchAddress = branch.postalAddress;
    if (location.street === branchAddress.street && location.postalCode === branchAddress.postalCode) {
      return (
        <Typography fontSize="11px" noWrap>
          {branch.name}
        </Typography>
      );
    }
  }

  return <Typography fontSize="11px" noWrap>{`${location.city}, ${location.street}`}</Typography>;
};

const ASFCourseSessionContent = (props: CalendarEventContentProps<ASFCourseSession>) => {
  return (
    <>
      <Stack sx={{ position: "relative" }}>
        <EventTitle eventOrTitle={calendarEventTitle(props.event)} />
        {props.options?.showDetails && !props.options.hideInstructor && (
          <IconWithText
            icon={<Person4Icon fontSize="inherit" />}
            text={<InstructorDisplayName instructorId={props.event.instructorId} fontSize="11px" noWrap />}
          />
        )}
        <IconWithText icon={<PlaceIcon fontSize="inherit" />} text={<Location event={props.event} />} />
        <Stack direction="row" justifyContent="space-between" gap="10px">
          <IconWithText
            icon={<PeopleIcon fontSize="inherit" />}
            text={
              <Typography fontSize="11px" noWrap>
                {props.event.numBookedParticipants}
              </Typography>
            }
          />
        </Stack>
      </Stack>
    </>
  );
};

const OtherEventContent = (props: CalendarEventContentProps<OtherEvent>) => {
  const { notes } = props.event;
  return (
    <Stack>
      <EventTitle eventOrTitle={props.event} />
      {notes && (
        <Typography fontSize="11px" noWrap>
          {notes}
        </Typography>
      )}
    </Stack>
  );
};

const TheoryExamContent = (props: CalendarEventContentProps<TheoryExam>) => {
  return (
    <Stack>
      <EventTitle eventOrTitle={props.event} />
      {props.options?.showDetails && (
        <IconWithText
          icon={<PersonOutlineIcon fontSize="inherit" />}
          text={<StudentDisplayName studentId={props.event.student.uid} fontSize="11px" noWrap />}
        />
      )}
    </Stack>
  );
};

const RemoteLessonContent = (props: CalendarEventContentProps<RemoteLesson>) => {
  return (
    <Stack>
      <EventTitle eventOrTitle={props.event} />
      {(props.options?.showDetails || props.options?.showStudent) && (
        <IconWithText
          icon={<PersonOutlineIcon fontSize="inherit" />}
          text={<StudentDisplayName studentId={props.event.student.uid} fontSize="11px" noWrap />}
        />
      )}
    </Stack>
  );
};

interface RecommendationCardContentProps {
  readonly recommendation: Recommendation;
}

const RecommendationCardContent = (props: RecommendationCardContentProps) => {
  const isDebug = useIsDebug();
  return (
    <Stack>
      <EventTitle eventOrTitle={"Terminvorschlag"} />
      {props.recommendation.resourceUids.map((resourceId) => (
        <VehicleDisplay key={resourceId} vehicleId={resourceId} iconSize="small" />
      ))}
      {isDebug && props.recommendation.score !== undefined && (
        <Typography>{`Score: ${Math.round(props.recommendation.score)}`}</Typography>
      )}
      {isDebug && props.recommendation.individualScores?.coverage !== undefined && (
        <Typography>{`Coverage: ${Math.round(props.recommendation.individualScores?.coverage)}`}</Typography>
      )}
      {isDebug && props.recommendation.individualScores?.instructorSwitch !== undefined && (
        <Typography>{`Instructor Switch: ${Math.round(
          props.recommendation.individualScores?.instructorSwitch,
        )}`}</Typography>
      )}
      {isDebug && props.recommendation.individualScores?.resourceSwitch !== undefined && (
        <Typography>{`Resource Switch: ${Math.round(
          props.recommendation.individualScores?.resourceSwitch,
        )}`}</Typography>
      )}
      {isDebug && props.recommendation.removeReason && (
        <Typography>{`Reason: ${props.recommendation.removeReason}`}</Typography>
      )}
    </Stack>
  );
};

export const autovioCalendarEventToEventInput = (event: AutovioCalendarEvent): EventInput => ({
  title: calendarEventTitle(event),
  id: event.id,
  backgroundColor: calendarEventBackgroundColor(event),
  textColor: calendarEventTextColor(event),
  editable: false,
  start: event.start.toISO(),
  end: event.end.toISO(),
  extendedProps: {
    event: event,
  },
});

const HoverContainer = (props: { readonly onClick: () => void }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        height: "100%",
        cursor: "pointer",
        ":hover": { background: autovioColors.grey, opacity: 0.2 },
      }}
      onClick={props.onClick}
    />
  );
};

interface EventContentTooltipProps {
  readonly children: React.ReactNode;
  readonly eventInfo: EventContentArg;
  readonly title: React.ReactNode;
  readonly onClick?: (eventInfo: EventContentArg) => void;
  readonly hideTooltip?: boolean;
}

const EventContentWithTooltip = (props: EventContentTooltipProps) => {
  const wrappedContent = (
    <Box overflow="hidden">
      <Box sx={{ px: "5px" }}>
        {props.children}
        {props.onClick && <HoverContainer onClick={() => props.onClick?.(props.eventInfo)} />}
      </Box>
    </Box>
  );

  return (
    <>
      {props.hideTooltip || !props.eventInfo.event.title ? (
        <>{wrappedContent}</>
      ) : (
        <Tooltip
          PopperProps={{ placement: "top" }}
          disableFocusListener
          disableInteractive
          title={
            <>
              <Typography variant="caption">{props.eventInfo.timeText}</Typography>
              {props.title}
            </>
          }
        >
          <Box sx={{ width: "100%", height: "100%" }}>{wrappedContent}</Box>
        </Tooltip>
      )}
    </>
  );
};
