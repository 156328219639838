import {
  CreateResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetOneParams,
  GetOneResult,
  GetListParams,
  GetListResult,
  UpdateResult,
  UpdateParams,
} from "react-admin";
import { serverAPI } from "../api/server.api";
import { applyFilter, applyPagination, applySort } from "../backoffice.utils";
import { AbstractProvider } from "./abstractProvider";

export interface Branch {
  id: string;
  createdAt: string | null;
  createdById: string;
  updatedAt: string | null;
  updatedById: string;
  drivingSchoolId: string;
  name: string;
  location?: string | null;
  startDate: string | null;
  active: boolean;
  isDefaultBranch: boolean;
  maxStudentsForTheory: number;
  address: {
    street: string;
    postalCode: string;
    city: string;
    company: string;
    country: string;
  };
}

class BranchesProvider extends AbstractProvider<Branch> {
  async getOne(_: string, { id }: GetOneParams<Branch>): Promise<GetOneResult<Branch>> {
    const branch = await serverAPI.getBranch({ id });
    return { data: branch };
  }

  async getList(_resource: string, params: GetListParams): Promise<GetListResult<Branch>> {
    const { filter, sort, pagination } = params;
    const { drivingSchoolId, ...clientSideFilter } = filter;
    if (!drivingSchoolId) {
      throw new Error(`Unexpected filter: ${JSON.stringify(filter)} -- expected: {"drivingSchoolId": ...}`);
    }
    const branches = await serverAPI.getBranches({ drivingSchoolId });
    return applyPagination(applySort(applyFilter(branches, clientSideFilter), sort), pagination);
  }

  async getManyReference(
    _resource: string,
    { target, id, filter, sort, pagination }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<Branch>> {
    if (target !== "drivingSchoolId") {
      throw new Error(`Invalid target: ${JSON.stringify(target)} -- expected: "drivingSchoolId"`);
    }
    return this.getList("branches", { filter: { drivingSchoolId: id, ...filter }, sort, pagination });
  }

  async create(
    _resource: string,
    params: { data: Omit<Branch, "id" | "createdAt" | "createdById" | "updatedAt" | "updatedBy"> },
  ): Promise<CreateResult<Branch>> {
    const { drivingSchoolId, name, address, active, isDefaultBranch, maxStudentsForTheory } = params.data;

    const payload = {
      drivingSchoolId,
      name,
      address,
      active,
      isDefaultBranch,
      maxStudentsForTheory,
    };
    const branch = await serverAPI.createBranch({ payload });
    return { data: branch };
  }

  async update(_resource: string, params: UpdateParams<Branch>): Promise<UpdateResult<Branch>> {
    const { id, data } = params;
    const { name, address, active, isDefaultBranch, maxStudentsForTheory } = data;
    const payload = {
      name,
      address,
      active,
      isDefaultBranch,
      maxStudentsForTheory,
    };
    const branch = await serverAPI.updateBranch({ id, payload });
    return { data: branch };
  }
}

export const branchesProvider = new BranchesProvider();
