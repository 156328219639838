import { Box, Card, Typography } from "@mui/material";
import { overviewCardStyle } from "./backofficeTheme";

type DashboardCardProps = React.PropsWithChildren<{
  title: string;
}>;

export function DashboardCard({ title, children }: DashboardCardProps) {
  return (
    <Card sx={overviewCardStyle}>
      <Typography sx={{ fontWeight: "bold", fontSize: 15, mb: "15px" }}>{title}</Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>{children}</Box>
    </Card>
  );
}
