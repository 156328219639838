import { ReactElement, useEffect } from "react";

/**
 * Side effect: Updates the document title (i.e. the title of the browser tab).
 */
export function PageTitle(props: { documentTitle: string; children: ReactElement }): ReactElement;
export function PageTitle(props: { children: string }): ReactElement;
export function PageTitle(props: { documentTitle?: string; children: string | ReactElement }): ReactElement {
  const documentTitle = props.documentTitle ?? (props.children as string);
  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);
  const { children } = props;
  if (typeof children === "string") {
    return <h2 style={{ margin: 0, lineHeight: 0.83, marginBottom: 30, marginTop: 10 }}>{children}</h2>;
  }
  return children;
}
