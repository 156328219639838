import { BackofficeListTitle } from "../../misc/BackofficeListTitle";
import { List, NumberField, SelectInput, TextField } from "react-admin";
import { MoneyField } from "../../fields/MoneyField";
import { MostRecentLessonField } from "../../fields/MostRecentLessonField";
import { Typography } from "@mui/material";
import { SendFirstDunningNoticeButton } from "../../buttons/SendFirstDunningNoticeButton";
import { ReferenceDrivingSchoolField } from "../../fields/ReferenceDrivingSchoolField";
import { Money } from "../../misc/Money";
import { autovioColors } from "../../misc/backofficeTheme";
import { BookOutButton } from "../../buttons/BookOutButton";
import { DateField } from "../../fields/DateField";
import { GlobalDrivingSchoolFilter } from "../../misc/GlobalDrivingSchoolFilter";
import { useBalanceSum } from "../../hooks/useBalanceSum";
import { useRecoilValue } from "recoil";
import { persistentGlobalDrivingSchoolFilterState } from "../../persistent_state";
import { DunningDatagrid } from "./DunningDatagrid";

export function CandidatesForFirstDunningNoticeList() {
  const listDescription = "Liste aller inaktiven Fahrschüler mit negativen Saldo, die noch nicht angemahnt wurden";
  const sum = useBalanceSum("candidatesForFirstDunningNotice", "candidatesForFirstDunningNoticeParkingLot");
  const drivingSchoolId = useRecoilValue(persistentGlobalDrivingSchoolFilterState);

  return (
    <>
      {sum ? (
        <>
          <Typography>
            {`${listDescription} • Summe ausstehende Zahlungen: `}
            <Money cents={sum} style={{ color: autovioColors.red }} />
            <sup style={{ color: autovioColors.red }}>*</sup>
          </Typography>
          <Typography color={autovioColors.grey}>
            <sup>*</sup>Es wurden nur Fahrschüler berücksichtigt, die wenigstens eine Theorie- oder Fahrstunde hatten.
          </Typography>
        </>
      ) : (
        <Typography>{listDescription}</Typography>
      )}
      <List
        resource={"candidatesForFirstDunningNotice"}
        title={<BackofficeListTitle />}
        filters={[
          <GlobalDrivingSchoolFilter source="drivingSchoolId" alwaysOn />,
          <SelectInput
            label="Status"
            source="status"
            choices={[
              { id: "cancelled", name: "Abgebrochen" },
              { id: "inactive", name: "Inaktiv" },
              { id: "onHold", name: "Pausiert" },
              { id: "outstandingPayments", name: "Offene Zahlungen" },
              { id: "completed", name: "Fertig" },
            ]}
            alwaysOn
            emptyText={<i>alle</i>}
          />,
        ]}
        sort={{ field: "balance", order: "ASC" }}
        actions={false}
        exporter={false}
        empty={false}
      >
        <DunningDatagrid dialogActions={[<BookOutButton />]}>
          <TextField label="Name" source="name" />
          {!drivingSchoolId && <ReferenceDrivingSchoolField label="Fahrschule" />}
          <MoneyField label="Saldo" source="balance" redForNegativeValues />
          <DateField label="Anmeldung" source="startDate" showDate="with diff" />
          <TextField label="Status" source="verboseStatusSinceXxxDays" />
          <NumberField
            label="genommene Theoriestunden"
            source="kpis.finishedTheoryLessonsCount"
            options={{ maximumFractionDigits: 1 }}
          />
          <NumberField
            label="genommene Fahrstunden"
            source="kpis.finishedDrivingLessonsCount"
            options={{ maximumFractionDigits: 1 }}
          />
          <MostRecentLessonField label="letzte genommene Stunde" />
          <SendFirstDunningNoticeButton />
        </DunningDatagrid>
      </List>
    </>
  );
}
