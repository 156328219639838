import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useState } from "react";
import { useNotify } from "react-admin";
import { RecommendationWithPrice, useBookRecommendationMutation } from "../../api/backoffice.api";
import { grants } from "../../backoffice.access_control";
import { CancellationPolicy } from "../../model/CancellationPolicy";
import { PostalAddress } from "../../model/PostalAddress";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";
import { InstructorDisplayName } from "../InstructorDisplayName";
import { Money } from "../Money";
import { StudentDisplayName } from "../StudentDisplayName";
import { ConfirmCancellationPolicyDialog } from "./ConfirmCancellationPolicyDialog";
import { LoadingButton } from "@mui/lab";
import { handleBookingError } from "../../utils/handleBookingError";
import { DialogCloseButton } from "../DialogCloseButton";

interface ConfirmBookingDialogProps {
  readonly drivingSchool: DrivingSchool;
  readonly startLocation: PostalAddress;
  readonly recommendation: RecommendationWithPrice;
  readonly isOpen: boolean;
  readonly onClose: () => void;
}

export function ConfirmBookingDialog({
  drivingSchool,
  startLocation,
  recommendation,
  isOpen,
  onClose,
}: ConfirmBookingDialogProps) {
  const [isConfirmingCancellationPolicy, setIsConfirmingCancellationPolicy] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [isInviting, setIsInviting] = useState(false);
  const isLoading = isBooking || isInviting;
  const bookRecommendationMutation = useBookRecommendationMutation();
  const notify = useNotify();

  const book = async (agreedCancellationPolicy?: CancellationPolicy) => {
    try {
      setIsBooking(true);
      await bookRecommendationMutation.mutateAsync({
        recommendation,
        startLocation,
        studentRsvp: "accepted",
        studentAgreedCancellationPolicy: agreedCancellationPolicy,
      });
      notify("Termin erfolgreich gebucht", { type: "success" });
      onClose();
    } catch (error) {
      if (!(await handleBookingError(error, recommendation.studentUid, notify))) {
        console.error("Failed to book recommendation", error);
        notify("Fehler beim Buchen des Termins", { type: "error" });
      }
    } finally {
      setIsBooking(false);
    }
  };

  const invite = async () => {
    try {
      setIsInviting(true);
      await bookRecommendationMutation.mutateAsync({
        recommendation,
        startLocation,
        studentRsvp: "pending",
      });
      notify("Einladung erfolgreich versandt", { type: "success" });
      onClose();
    } catch (error) {
      if (!(await handleBookingError(error, recommendation.studentUid, notify))) {
        console.error("Failed to invite student", error);
        notify("Fehler beim Versenden der Einladung", { type: "error" });
      }
    } finally {
      setIsInviting(false);
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Übungsstunde buchen</DialogTitle>
        <DialogCloseButton onClose={onClose} disabled={isLoading} />
        <DialogContent sx={{ minWidth: "300px" }}>
          <BookingDialogDetailsRow
            label="Start:"
            value={<Typography variant="body2">{`${recommendation.start.toFormat("dd.MM. - HH:mm")}`}</Typography>}
          />
          <BookingDialogDetailsRow
            label="Ende:"
            value={<Typography variant="body2">{`${recommendation.end.toFormat("dd.MM. - HH:mm")}`}</Typography>}
          />
          <BookingDialogDetailsRow
            label="Startadresse:"
            value={<Typography variant="body2">{startLocation.street}</Typography>}
          />
          <BookingDialogDetailsRow
            label=""
            value={<Typography variant="body2">{`${startLocation.postalCode} ${startLocation.city}`}</Typography>}
          />
          <Box sx={{ height: "16px" }} />
          <BookingDialogDetailsRow
            label="Fahrschüler:"
            value={<StudentDisplayName studentId={recommendation.studentUid} />}
          />
          <BookingDialogDetailsRow
            label="Fahrlehrer:"
            value={<InstructorDisplayName instructorId={recommendation.instructorUid} />}
          />
          <Box sx={{ height: "16px" }} />
          <BookingDialogDetailsRow
            label="Preis:"
            value={
              <Typography variant="body2">
                <Money cents={recommendation.price.studentPrice.gross} />
              </Typography>
            }
          />
        </DialogContent>
        <DialogActions>
          {grants.includes("bookAppointments") && (
            <LoadingButton
              variant="contained"
              disabled={isLoading}
              loading={isBooking}
              onClick={() => {
                if (drivingSchool.customizations.includes("cancellationPolicyPerLesson")) {
                  setIsConfirmingCancellationPolicy(true);
                } else {
                  void book();
                }
              }}
            >
              Verbindlich buchen
            </LoadingButton>
          )}
          <LoadingButton variant="contained" disabled={isLoading} loading={isInviting} onClick={invite}>
            Einladung senden
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ConfirmCancellationPolicyDialog
        isOpen={isConfirmingCancellationPolicy}
        studentUid={recommendation.studentUid}
        onCancel={() => {
          setIsConfirmingCancellationPolicy(false);
          onClose();
        }}
        onConfirm={() => {
          setIsConfirmingCancellationPolicy(false);
          void book(drivingSchool.paidLessonCancellationPolicy);
        }}
      />
    </>
  );
}

interface BookingDialogDetailsRowProps {
  readonly label: string;
  readonly value: React.ReactNode;
}
const BookingDialogDetailsRow = (props: BookingDialogDetailsRowProps) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Typography variant="body2" fontWeight="bold">
        {props.label}
      </Typography>
      {props.value}
    </Box>
  );
};
