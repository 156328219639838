import { Controller, useFormContext } from "react-hook-form";
import { Student } from "../../providers/studentsProvider";
import { StudentSelect } from "../../inputs/StudentSelect";
import { ExamFormValues } from "./ExamForm";

export const StudentInputField = (props: { drivingSchoolId?: string; instructorId?: string }) => {
  const { control, setValue, watch } = useFormContext<ExamFormValues>();
  const student = watch("student");
  return (
    <Controller
      name="student"
      control={control}
      render={({ field, formState }) => (
        <StudentSelect
          inputRef={field.ref}
          label="Fahrschüler"
          size="medium"
          disabled={formState.isSubmitting}
          drivingSchoolId={props.drivingSchoolId}
          instructorId={props.instructorId}
          value={student}
          onChange={(value) => {
            field.onChange({ target: { value } });
            if (value) {
              setValue("drivingLicenseClass", value.activeOrMostRecentBookedTraining.drivingLicenseClass);
            }
          }}
          onBlur={field.onBlur}
          error={formState.errors.student?.message}
        />
      )}
      rules={{
        validate: (value?: Student) => {
          return value ? true : "Bitte wähle den Fahrschüler aus.";
        },
      }}
    />
  );
};
