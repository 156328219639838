import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, CircularProgress, IconButton, SxProps } from "@mui/material";
import { useState } from "react";
import { useNotify } from "react-admin";
import { useAddStudentToTheoryLessonMutation } from "../api/backoffice.api";
import { StudentSelect } from "../inputs/StudentSelect";
import { TheoryLesson, TheoryLessonStudentAttendance } from "../model/autovioCalendarEvents";
import { Student } from "../providers/studentsProvider";
import { Row } from "../misc/Row";

export const AddStudentToTheoryLessonButton = ({ sx, theoryLesson }: { sx?: SxProps; theoryLesson: TheoryLesson }) => {
  const [showStudentSelect, setShowStudentSelect] = useState(false);
  const [addingStudent, setAddingStudent] = useState(false);
  const addStudentToTheoryLessonMutation = useAddStudentToTheoryLessonMutation();
  const notify = useNotify();

  const addStudentToTheoryLesson = async (student: Student) => {
    try {
      setAddingStudent(true);
      await addStudentToTheoryLessonMutation.mutateAsync({
        studentUid: student.id,
        calendarEventUid: theoryLesson.id,
      });
      notify(`${student.name} erfolgreich hinzugefügt.`, { type: "success" });
    } catch (error) {
      console.error(`Failed to add student ${student.id} to theory lesson ${theoryLesson.id}`, error);
      notify(`Fehler beim Hinzufügen von ${student.name}.`, { type: "error" });
    } finally {
      setAddingStudent(false);
      setShowStudentSelect(false);
    }
  };

  const handleStudentSelected = async (student: Student | null) => {
    if (!student) return;
    await addStudentToTheoryLesson(student);
  };

  return showStudentSelect ? (
    <Row sx={sx} alignItems="center" spacing="10px">
      <StudentSelect
        autoFocus
        label="Fahrschüler hinzufügen"
        disabled={addingStudent}
        sx={{ minWidth: "250px", flex: 1 }}
        size="small"
        onChange={(student) => handleStudentSelected(student)}
        drivingSchoolId={theoryLesson.drivingSchoolId}
        filterStudents={(student) => {
          const attendance: TheoryLessonStudentAttendance | undefined = theoryLesson.students[student.id];
          return !attendance || attendance.rsvp === "rejected";
        }}
      />
      {addingStudent ? (
        <CircularProgress size={24} />
      ) : (
        <IconButton size="small" onClick={() => setShowStudentSelect(false)}>
          <CloseIcon color="action" />
        </IconButton>
      )}
    </Row>
  ) : (
    <Box sx={sx}>
      <Button
        variant="text"
        sx={{ height: "40px" /* ... same height as <StudentSelect> */ }}
        startIcon={<AddIcon />}
        onClick={() => setShowStudentSelect(true)}
      >
        Fahrschüler hinzufügen
      </Button>
    </Box>
  );
};
