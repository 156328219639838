import React, { FC, useState, useCallback, createContext, useContext } from "react";
import { useNotify } from "react-admin";
import { useQueryClient } from "react-query";
import { AutovioCalendarEvent } from "../model/autovioCalendarEvents";
import { serverAPI } from "../api/server.api";

const EventDialogContext = createContext<{
  isSaving: boolean;
  studentsTempAttendance: Record<string, boolean>;
  handleAttendButtonClick: (studentId: string) => void;
  handleNoShowButtonClick: (studentId: string) => void;
  handleSaveStudentsAttendance: () => Promise<void>;
} | null>(null);

export const EventDialogProvider: FC<{ children: React.ReactNode; event: AutovioCalendarEvent }> = ({
  children,
  event,
}) => {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [studentsTempAttendance, setStudentsTempAttendance] = useState<Record<string, boolean>>({});

  const handleAttendButtonClick = useCallback(
    (studentId: string) => {
      if (isSaving) return;
      const attended = studentsTempAttendance[studentId] === true;
      if (attended) return;
      setStudentsTempAttendance((prevState) => ({ ...prevState, [studentId]: true }));
    },
    [isSaving, studentsTempAttendance],
  );

  const handleNoShowButtonClick = useCallback(
    (studentId: string) => {
      if (isSaving) return;
      const noShow = studentsTempAttendance[studentId] === false;
      if (noShow) return;
      setStudentsTempAttendance((prevState) => ({ ...prevState, [studentId]: false }));
    },
    [isSaving, studentsTempAttendance],
  );

  const handleSaveStudentsAttendance = useCallback(async () => {
    setIsSaving(true);
    try {
      await serverAPI.updateTheoryLessonAttendance({
        eventId: event.id,
        attendance: studentsTempAttendance,
      });
      notify("Die Teilnahme an der Theoriestunde wurde erfolgreich aktualisiert", { type: "success" });
      await queryClient.invalidateQueries(["calendarEvents"]);
      await queryClient.invalidateQueries(["calendarEventHistory"]);
      setStudentsTempAttendance({});
    } catch (error) {
      console.error("Failed to update theory lesson attendance", error);
      notify("Aktualisierung der Teilnahme am Theorieunterricht fehlgeschlagen", { type: "error" });
    }
    setIsSaving(false);
  }, [studentsTempAttendance]);

  return (
    <EventDialogContext.Provider
      value={{
        isSaving,
        studentsTempAttendance,
        handleAttendButtonClick,
        handleNoShowButtonClick,
        handleSaveStudentsAttendance,
      }}
    >
      {children}
    </EventDialogContext.Provider>
  );
};

export const useEventDialogContext = () => {
  const context = useContext(EventDialogContext);
  if (!context) {
    throw new Error("useEventDialogContext must be used within an EventDialogProvider");
  }
  return context;
};
