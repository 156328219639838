import { Tabs } from "../misc/Tabs";
import { Tab } from "react-admin";
import { NotesList } from "./NotesList";
import { DocumentsList } from "./DocumentsList";
import { IconButton, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { autovioColors } from "../misc/backofficeTheme";
import { CSSProperties, useRef } from "react";
import { Row } from "../misc/Row";

export type NotesAndDocumentsSource = "student" | "drivingSchool";

export function NotesAndDocuments({
  showToggleButton,
  handleDrawerToggle,
  open,
  style,
  source,
}: {
  handleDrawerToggle?: () => void;
  open?: boolean;
  showToggleButton?: boolean;
  style?: CSSProperties;
  source: NotesAndDocumentsSource;
}) {
  const { green } = autovioColors;
  const ref = useRef<{ showForm: () => void }>();

  return (
    <>
      <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: "18px" }}>
        <Typography sx={{ flex: 1, fontWeight: "bold", fontSize: 15 }}>Notizen & Dokumente</Typography>
        <Row sx={{ gap: "20px" }}>
          <IconButton sx={{ color: green, width: 24, height: 24 }} onClick={() => ref.current?.showForm()}>
            <AddIcon style={{ fontSize: 24 }} />
          </IconButton>
          {showToggleButton && (
            <IconButton sx={{ color: green, width: 24, height: 24 }} onClick={() => handleDrawerToggle?.()}>
              <KeyboardArrowDownIcon style={{ fontSize: 24, transform: open ? "rotate(0deg)" : "rotate(180deg)" }} />
            </IconButton>
          )}
        </Row>
      </Row>
      <Tabs syncWithLocation={false} fullWidth>
        <Tab label="Notizen">
          <NotesList ref={ref} style={style} resource={source === "student" ? "studentNotes" : "drivingSchoolNotes"} />
        </Tab>
        <Tab label="Dokumente">
          <DocumentsList
            ref={ref}
            style={style}
            resource={source === "student" ? "studentDocuments" : "drivingSchoolDocuments"}
          />
        </Tab>
      </Tabs>
    </>
  );
}
