import {
  collection,
  deleteField,
  doc,
  DocumentData,
  documentId,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  getDocFromServer,
  query,
  Query,
  where,
} from "firebase/firestore";
import { FirestoreProvider } from "./firestoreProvider";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";
import { t } from "../model/types";
import type { z } from "zod";
import { CancellationPolicySchema } from "../model/CancellationPolicy";
import { PostalAddressSchema } from "../model/PostalAddress";
import memoizeOne from "memoize-one";
import { safeLocalStorage } from "../utils/safeLocalStorage";
import { firestore } from "../firebase";
import { DateTime } from "luxon";
import { CustomizationFlagEnum } from "../model/customizationFlags";

const DrivingSchoolDefaultsSchema = t.object({
  paidLessonCancellationPolicy: CancellationPolicySchema,
  theoryLessonCancellationPolicy: CancellationPolicySchema,
});

export function isDrivingSchool(record: any): record is DrivingSchool {
  return typeof record === "object" && "uid" in record && "stripeConnectedAccountId" in record;
}

type DrivingSchoolDefaults = z.infer<typeof DrivingSchoolDefaultsSchema>;

let defaults: DrivingSchoolDefaults;

const loadDefaults = memoizeOne(async (firestore: Firestore) => {
  let cachedDefaultsJson: string | undefined;
  const cachedDefaults = safeLocalStorage.getItem("firestore::config/defaults.DrivingSchool", (s: string) => {
    cachedDefaultsJson = s;
    DrivingSchoolDefaultsSchema.parse(JSON.parse(s));
  });
  if (cachedDefaults) {
    defaults = cachedDefaults;
    void _loadDefaults(firestore).then((cachedDefaultsJson2) => {
      if (cachedDefaultsJson2 && cachedDefaultsJson2 !== cachedDefaultsJson) {
        void drivingSchoolsProvider.reload();
      }
    });
  } else {
    await _loadDefaults(firestore);
  }
});

async function _loadDefaults(firestore: Firestore): Promise<string | undefined> {
  try {
    const snapshot = await getDocFromServer(doc(firestore, "config/defaults"));
    defaults = t.object({ DrivingSchool: DrivingSchoolDefaultsSchema }).parse(snapshot.data()).DrivingSchool;
    const cachedDefaultsJson = JSON.stringify(defaults);
    safeLocalStorage.setItem("firestore::config/defaults.DrivingSchool", cachedDefaultsJson);
    return cachedDefaultsJson;
  } catch (error) {
    console.error("Failed to load DrivingSchool defaults", error);
  }
}

const BranchSchema = t.object({
  uid: t.uid(),
  drivingSchoolUid: t.uid(),
  name: t.optional(t.string()).transform((val) => val ?? ""),
  postalAddress: PostalAddressSchema,
  isDefaultBranch: t.boolean().optional(),
  maxAmountOfStudents: t.number().int(),
});

export type Branch = z.infer<typeof BranchSchema>;

const DrivingSchoolFirestoreDocumentDataSchema = t
  .object({
    branches: t.optional(t.record(BranchSchema)).transform((val) => Object.values(val ?? {})),
    customizations: t
      .optional(t.array(CustomizationFlagEnum.catch(() => "unknown" as any)))
      .transform((val) => (val ?? []).filter((it) => it !== ("unknown" as any))),
    hubspotCompanyId: t.hubspotCompanyId(),
    instructorUids: t.optional(t.array(t.uid())).transform((val) => val ?? []),
    inviteLink: t.optional(t.string()),
    invoiceFooter: t.string(),
    linkToHandbook: t.optional(t.string()),
    logoUrl: t.optional(t.string()),
    maxAmountOfActiveStudentsPerInstructor: t.optional(t.number().int()),
    maxAmountOfStudentsForTheoryLesson: t.number().int(),
    name: t.string(),
    paidLessonCancellationPolicy: t
      .optional(CancellationPolicySchema)
      .transform((val) => val ?? defaults.paidLessonCancellationPolicy),
    postalAddress: PostalAddressSchema,
    profileUrl: t.optional(t.string()),
    referralContent: t.optional(t.object({ text: t.optional(t.string()), title: t.optional(t.string()) })),
    stampStorageRef: t.string().nullish(),
    stripeConnectedAccountId: t.string(),
    theoryLessonCancellationPolicy: t
      .optional(CancellationPolicySchema)
      .transform((val) => val ?? defaults.theoryLessonCancellationPolicy),
    uid: t.string(),
    startDate: t.dateTime(),
    endDate: t.optional(t.dateTime()),
    threadId: t.optional(t.string().uuid()).nullish(),
  })
  .transform((drivingSchool) => {
    for (const branch of drivingSchool.branches) {
      if (!branch.name) {
        // Fall back to the city (if there is only one branch in the city),
        // or to the street (if all branches are in the same city)
        // or to the city and the street otherwise ...
        if (drivingSchool.branches.filter((it) => it.postalAddress.city === branch.postalAddress.city).length === 1) {
          branch.name = branch.postalAddress.city;
        } else if (drivingSchool.branches.every((it) => it.postalAddress.city === branch.postalAddress.city)) {
          branch.name = branch.postalAddress.street;
        } else {
          branch.name = `${branch.postalAddress.city}, ${branch.postalAddress.street}`;
        }
      }
    }
    (drivingSchool as any).id = drivingSchool.uid;
    (drivingSchool as any).isActive =
      drivingSchool.startDate < DateTime.now() && (!drivingSchool.endDate || DateTime.now() < drivingSchool.endDate);
    return drivingSchool;
  });

export type DrivingSchool = z.infer<typeof DrivingSchoolFirestoreDocumentDataSchema> & {
  id: string;
  isActive: boolean;
  prohibitsTheoryLearningInTheApp: boolean;
};

class DrivingSchoolsProvider extends FirestoreProvider<DrivingSchool> {
  querySingle(id: string): [DocumentReference, (record: DrivingSchool) => void] {
    if (restrictAccessToDrivingSchoolIds && !restrictAccessToDrivingSchoolIds.includes(id)) {
      throw new Error(`Access to driving school ${id} denied`);
    }
    return [doc(firestore, `/driving_schools/${id}`), () => {}];
  }

  queryAll(): DocumentReference | Query {
    if (restrictAccessToDrivingSchoolIds?.length === 1) {
      return doc(firestore, `/driving_schools/${restrictAccessToDrivingSchoolIds[0]}`);
    } else if (restrictAccessToDrivingSchoolIds) {
      return query(
        collection(firestore, `/driving_schools`),
        where(documentId(), "in", restrictAccessToDrivingSchoolIds),
      );
    } else {
      return collection(firestore, `/driving_schools`);
    }
  }

  async fromFirestore(doc: DocumentSnapshot): Promise<DrivingSchool> {
    if (!defaults) {
      await loadDefaults(doc.ref.firestore);
    }
    const drivingSchool = DrivingSchoolFirestoreDocumentDataSchema.parse(doc.data()) as DrivingSchool;
    drivingSchool.prohibitsTheoryLearningInTheApp = !drivingSchool.customizations?.includes("theoryLearning");
    return drivingSchool;
  }

  toFirestore(updateData: DrivingSchool): DocumentData {
    const { maxAmountOfActiveStudentsPerInstructor, referralContent, logoUrl, profileUrl } = updateData;

    return {
      maxAmountOfActiveStudentsPerInstructor: maxAmountOfActiveStudentsPerInstructor
        ? maxAmountOfActiveStudentsPerInstructor
        : deleteField(),
      ...(updateData.customizations && { customizations: updateData.customizations }),
      referralContent: referralContent ? referralContent : deleteField(),
      ...(logoUrl && { logoUrl }),
      ...(profileUrl && { profileUrl }),
    };
  }
}

export const drivingSchoolsProvider = new DrivingSchoolsProvider("DrivingSchool", "driving_schools");
