import { Box, SxProps } from "@mui/material";
import { TheoryLesson } from "../model/autovioCalendarEvents";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";
import { useRemoveStudentsFromTheoryLessonMutation } from "../api/backoffice.api";
import { useNotify } from "react-admin";
import { DeleteIcon } from "../icons/DeleteIcon";
import { autovioColors } from "../misc/backofficeTheme";

export function RemoveStudentFromTheoryLessonButton({
  sx,
  theoryLesson,
  studentId,
}: {
  sx?: SxProps;
  theoryLesson: TheoryLesson;
  studentId: string;
}) {
  const [removingStudent, setRemovingStudent] = useState(false);
  const removeStudentsFromTheoryLessonMutation = useRemoveStudentsFromTheoryLessonMutation();
  const notify = useNotify();

  const removeStudentFromTheoryLesson = async () => {
    try {
      setRemovingStudent(true);
      await removeStudentsFromTheoryLessonMutation.mutateAsync({
        studentUids: [studentId],
        calendarEventUid: theoryLesson.id,
      });
      notify(`Fahrschüler erfolgreich entfernt.`, { type: "success" });
    } catch (error) {
      console.error(`Failed to remove student ${studentId} from theory lesson ${theoryLesson.id}`, error);
      notify(`Fehler beim Entfernen des Fahrschülers.`, { type: "error" });
    } finally {
      setRemovingStudent(false);
    }
  };

  return (
    <Box sx={sx}>
      <LoadingButton
        loading={removingStudent}
        variant="text"
        sx={{ color: autovioColors.red }}
        startIcon={<DeleteIcon />}
        onClick={removeStudentFromTheoryLesson}
      >
        Kostenfrei entfernen
      </LoadingButton>
    </Box>
  );
}
