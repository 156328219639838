import { Button } from "react-admin";
import Typography from "@mui/material/Typography";
import { Row } from "../../misc/Row";
import type { DrivingLessonFormValues } from "./DrivingLessonForm";
import { useFormContext } from "react-hook-form";
import { useDialog } from "../../hooks/useDialog";
import { DrivingLessonRepetitionsDialog } from "../DrivingLessonRepetitionsDialog/DrivingLessonRepetitionsDialog";
import { Column } from "../../misc/Column";
import { autovioColors } from "../../misc/backofficeTheme";

export const DrivingLessonRepetitionsField = () => {
  const { dialogProps, openDialog } = useDialog();
  const { watch } = useFormContext<DrivingLessonFormValues>();
  const { student, dateTime, repetitions } = watch();
  const hasRepetitions = repetitions.length > 0;

  if (!student) return null;
  return (
    <Row sx={{ alignItems: "flex-start", justifyContent: "space-between" }}>
      <Column sx={{ px: 0.5 }}>
        <Typography variant="button">Wiederholungen:</Typography>
        <Typography variant="body2" color={hasRepetitions ? autovioColors.black : autovioColors.grey}>
          {hasRepetitions ? `${repetitions.length + 1} Termine insgesamt` : "keine"}
        </Typography>
      </Column>
      <Button
        sx={{ p: 1 }}
        label={hasRepetitions ? "Wiederholungen aktualisieren" : "Wiederholungen hinzufügen"}
        disabled={!dateTime}
        onClick={openDialog}
      />
      <DrivingLessonRepetitionsDialog {...dialogProps} />
    </Row>
  );
};
