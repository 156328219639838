import {
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  getDocFromServer,
  getDocsFromServer,
  onSnapshot,
  Query,
  QuerySnapshot,
  Unsubscribe,
  updateDoc,
} from "firebase/firestore";
import type {
  CreateParams,
  CreateResult,
  DeleteManyParams,
  DeleteManyResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  RaRecord,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from "react-admin";
import { firestore } from "../firebase";
import { applyFilter, applyPagination, applySort, sleep } from "../backoffice.utils";

type FirestoreRecord = RaRecord & { id: string };

export const knownInvalidDocuments = new Set<string>();

interface Snapshot<T> {
  records: Array<T>;
  recordsById: Map<string, T>;
}

export abstract class FirestoreProvider<T extends FirestoreRecord> {
  _recordsById: Map<string, T> | undefined;
  private _snapshotPromise: Promise<Snapshot<T>> | undefined;
  private _unsubscribe: Unsubscribe | undefined;

  constructor(
    private resourceType: string,
    protected collection: string,
  ) {}

  async preload(): Promise<void> {
    await this.snapshot();
  }

  reload(): Promise<void> {
    this._unsubscribe?.();
    this._unsubscribe = undefined;
    this._snapshotPromise = undefined;
    this._recordsById = undefined;
    return this.preload();
  }

  querySingle(_id: string): [Query | DocumentReference, /* checkAccess: */ (record: T) => void] {
    throw new Error(`querySingle is not overridden by ${this.constructor.name}`);
  }

  abstract queryAll(): Query | DocumentReference;

  abstract fromFirestore(doc: DocumentSnapshot<unknown>): T | Promise<T>;

  toFirestore(_updateData: Partial<T>, _previousData: T): DocumentData {
    throw new Error("Unimplemented: FirestoreProvider is readonly by default. Override to implement.");
  }

  async getList(_: string, { filter, sort, pagination }: GetListParams): Promise<GetListResult<T>> {
    const { records } = await this.snapshot();
    return applyPagination(applySort(applyFilter(records, filter), sort), pagination);
  }

  async getOne(_: string, { id }: GetOneParams): Promise<GetOneResult<T>> {
    const recordsById = this._recordsById;
    let record: T | undefined;
    if (recordsById) {
      record = recordsById.get(id);
    } else {
      record = await this.fetchOne(id);
    }
    if (!record) {
      throw new Error(`${this.resourceType} ${id} not found`);
    }
    return { data: record };
  }

  getOneFromCache(id: string): T | undefined {
    return this._recordsById?.get(id);
  }

  getAllFromCache(): Iterable<T> {
    return this._recordsById?.values() ?? [];
  }

  async getMany(resource: string, params: GetManyParams): Promise<GetManyResult<T>> {
    const data: Array<T> = [];
    if (params.ids.length > 5) {
      await this.preload();
    }
    for (const id of params.ids) {
      const { data: record } = await this.getOne(resource, { id });
      data.push(record);
    }
    return { data };
  }

  async getManyReference(
    resource: string,
    { target, id, pagination, sort, filter }: GetManyReferenceParams,
  ): Promise<GetManyReferenceResult<T>> {
    return this.getList(resource, { pagination, sort, filter: { ...filter, [target]: id } });
  }

  async create(_: string, __: CreateParams): Promise<CreateResult<T>> {
    throw new Error("Not implemented");
  }

  async update(_: string, update: UpdateParams<T>): Promise<UpdateResult<T>> {
    const updatePayload = Object.fromEntries(
      Object.entries(this.toFirestore(update.data, update.previousData)).filter(
        ([_, value]) => typeof value !== "undefined",
      ),
    );
    console.info(`Updating ${this.collection}/${update.id} ...`, updatePayload);
    await updateDoc(doc(firestore, `${this.collection}/${update.id}`), updatePayload);
    return this.getOne(this.resourceType, { id: update.id });
  }

  async updateMany(_: string, __: UpdateManyParams): Promise<UpdateManyResult> {
    throw new Error("Not implemented");
  }

  async delete(_: string, __: DeleteParams): Promise<DeleteResult<T>> {
    throw new Error("Not implemented");
  }

  async deleteMany(_: string, __: DeleteManyParams): Promise<DeleteManyResult> {
    throw new Error("Not implemented");
  }

  snapshot(): Promise<Snapshot<T>> {
    if (!this._snapshotPromise) {
      const queryOrDocRef = this.queryAll();
      if (isDocumentReference(queryOrDocRef)) {
        this._snapshotPromise = this._loadFromFirestore(queryOrDocRef);
      } else {
        this._snapshotPromise = this._loadAllFromFirestore(queryOrDocRef);
      }
    }
    return this._snapshotPromise;
  }

  filterAndGroupRecords(records: Array<T>): Snapshot<T> {
    const recordsById = new Map(records.map((record) => [record.id, record]));
    for (const record of records) {
      const { autovioUserId } = record;
      if (autovioUserId) {
        recordsById.set(autovioUserId, record);
      }
    }
    return { records, recordsById };
  }

  async fetchOne(id: string): Promise<T | undefined> {
    const [queryOrDocRef, checkAccess] = this.querySingle(id);
    let record: T | undefined;
    if (isDocumentReference(queryOrDocRef)) {
      const snapshot = await getDocFromServer(queryOrDocRef);
      if (snapshot.exists()) {
        record = await this.fromFirestore(snapshot);
        checkAccess(record);
      }
    } else {
      const { docs } = await getDocsFromServer(queryOrDocRef);
      if (docs.length > 1) {
        throw new Error(`Found multiple ${this.resourceType} for id: ${id}`);
      } else if (docs.length === 1) {
        record = await this.fromFirestore(docs[0]);
        checkAccess(record);
      }
    }
    return record;
  }

  async _loadFromFirestore(docRef: DocumentReference): Promise<Snapshot<T>> {
    const { id } = docRef;
    console.info(`Retrieving ${this.resourceType} ${id} ...`);
    const firstSnapshot = await getDocFromServer(docRef);
    if (!firstSnapshot.exists()) {
      throw new Error(`Firestore document ${docRef.path} does not exist`);
    }
    const record = await this.fromFirestore(firstSnapshot);
    console.info(`Retrieved ${this.resourceType} ${id}`);
    try {
      return this._onNewSnapshot([record]);
    } finally {
      let _log = false; // <-- Don't log the first received snapshot
      this._unsubscribe = onSnapshot(docRef, async (newSnapshot) => {
        try {
          const newRecord = await this.fromFirestore(newSnapshot);
          void this._onNewSnapshot([newRecord]);
          if (_log) {
            console.info(`Retrieved new snapshot of ${this.resourceType} ${id}`);
          } else {
            _log = true;
          }
        } catch (error) {
          console.error(`Failed to parse new snapshot ${docRef.path} -- ignoring it`, error);
          knownInvalidDocuments.add(docRef.path);
        }
      });
    }
  }

  async _loadAllFromFirestore(query: Query): Promise<Snapshot<T>> {
    console.info(`Retrieving all ${this.resourceType}s ...`);
    const t0 = Date.now();
    try {
      const firstSnapshot = await getDocsFromServer(query);
      const records: Array<T> = [];
      for (const doc of firstSnapshot.docs) {
        try {
          const record = await this.fromFirestore(doc);
          records.push(record);
        } catch (error) {
          const { path } = doc.ref;
          if (!knownInvalidDocuments.has(path)) {
            knownInvalidDocuments.add(path);
            console.info(`Failed to parse Firestore document ${path} -- ignoring it`, error);
          }
        }
      }
      console.info(`Retrieved ${records.length} ${this.resourceType}(s) in ${Date.now() - t0}ms`);
      try {
        return this._onNewSnapshot(records);
      } finally {
        let _log = false; // <-- Don't log the first received snapshot
        let _latestSnapshot: undefined | QuerySnapshot;
        let _relax = false;
        this._unsubscribe = onSnapshot(query, async (newSnapshot) => {
          // Ignore incomplete snapshots by checking their length ...
          // From https://github.com/firebase/firebase-js-sdk/issues/5682:
          // "The first snapshot will be raised once any document content is available -
          // this might be before the server responds if there are any document in the cache
          // that match the query. This cache includes all currently active queries even if
          // the cache is only kept in memory (and you have not opted into disk persistence).
          // As the backend starts returning results, documents are then added to the next snapshot.
          // All documents will cause an ADDED change once, but it is correct that this can happen
          // over multiple snapshots."
          if (newSnapshot.docs.length < firstSnapshot.docs.length * 0.95) {
            return;
          }
          _latestSnapshot = newSnapshot;
          // We wait a little bit here, to (1) Give the CPU time to do more important stuff
          // and (2) to see if another snapshot arrives in the meantime ...
          await sleep({ milliseconds: _relax ? 1500 : 500 });
          const newRecords: Array<T> = [];
          for (const doc of newSnapshot.docs) {
            try {
              if (_latestSnapshot !== newSnapshot) {
                // Another snapshot has been received!
                _relax = true;
                return;
              }
              const newRecord = await this.fromFirestore(doc);
              newRecords.push(newRecord);
            } catch (error) {
              const { path } = doc.ref;
              if (!knownInvalidDocuments.has(path)) {
                knownInvalidDocuments.add(path);
                console.info(`Failed to parse Firestore document ${path} -- ignoring it`, error);
              }
            }
          }
          if (_log) {
            console.info(`Retrieved new ${this.resourceType} snapshot with ${newRecords.length} record(s)`);
          } else {
            _log = true;
          }
          void this._onNewSnapshot(newRecords);
          _relax = false;
        });
      }
    } catch (error) {
      console.error(`Failed to retrieve all ${this.resourceType}s`, { error, query });
      throw error;
    }
  }

  _onNewSnapshot(records: Array<T>): Promise<Snapshot<T>> {
    const snapshot = this.filterAndGroupRecords(records);
    this._recordsById = snapshot.recordsById;
    const snapshotPromise = Promise.resolve(snapshot);
    this._snapshotPromise = snapshotPromise;
    return snapshotPromise;
  }
}

function isDocumentReference(x: Query | DocumentReference<DocumentData>): x is DocumentReference<DocumentData> {
  return x.type === "document";
}
