export function isValidPhoneNumber(s: string): boolean {
  s = s.trim();
  if (s.startsWith("+")) {
    s = "00" + s.substring(1);
  }
  if (!s.match(/^[\s0-9()-]+$/)) {
    return false;
  }
  return true;
}
