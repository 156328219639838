import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { Datagrid, FunctionField, ListBase, TextField } from "react-admin";
import { MoneyField } from "../../fields/MoneyField";
import { Invoice } from "../../providers/invoicesProvider";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { DateField } from "../../fields/DateField";

export const PrepaidInvoicesCard = ({ studentId, title }: { studentId: string; title: string }) => {
  return (
    <Card sx={{ marginTop: "1em" }}>
      <CardContent>
        <span style={{ fontWeight: 900 }}>{title}</span>
        <br />
        <br />
        <ListBase resource="b2cInvoices" filter={{ studentId, type: "PREPAID_CREDITS" }} perPage={999}>
          <Datagrid
            bulkActionButtons={false}
            isRowSelectable={() => false}
            empty={<Typography>Keine Quittungen gefunden.</Typography>}
          >
            <DateField source="createdAt" label="Datum" sortable={false} />
            <MoneyField source="total" label="Betrag" sortable={false} />
            <TextField source="nr" label="Nummer" sortable={false} />
            <FunctionField
              label=""
              sortable={false}
              render={(invoice: Invoice) => {
                const pdf_url = invoice.downloadUrl;
                return (
                  <Box sx={{ display: "flex" }}>
                    <Box sx={{ minWidth: "62px" }}>
                      {pdf_url && (
                        <Button
                          startIcon={<OpenInNewIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            open(pdf_url, "_blank");
                          }}
                        ></Button>
                      )}
                    </Box>
                  </Box>
                );
              }}
            />
          </Datagrid>
        </ListBase>
      </CardContent>
    </Card>
  );
};
