import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import { autovioColors } from "./backofficeTheme";

export const HoverableNumberCircle: React.FC<{ label: string; onClick: () => void; isRemovable?: boolean }> = ({
  label,
  onClick,
  isRemovable = true,
}) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: 18,
        height: 18,
        borderRadius: "50%",
        backgroundColor: autovioColors.green,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        transition: "background-color 0.3s",
        ...(isRemovable
          ? {
              "&:hover .number": {
                opacity: 0,
              },
              "&:hover .closeIcon": {
                opacity: 1,
              },
            }
          : {}),
      }}
    >
      <Typography
        variant="body1"
        className="number"
        data-testid={`selected-lesson-${label}`}
        sx={{
          pr: "1px",
          fontSize: 11,
          fontWeight: "bold",
          transition: "opacity 0.3s",
          position: "absolute",
          zIndex: 1,
          color: "white",
        }}
      >
        {label}
      </Typography>
      {isRemovable && (
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
          className="closeIcon"
          aria-label={`Delete ${label}`}
          sx={{
            fontSize: 14,
            position: "absolute",
            zIndex: 2,
            opacity: 0,
            transition: "opacity 0.3s",
            color: "white",
            "&:hover": {
              backgroundColor: "rgba(255,255,255,0.2)",
            },
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      )}
    </Box>
  );
};
