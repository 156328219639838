import { type ReactNode } from "react";
import { SaveButton, useNotify } from "react-admin";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { DateTime } from "luxon";
import { Student } from "../../providers/studentsProvider";
import { StartingPointInput } from "../../inputs/StartingPointInput";
import { Instructor } from "../../providers/instructorsProvider";
import { AppointmentDurationInput } from "../../inputs/AppointmentDurationInput";
import { DrivingLicenseClassInput } from "../../inputs/DrivingLicenseClassInput";
import { DateTimeInput } from "../../inputs/DateTimeInput";
import { StudentInputField } from "./StudentInputField";
import { VehiclesInput, OWN_MOTORCYCLE_PSEUDO_ID } from "../../inputs/VehiclesInput";
import { DrivingLicenseClass, isForMotorcycle, isForTrailer } from "../../model/DrivingLicenseClass";
import { instructorRecommendationSettingsProvider } from "../../providers/instructorRecommendationSettingsProvider";
import { bookDrivingLesson } from "../../api/backoffice.api";
import { Column } from "../../misc/Column";
import { Row } from "../../misc/Row";
import { DrivingLessonTypeInput } from "../../inputs/DrivingLessonTypeInput";
import { startingPointsProvider } from "../../providers/startingPointsProvider";
import { _StudentSuggestions } from "./StudentSuggestions";
import { PriceSuggestion } from "./PriceSuggestion";
import { DrivingLessonRepetitionsField } from "./DrivingLessonRepetitionsField";

interface DrivingLessonFormProps {
  instructor: Instructor;
  initialDateTime?: DateTime;
  onSuccess: () => Promise<void>;
  onFailure: (error: unknown, student?: Student) => Promise<void>;
}

export interface DrivingLessonFormValues {
  drivingLessonType: "normal" | "ueberlandfahrt" | "autobahnfahrt" | "nachtfahrt";
  dateTime?: DateTime;
  durationInMinutes: number;
  startingPointId: undefined;
  instructorId: string;
  student?: Student;
  drivingLicenseClass?: DrivingLicenseClass;
  carId?: string;
  motorcycleId?: string;
  trailerId?: string;
  secondMotorcycleOrCarId?: string;
  repetitions: DateTime[];
}

export interface BookDrivingLessonParams {
  dateTime: DateTime;
  drivingLessonType: "normal" | "ueberlandfahrt" | "autobahnfahrt" | "nachtfahrt";
  instructorId: string;
  studentId: string;
  bookedTrainingId: string;
  duration: { minutes: number };
  startingPointId: string;
  resourceIds: Array<string>;
  repetitions: DateTime[];
}

export const DrivingLessonForm = ({ instructor, initialDateTime, onSuccess, onFailure }: DrivingLessonFormProps) => {
  const notify = useNotify();

  const defaultValues = {
    drivingLessonType: "normal",
    dateTime: initialDateTime,
    durationInMinutes: 0,
    instructorId: instructor?.id,
    student: undefined,
    carId: undefined,
    motorcycleId: undefined,
    trailerId: undefined,
    secondMotorcycleOrCarId: undefined,
    drivingLicenseClass: undefined,
    startingPointId: undefined,
    repetitions: [],
  } satisfies DrivingLessonFormValues;

  const formProps = useForm<DrivingLessonFormValues>({
    defaultValues: async () => {
      let durationInMinutes = defaultValues.durationInMinutes;
      if (instructor) {
        const { data } = await instructorRecommendationSettingsProvider.getOne("", { id: instructor.id });
        if (data) {
          durationInMinutes = data.preferredDurationMinsPerDrivingLessonType.normal[0];
        }
      }
      return { ...defaultValues, durationInMinutes };
    },
  });

  function validate(formValues: DrivingLessonFormValues): BookDrivingLessonParams {
    const {
      drivingLessonType,
      dateTime,
      durationInMinutes,
      instructorId,
      student,
      carId,
      motorcycleId,
      trailerId,
      secondMotorcycleOrCarId,
      drivingLicenseClass,
      startingPointId,
    } = formValues;
    if (!dateTime) {
      throw new Error(`Invalid dateTime: ${dateTime}`);
    }
    if (!drivingLessonType) {
      throw new Error(`Invalid drivingLessonType: ${drivingLessonType}`);
    }
    if (!student) {
      throw new Error(`Invalid student: ${student}`);
    }
    if (!instructorId) {
      throw new Error(`Invalid instructorId: ${instructorId}`);
    }
    if (!drivingLicenseClass) {
      throw new Error(`Invalid drivingLicenseClass: ${drivingLicenseClass}`);
    }
    let resourceIds: Array<string>;
    if (isForMotorcycle(drivingLicenseClass)) {
      if (!motorcycleId) {
        throw new Error(`Invalid motorcycleId: ${motorcycleId}`);
      }
      const studentUsesOwnMotorcycle = motorcycleId === OWN_MOTORCYCLE_PSEUDO_ID;
      if (!secondMotorcycleOrCarId) {
        throw new Error(`Invalid secondMotorcycleOrCarId: ${secondMotorcycleOrCarId}`);
      }
      if (secondMotorcycleOrCarId === motorcycleId) {
        throw new Error(`secondMotorcycleOrCarId must not be equal to motorcycleId`);
      }
      resourceIds = studentUsesOwnMotorcycle ? [secondMotorcycleOrCarId] : [motorcycleId, secondMotorcycleOrCarId];
    } else if (isForTrailer(drivingLicenseClass)) {
      if (!carId) {
        throw new Error(`Invalid carId: ${carId}`);
      }
      if (!trailerId) {
        throw new Error(`Invalid trailerId: ${motorcycleId}`);
      }
      resourceIds = [carId, trailerId];
    } else {
      if (!carId) {
        throw new Error(`Invalid carId: ${carId}`);
      }
      resourceIds = [carId];
    }
    const bookedTraining = student.bookedTrainings.find((it) => it.drivingLicenseClass === drivingLicenseClass);
    if (!bookedTraining) {
      throw new Error(`Student ${student.id} has no booked training for driving license class ${drivingLicenseClass}`);
    }
    if (!["normal", "ueberlandfahrt", "autobahnfahrt", "nachtfahrt"].includes(drivingLessonType)) {
      throw new Error(`Invalid drivingLessonType: ${drivingLessonType}`);
    }
    if (!startingPointId) {
      throw new Error(`Invalid startingPointId: ${startingPointId}`);
    }
    if (!durationInMinutes) {
      throw new Error(`Invalid durationInMinutes: ${durationInMinutes}`);
    }
    if (formValues.repetitions.some((date) => date.hasSame(dateTime, "day"))) {
      throw new Error("Invalid lesson repetition conflicting with main lesson time");
    }
    const repetitions = formValues.repetitions.map((date) =>
      date.startOf("day").set({ hour: dateTime.hour, minute: dateTime.minute }),
    );

    return {
      drivingLessonType,
      instructorId,
      studentId: student.id,
      bookedTrainingId: bookedTraining.id,
      dateTime,
      duration: { minutes: durationInMinutes },
      startingPointId,
      resourceIds,
      repetitions,
    } satisfies BookDrivingLessonParams;
  }

  const onSubmit = async (formValues: DrivingLessonFormValues) => {
    try {
      const params = validate(formValues);
      const { startingPointId, dateTime, ...rest } = params;
      const { data: startingPoint } = await startingPointsProvider.getOne("startingPoints", { id: startingPointId });
      const location = startingPoint.postalAddress;
      const studentRsvp = dateTime < DateTime.now() ? "accepted" : "pending";

      await bookDrivingLesson({ ...rest, dateTime, location, studentRsvp });
      notify(studentRsvp === "pending" ? "Einladung erfolgreich versandt" : "Fahrstunde erfolgreich eingetragen", {
        type: "success",
      });
      await onSuccess();
    } catch (error) {
      await onFailure(error, formValues.student);
    }
  };

  const { student, dateTime, drivingLessonType, drivingLicenseClass } = formProps.watch();

  return (
    <FormProvider {...formProps}>
      <form onSubmit={formProps.handleSubmit(onSubmit)} style={{ height: "100%" }}>
        <Column height="100%" minHeight={500} gap="1em">
          <WhenFormIsInitialized>
            <DateTimeInput source="dateTime" sx={{ mt: "5px" /* <-- prevents that the input label is truncated */ }} />
            <AppointmentDurationInput source="durationInMinutes" />
            <DrivingLessonTypeInput source="drivingLessonType" options={_drivingLessonTypeOptions} />
            <StudentInputField instructorId={instructor.id} />
            {!student && <_StudentSuggestions />}
            {student && (
              <DrivingLicenseClassInput
                student={student}
                source="drivingLicenseClass"
                label="Ausbildung für Führerscheinklasse"
              />
            )}
            {student && instructor && (
              <VehiclesInput
                student={student}
                instructor={instructor}
                drivingLessonType={drivingLessonType}
                drivingLicenseClass={drivingLicenseClass ?? ""}
              />
            )}
            {student && <StartingPointInput source="startingPointId" instructor={instructor} student={student} />}
            <DrivingLessonRepetitionsField />
            <PriceSuggestion validate={validate} />
            <Row sx={{ justifyContent: "flex-end", mt: "auto" }}>
              <SaveButton
                icon={<></>}
                label={dateTime && dateTime < DateTime.now() ? "Termin eintragen" : "Einladung senden"}
              />
            </Row>
          </WhenFormIsInitialized>
        </Column>
      </form>
    </FormProvider>
  );
};

const _drivingLessonTypeOptions: Array<[string, string]> = [
  ["normal", "normale Übungsstunde"],
  ["autobahnfahrt", "Autobahnfahrt"],
  ["nachtfahrt", "Nachtfahrt"],
  ["ueberlandfahrt", "Überlandfahrt"],
];

function WhenFormIsInitialized({ children }: { children: ReactNode }) {
  const { formState } = useFormContext();
  return formState.isLoading ? null : children;
}
