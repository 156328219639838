import { CSSProperties } from "react";
import { autovioColors } from "./backofficeTheme";
import { Money as MoneyType } from "../model/Money";

export const moneyFormatter = new Intl.NumberFormat("de", {
  style: "currency",
  currency: "EUR",
});

type MoneyProps = ({ cents: number; value?: never } | { cents?: never; value?: MoneyType }) & {
  children?: never;
  style?: CSSProperties;
};

export function Money({ cents, value, style }: MoneyProps) {
  let euro: number;
  if (typeof cents === "number" && isFinite(cents)) {
    euro = cents / 100;
  } else if (value) {
    euro = value.amount;
  } else {
    return null;
  }
  return <span style={style}>{moneyFormatter.format(euro)}</span>;
}

export function formatMoney(money: MoneyType) {
  return moneyFormatter.format(money.amount);
}

export function MoneyCurrency({
  amount,
  cents,
  currency,
  redForNegativeValues,
  greenForPositiveValues,
  showPlus,
  negate,
}: {
  amount?: string;
  cents?: number;
  currency?: string;
  redForNegativeValues?: boolean;
  greenForPositiveValues?: boolean;
  showPlus?: boolean;
  negate?: boolean;
}) {
  if (!currency) {
    currency = "EUR";
  }
  if (amount === undefined) {
    amount = cents ? (cents / 100).toString() : "0";
  }
  if (redForNegativeValues === undefined) {
    redForNegativeValues = true;
  }
  if (greenForPositiveValues === undefined) {
    greenForPositiveValues = false;
  }
  if (negate === undefined) {
    negate = false;
  }
  const value = parseFloat(amount) * (negate ? -1 : 1);
  const style =
    redForNegativeValues && value < 0
      ? { color: autovioColors.red }
      : greenForPositiveValues && value > 0
        ? { color: autovioColors.green }
        : undefined;
  return (
    <span style={style}>
      {showPlus && value > 0 ? "+" : ""}
      {new Intl.NumberFormat("de", { style: "currency", currency }).format(value)}
    </span>
  );
}
