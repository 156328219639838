import type { Branch } from "../providers/branchesProvider";
import {
  Create,
  Edit,
  NumberInput,
  required,
  Resource,
  SimpleForm,
  TextInput,
  useGetOne,
  useRedirect,
  useNotify,
  Toolbar,
  ToolbarProps,
  SaveButton,
  BooleanInput,
} from "react-admin";
import { BackofficeRecordTitle } from "../misc/BackofficeRecordTitle";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { DrivingSchool } from "../providers/drivingSchoolsProvider";
import { Column } from "../misc/Column";
import Box from "@mui/material/Box";

function CreateBranchForm() {
  const [{ drivingSchoolId }] = useAutovioContext();
  const redirect = useRedirect();
  const notify = useNotify();
  const { data: drivingSchool } = useGetOne<DrivingSchool>(
    "drivingSchools",
    { id: drivingSchoolId! },
    {
      enabled: !!drivingSchoolId,
    },
  );

  if (!drivingSchoolId || !drivingSchool) {
    return (
      <Box className="centered">
        <Column sx={{ alignItems: "center" }}>Bitte wähle eine Fahrschule aus.</Column>
      </Box>
    );
  }

  return (
    <Create
      title={<BackofficeRecordTitle />}
      mutationOptions={{
        onSuccess: () => {
          notify("Filiale erfolgreich erstellt.", { type: "success" });
          redirect(`/drivingSchools/${drivingSchoolId}/branches`);
        },
        onError: (error: Error) => {
          notify("Fehler beim Erstellen der Filiale.", { type: "error" });
          console.error("Failed to create branch.", error);
        },
      }}
      transform={(data: Branch) => ({
        ...data,
        drivingSchoolId,
        address: {
          ...data.address,
          company: drivingSchool.name,
        },
      })}
    >
      <BranchForm mode="create" />
    </Create>
  );
}

function UpdateBranchForm() {
  const [{ drivingSchoolId }] = useAutovioContext();
  const redirect = useRedirect();
  const notify = useNotify();

  return (
    <Edit
      mutationOptions={{
        onSuccess: () => {
          notify("Änderungen erfolgreich gespeichert.", { type: "success" });
          redirect(`/drivingSchools/${drivingSchoolId}/branches`);
        },
        onError: (error: unknown) => {
          notify("Fehler beim Speichern.", { type: "error" });
          console.error("Failed to update branch.", error);
        },
      }}
      mutationMode="pessimistic"
      title={<BackofficeRecordTitle />}
    >
      <BranchForm mode="edit" />
    </Edit>
  );
}

function BranchForm({ mode }: { mode: "create" | "edit" }) {
  return (
    <SimpleForm warnWhenUnsavedChanges toolbar={<CustomToolbar />}>
      <TextInput label="Name" source="name" autoFocus={mode === "create"} validate={required()} />
      <TextInput label="Straße" source="address.street" validate={required()} />
      <TextInput label="PLZ" source="address.postalCode" validate={required()} />
      <TextInput label="Ort" source="address.city" validate={required()} />
      <NumberInput label="Max Schüleranzahl pro Theoriestunde" source="maxStudentsForTheory" validate={required()} />
      <BooleanInput label="Standard-Filiale" source="isDefaultBranch" />
    </SimpleForm>
  );
}

const CustomToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const BranchesResource = (
  <Resource
    key="branches"
    name="branches"
    create={CreateBranchForm}
    edit={UpdateBranchForm}
    options={{ label: "Filialen", createTitle: "Neue Filiale" }}
    recordRepresentation={(branch: Branch) => branch.name}
  />
);
