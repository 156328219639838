import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import { ReactNode } from "react";

interface EventPropagationStopperProps {
  component?: React.ElementType;
  sx?: SxProps;
  children: ReactNode;
}

export const EventPropagationStopper = ({ component = "div", sx = {}, children }: EventPropagationStopperProps) => {
  return (
    <Box component={component} sx={sx} onClick={(event: MouseEvent) => event.stopPropagation()}>
      {children}
    </Box>
  );
};
