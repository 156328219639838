import { useMemo } from "react";
import { FieldValues, useFormContext, Validate, Path } from "react-hook-form";
import { LinearProgress } from "react-admin";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SxProps } from "@mui/material";

interface SelectInputProps<T extends string | number> {
  source: Path<FieldValues>;
  label: string;
  options: undefined | Array<[T, string]>;
  validate: Validate<T, FieldValues>;
  sx?: SxProps;
}

let _selectLabelIdCounter = 1;

export const SelectInput = <T extends string | number>({
  source,
  label,
  options,
  validate,
  sx = {},
}: SelectInputProps<T>) => {
  const labelId = useMemo(() => `Select-Label-${_selectLabelIdCounter++}`, []);
  const { register, formState, watch } = useFormContext<FieldValues>();
  const { ref, ...fieldProps } = register(source, { validate: validate as any });

  const value = watch(source);
  const error = formState.errors[source]?.message;
  return (
    <FormControl sx={sx}>
      <InputLabel id={labelId} error={!!error}>
        {label}
      </InputLabel>
      <Select
        {...fieldProps}
        label={label}
        labelId={labelId}
        inputRef={ref}
        value={value}
        error={!!error}
        disabled={formState.isSubmitting}
      >
        {(!options || options.length === 0) && <LinearProgress sx={{ ml: "1em" }} />}
        {options?.map(([value, label]) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {typeof error === "string" && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};
