import { Typography } from "@mui/material";
import { EmptyCalendarIcon } from "../icons/EmptyCalendarIcon";
import { Column } from "./Column";
import { Row } from "./Row";
import { autovioColors } from "./backofficeTheme";

export function AppointmentEmptyState({ label }: { label?: string }) {
  return (
    <div className="RaList-main">
      <Row sx={{ width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
        <Column sx={{ alignItems: "center", gap: "28px" }}>
          <EmptyCalendarIcon />
          <Typography color={autovioColors.grey} fontSize="14px">
            {label ?? "Keine Einträge."}
          </Typography>
        </Column>
      </Row>
    </div>
  );
}
