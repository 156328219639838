import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDialog } from "../../hooks/useDialog";
import { AddDrivingLicenseAuthorityFormDialog } from "./AddDrivingLicenseAuthorityFormDialog";

export function AddDrivingLicenseAuthorityFormButton() {
  const { dialogProps, openDialog } = useDialog("AddDrivingLicenseAuthorityFormDialog");

  return (
    <Box>
      <Button startIcon={<AddIcon />} variant="outlined" sx={{ height: "40px" }} onClick={openDialog}>
        Formular hinzufügen
      </Button>
      <AddDrivingLicenseAuthorityFormDialog {...dialogProps} />
    </Box>
  );
}
