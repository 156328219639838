import merge from "lodash/merge";
import { backofficeTheme } from "./backofficeTheme";
import { createTheme } from "@mui/material";

export const formTheme = createTheme(
  merge({}, backofficeTheme, {
    components: {
      MuiInputLabel: {
        defaultProps: {
          shrink: true, // Always keep the label in the "shrink" position
        },
        styleOverrides: {
          root: {
            maxWidth: "fit-content",
            transform: "none",
            position: "relative",
            marginBottom: 5,
            fontSize: "12px",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-notchedOutline": {
              top: 0,
              legend: {
                display: "none", // Hide the legend to remove the gap
              },
            },
          },
        },
      },
    },
  }) as any,
);
