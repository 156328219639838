import { BooleanInput, EditBase, Form, FormDataConsumer, SaveButton, SelectInput } from "react-admin";
import { approvalStatusChoices } from "../../model/approvalStatusChoices";
import { ExaminationAssignmentReceivedAtInput } from "../../inputs/ExaminationAssignmentExpiresAtInput";
import { ExaminationAssignmentExpiresAtInput } from "../../inputs/ExaminationAssignmentReceivedAtInput";
import { DialogActions, DialogContent } from "@mui/material";

export function StudentAdministrativeOnboardingForm({ onSave }: { onSave: () => void }) {
  return (
    <EditBase redirect={false} mutationMode="pessimistic" mutationOptions={{ onSuccess: onSave }}>
      <Form shouldUnregister warnWhenUnsavedChanges>
        <DialogContent sx={{ width: "360px" }}>
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.administrativeOnboardingState?.applicationApproved === "pending") {
                return (
                  <>
                    <BooleanInput label="Erste-Hilfe-Kurs" source="administrativeOnboardingState.firstAidCourseDone" />
                    <BooleanInput label="Sehtest" source="administrativeOnboardingState.eyeTestDone" />
                    <BooleanInput label="Passbild" source="administrativeOnboardingState.photoTaken" />
                    <BooleanInput
                      label="Antrag ausgefüllt"
                      source="administrativeOnboardingState.applicationFilledOut"
                    />
                    <BooleanInput
                      label="Antrag eingereicht"
                      source="administrativeOnboardingState.applicationSubmitted"
                    />
                  </>
                );
              }
            }}
          </FormDataConsumer>
          <SelectInput
            sx={{ width: "15em" }}
            label="Status"
            source="administrativeOnboardingState.applicationApproved"
            choices={approvalStatusChoices}
          />
          <FormDataConsumer>
            {({ formData }) => {
              if (formData.administrativeOnboardingState.applicationApproved === "approved") {
                return (
                  <>
                    <ExaminationAssignmentReceivedAtInput />
                    <ExaminationAssignmentExpiresAtInput />
                  </>
                );
              }
            }}
          </FormDataConsumer>
        </DialogContent>
        <DialogActions>
          <SaveButton />
        </DialogActions>
      </Form>
    </EditBase>
  );
}
