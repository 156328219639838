import type { DialogProps } from "../../misc/DialogProps";
import { DialogCloseButton } from "../../misc/DialogCloseButton";
import { StudentDetails } from "../../details/StudentDetails";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import { Row } from "../../misc/Row";
import { DunningProcessTimeline } from "./DunningProcessTimeline";
import { useState } from "react";
import { Dialog, DialogContent, MenuItem, Menu, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Identifier, RecordContextProvider, useGetOne } from "react-admin";
import { EventPropagationStopper } from "../../misc/EventPropagationStopper";

export function DunningStudentDialog({
  studentId,
  actions,
  ...dialogProps
}: DialogProps & { studentId: Identifier; actions: React.ReactNode[] }) {
  const { data: student } = useGetOne("students", { id: studentId }, { enabled: !!studentId });
  return (
    <Dialog {...dialogProps} maxWidth="xl" fullWidth>
      <DialogCloseButton onClose={dialogProps.onClose} />
      <DialogContent sx={{ backgroundColor: "#f8f8f8", pt: 8 }}>
        {student ? (
          <RecordContextProvider value={student}>
            <DialogActions actions={actions} />
            <DunningProcessTimeline />
            <StudentDetails compactView />
          </RecordContextProvider>
        ) : (
          <Row minHeight="80px" pt="80px">
            <LoadingIndicator />
          </Row>
        )}
      </DialogContent>
    </Dialog>
  );
}

function DialogActions({ actions }: { actions: React.ReactNode[] }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openActionsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeActionsMenu = () => {
    setAnchorEl(null);
  };

  if (actions.length === 0) {
    return null;
  }

  return (
    <EventPropagationStopper>
      <IconButton
        sx={{
          position: "absolute",
          top: "18px",
          right: "56px",
        }}
        onClick={openActionsMenu}
      >
        <MoreVertIcon sx={{ fontSize: 20 }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={closeActionsMenu}
        MenuListProps={{
          "aria-labelledby": "dialog-actions",
        }}
      >
        {actions.map((action) => (
          <MenuItem sx={{ p: 0 }} onClick={closeActionsMenu}>
            {action}
          </MenuItem>
        ))}
      </Menu>
    </EventPropagationStopper>
  );
}
