import React from "react";
import { Datagrid } from "react-admin";
import { EmptyState } from "../../misc/EmptyState";
import { useStudentDunningDialog } from "../../hooks/useStudentDunningDialog";
import { DunningStudentDialog } from "../../dialogs/DunningStudentDialog/DunningStudentDialog";

export const DunningDatagrid = ({
  children,
  dialogActions = [],
}: {
  children: React.ReactNode;
  dialogActions?: React.ReactNode[];
}) => {
  const { dialogProps, openDialog } = useStudentDunningDialog();

  return (
    <>
      <Datagrid
        rowClick={(studentId) => {
          openDialog(studentId);
          return false;
        }}
        bulkActionButtons={false}
        empty={<EmptyState label="Keine passenden Fahrschüler gefunden." />}
      >
        {children}
      </Datagrid>
      <DunningStudentDialog actions={dialogActions} {...dialogProps} />
    </>
  );
};
