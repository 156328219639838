import { Identifier, useGetManyReference } from "react-admin";
import { useFormContext, FieldValues } from "react-hook-form";
import { Car, Motorcycle, Trailer } from "../providers/resourceProviders";
import { SelectInput } from "./SelectInput";
import { Student } from "../providers/studentsProvider";
import { Instructor } from "../providers/instructorsProvider";
import { DrivingLicenseClass, isForMotorcycle, isForTrailer } from "../model/DrivingLicenseClass";
import { DrivingLesson } from "../model/autovioCalendarEvents";

interface VehiclesInputProps {
  instructor: Instructor;
  student: Student;
  drivingLicenseClass: "" | DrivingLicenseClass;
  drivingLessonType: "" | DrivingLesson["drivingLessonType"] | "theoretischePruefung";
}

export const OWN_MOTORCYCLE_PSEUDO_ID = "__own_motorcycle__";

export const VehiclesInput = ({ instructor, student, drivingLicenseClass, drivingLessonType }: VehiclesInputProps) => {
  if (!(student && drivingLicenseClass && drivingLessonType)) {
    return null;
  }

  if (isForMotorcycle(drivingLicenseClass)) {
    if (drivingLessonType === "praktischePruefung") {
      return (
        <>
          <MotorcycleInput label="Motorrad für Fahrschüler" instructorId={instructor.id} />
          <CarInput label="Auto für Fahrlehrer und Prüfer" instructorId={instructor.id} />
        </>
      );
    } else {
      return (
        <>
          <MotorcycleInput label="Motorrad für Fahrschüler" instructorId={instructor.id} />
          <SecondCarOrMotorcycleInput label="Fahrzeug für Fahrlehrer" instructorId={instructor.id} />
        </>
      );
    }
  }

  return (
    <>
      <CarInput label="Auto" instructorId={instructor.id} />
      {isForTrailer(drivingLicenseClass) && <TrailerInput label="Anhänger" instructorId={instructor.id} />}
    </>
  );
};

function MotorcycleInput(props: { label: string; instructorId: Identifier }) {
  const { data: motorcycles } = useGetManyReference<Motorcycle>("motorcycles", {
    target: "entitledUserId",
    id: props.instructorId,
    filter: { withDeleted: false },
    sort: { field: "name", order: "ASC" },
    pagination: { page: 1, perPage: 999 },
  });
  const options: undefined | Array<[string, string]> = motorcycles && [
    [OWN_MOTORCYCLE_PSEUDO_ID, "Eigenes Motorrad"],
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    ...(motorcycles.map((it) => [it.id, it.name]) as Array<[string, string]>),
  ];
  return (
    <SelectInput
      source="motorcycleId"
      label={props.label}
      options={options}
      validate={(value) => (value ? true : "Bitte wähle ein Motorrad aus.")}
    />
  );
}

function SecondCarOrMotorcycleInput(props: { label: string; instructorId: Identifier }) {
  const { watch } = useFormContext<FieldValues>();
  const motorcycleId: string = watch("motorcycleId");
  const { data: motorcycles } = useGetManyReference<Motorcycle>("motorcycles", {
    target: "entitledUserId",
    id: props.instructorId,
    filter: { withDeleted: false },
    sort: { field: "name", order: "ASC" },
    pagination: { page: 1, perPage: 999 },
  });
  const { data: cars } = useGetManyReference<Car>("cars", {
    target: "entitledUserId",
    id: props.instructorId,
    filter: { withDeleted: false },
    sort: { field: "name", order: "ASC" },
    pagination: { page: 1, perPage: 999 },
  });
  const options =
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    motorcycles && cars && ([...motorcycles, ...cars].map((it) => [it.id, it.name]) as Array<[string, string]>);
  return (
    <SelectInput
      source="secondMotorcycleOrCarId"
      label={props.label}
      options={options}
      validate={(value) =>
        !value
          ? "Bitte wähle ein Fahrzeug aus."
          : value === motorcycleId
            ? "Bitte wähle ein anderes Fahrzeug aus."
            : true
      }
    />
  );
}

function TrailerInput(props: { label: string; instructorId: Identifier }) {
  const { data: trailers } = useGetManyReference<Trailer>("trailers", {
    target: "entitledUserId",
    id: props.instructorId,
    filter: { withDeleted: false },
    sort: { field: "name", order: "ASC" },
    pagination: { page: 1, perPage: 999 },
  });
  return (
    <SelectInput
      source="trailerId"
      label={props.label}
      options={trailers?.map((trailer) => [trailer.id, trailer.name])}
      validate={(value) => (value ? true : "Bitte wähle einen Anhänger aus.")}
    />
  );
}

function CarInput(props: { label: string; instructorId: Identifier }) {
  const { data: cars } = useGetManyReference<Car>("cars", {
    target: "entitledUserId",
    id: props.instructorId,
    filter: { withDeleted: false },
    sort: { field: "name", order: "ASC" },
    pagination: { page: 1, perPage: 999 },
  });
  return (
    <SelectInput
      source="carId"
      label={props.label}
      options={cars?.map((car) => [car.id, `${car.name} (${_formatGearType(car.car.gearType)})`])}
      validate={(value) => (value ? true : "Bitte wähle ein Auto aus.")}
    />
  );
}

function _formatGearType(gearType: "manual" | "automatic" | "electric") {
  if (gearType === "manual") {
    return "Schaltwagen";
  } else if (gearType === "automatic") {
    return "Automatik";
  } else if (gearType === "electric") {
    return "Elektrisch";
  } else {
    return gearType;
  }
}
